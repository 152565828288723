import React from 'react';
import cn from 'classnames';
import AnimateHeight from 'react-animate-height';

import CardUtils from  '../utils/CardUtils';
import ColorUtils from '../utils/ColorUtils';
import CountryUtils from '../utils/CountryUtils';
import NumberUtils from '../utils/NumberUtils';

const WalletInfo = React.forwardRef(({
  eWallets,
  wallet,
  onWalletChanged,
  walletError,
  walletErrorMsg,
  walletMissing,
  shipping,
  sameBillingAsShipping,
  onUseShippingAsBillingChecked,
  requestName,
  name,
  onNameChanged,
  onNameBlur,
  nameError,
  nameErrorMsg,
  nameMissing,
  billing,
  billingDetailsMissing,
  billingCountry,
  onBillingCountryChanged,
  billingAddressLine1,
  billingAddressLine1Missing,
  onBillingAddressLine1Changed,
  billingAddressLine2,
  onBillingAddressLine2Changed,
  billingAddressSuburb,
  onBillingAddressSuburbChanged,
  billingAddressCity,
  billingAddressCityMissing,
  onBillingAddressCityChanged,
  billingAddressPostalCode,
  billingAddressPostalMissing,
  onBillingAddressPostalChanged,
  billingAddressState,
  billingAddressStateMissing,
  onBillingAddressStateChanged,
  complete,
  loading,
  success,
  buttonColor,
  total,
  currency,
  submit,
  onWalletPayClick
}, ref) => {
  return (
    <div ref={ref} className="box-border flex flex-row -m-2 flex-wrap">
      <div className="box-border flex-initial min-w-0 max-w-full w-full p-2">
        <div>
          <div className="box-border flex flex-no-wrap justify-between relative">
            <label htmlFor="wallet">
              <span className="m-0 text-13px font-medium text-gray-600">Wallet</span>
            </label>
            <div className={cn("transition-all duration-300 ease", { 'transform translate-y-full opacity-0': !walletMissing, 'transform translate-y-0 opacity-100': walletMissing })}>
              <span className="m-0 text-tiny font-medium text-danger uppercase">
                <span>Required</span>
              </span>
            </div>
          </div>
          <div className="m-0 mt-1 border-none p-0">
            <div className="relative flex flex-wrap">
              <div className="box-border flex-initial min-w-0 max-w-full w-full">
                <div className="relative">
                  <div>
                    <div className="relative flex">
                      <select disabled={loading || success} className={cn("checkout-select w-full h-11 text-base py-2 pl-3 pr-6 text-input leading-normal border-0 bg-white shadow-input transitions-input appearance-none rounded-md lg:h-9 lg:text-sm", {
                      'invalid': walletError || walletMissing,
                      'text-placeholder empty': !wallet,
                      'has-icon': wallet,
                      'input-disabled': loading || success,
                    })} id="wallet" name="wallet" autoComplete="wallet" aria-label="Select wallet" defaultValue={wallet} onChange={(e) => onWalletChanged(e)}>
                        <option value="" disabled hidden>Select wallet</option>
                        {
                          eWallets.map((w, index) =>
                            <option key={index} value={w}>{CardUtils.getWalletName(w)}</option>
                          )
                        }
                      </select>
                      <svg className={cn("absolute top-1/2 right-3 -mt-1.5 pointer-events-none w-3 h-12 z-3", { "hidden": loading || success })} focusable="false" width="12" height="12">
                        <path d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <div className={cn("form-input-icon pointer-events-none flex items-center absolute top-0 h-full pr-3 z-3 left-0 right-auto pl-3", {
                      'is-loaded is-select': wallet !== '',
                    })}>
                        {
                          wallet &&
                          <img src={CardUtils.getWalletIcon(wallet)} alt={wallet} className="h-4.5 rounded-2.5px" />
                        }
                    </div>
                  </div>
                </div>
              </div>
              <AnimateHeight
                height={walletErrorMsg ? 'auto' : 0}
                animateOpacity
                duration={500}
                style={{ flexShrink: 0, maxWidth: '100%' }}
              >
                <span className="m-0 text-13px text-danger transition-all duration-300 ease origin-top-left">
                  <span>{`${walletErrorMsg} `}</span>
                </span>
              </AnimateHeight>
            </div>
          </div>
        </div>
      </div>
      {
        shipping && billing ? (
          <div className="box-border flex-initial min-w-0 max-w-full w-full p-2">
            <div>
              <div className={cn("flex items-baseline", { 'checked': sameBillingAsShipping })}>
                <div className="mr-3 relative top-1">
                  <input disabled={loading || success} className="checkout-checkbox appearance-none bg-transparent border-none shadow-none h-4 opacity-0 outline-none absolute top-0 w-4 cursor-pointer" id="useShippingForBilling" name="useShippingForBilling" type="checkbox" defaultChecked={sameBillingAsShipping} onChange={(e) => onUseShippingAsBillingChecked(e)} />
                  <span className={cn("checkout-checkbox-styledInput pointer-events-none bg-transparent rounded-0.5 shadow-checkbox block h-4 min-w-4 select-none w-4", {
                    "before:empty-content before:checkbox-checked": sameBillingAsShipping,
                    "cursor-pointer": !loading && !success,
                    "cursor-default pointer-events-none before:cursor-default before:pointer-events-none after:bg-white after:-bottom-px after:empty-content after:-left-px after:opacity-50 after:pointer-events-auto after:absolute after:-right-0.5 after:-top-0.5": loading || success
                  })} />
                </div>
                <label htmlFor="useShippingForBilling">
                  <span className={cn("m-0 text-13px font-medium text-gray-600 select-none cursor-pointer", {
                    "text-placeholder": loading || success
                  })}>
                    <span>Billing address is same as shipping</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        ) : null
      }
      <div className="billing-container box-border flex-initial min-w-0 max-w-full w-full">
        <AnimateHeight
          height={shipping ? (sameBillingAsShipping ? 0 : 'auto') : 'auto'}
          duration={500}
          animateOpacity
          style={{ opacity: 1, padding: '0.5rem' }}
        >
          <div className="pointer-events-auto">
            <div className="box-border flex flex-row -m-2 flex-wrap">
              {
                requestName ? (
                  <div className="box-border flex-initial min-w-0 max-w-full w-full p-2">
                    <div>
                      <div className="box-border flex flex-no-wrap justify-between relative">
                        <label htmlFor="billingName">
                          <span className="m-0 text-13px font-medium text-input">Name</span>
                        </label>
                        <div className={cn("transition-all duration-300 ease", { 'transform translate-y-full opacity-0': !nameMissing, 'transform translate-y-0 opacity-100': nameMissing })}>
                          <span className="m-0 text-tiny font-medium text-danger uppercase">
                            <span>Required</span>
                          </span>
                        </div>
                      </div>
                      <div className="mt-1 mx-0 border-none p-0">
                        <div className="relative flex flex-wrap">
                          <div className="box-border flex-initial min-w-0 max-w-full w-full">
                            <div className="relative">
                              <span className="relative block m-0 p-0">
                                <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-md lg:h-9 lg:text-sm", {'invalid': nameMissing || nameError, 'input-disabled': loading || success})} autoComplete="billing name" autoCorrect="off" spellCheck="false" id="billingName" name="billingName" type="text" aria-invalid={nameMissing || nameError} value={name} onChange={(e) => onNameChanged(e)} onBlur={(e) => onNameBlur(e)} />
                              </span>
                            </div>
                          </div>
                          <AnimateHeight
                            height={nameErrorMsg ? 'auto' : 0}
                            animateOpacity
                            duration={500}
                            style={{ flexShrink: 0, maxWidth: '100%' }}
                          >
                            <span className="m-0 text-13px text-danger transition-all duration-300 ease origin-top-left">
                              <span>{`${nameErrorMsg} `}</span>
                            </span>
                          </AnimateHeight>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null
              }
              
              {
                billing ? (
                  <div className="box-border flex-initial min-w-0 max-w-full w-full p-2">
                    <div>
                      <div className="box-border flex flex-no-wrap justify-between relative">
                        <label htmlFor={billing ? 'billing-address-fieldset' : 'billingCountry'}>
                          <span className="m-0 text-13px font-medium text-input">
                            { billing ? 'Billing address' : 'Country or region' }
                          </span>
                        </label>
                        <div className={cn("transition-all duration-300 ease", { 'transform translate-y-full opacity-0': !billingDetailsMissing, 'transform translate-y-0 opacity-100': billingDetailsMissing })}>
                          <span className="m-0 text-tiny font-medium text-danger uppercase">
                            <span>Required</span>
                          </span>
                        </div>
                      </div>
                      <fieldset id="billing-address-fieldset" className="mt-1 mx-0 border-none p-0">
                        <div className="relative flex flex-wrap">
                          <div className="box-border flex-initial min-w-0 max-w-full w-full">
                            <div className="relative">
                              <div>
                                <div className="flex relative">
                                  <select disabled={loading || loading} className={cn("checkout-select w-full h-11 text-base py-2 pl-3 pr-6 text-input placeholder-placeholder leading-normal border-0 bg-white shadow-input transitions-input appearance-none rounded-none rounded-t-md lg:h-9 lg:text-sm", {
                                    'invalid': billingDetailsMissing,
                                    'input-disabled': loading || success
                                  })} id="billingCountry" name="billingCountry" autoComplete="billing country" aria-label="Country or region" value={billingCountry || ''} onChange={(e) => onBillingCountryChanged(e)}>
                                    <option value disabled hidden></option>
                                    {
                                      CountryUtils.getCountries().map((item, index) => 
                                        <option key={index} value={item.value}>{item.label}</option>
                                      )
                                    }
                                  </select>
                                  <svg className={cn("absolute top-1/2 right-3 -mt-1.5 pointer-events-none w-3 h-12 z-3", {'hidden': loading || success})} focusable="false" width="12" height="12">
                                    <path d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z" fillRule="evenodd"></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="box-border flex-initial min-w-0 max-w-full w-full">
                            <div className="relative">
                              <span className="relative block m-0 p-0">
                                <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm", {
                                  'invalid': billingAddressLine1Missing || billingDetailsMissing, 'input-disabled': loading || success
                                })} autoComplete="billing address-line1" autoCorrect="off" spellCheck="false" id="billingAddressLine1" name="billingAddressLine1" type="text" aria-label="Address line 1" placeholder="Address line 1" aria-invalid={billingAddressLine1Missing || billingDetailsMissing} value={billingAddressLine1 || ''} onChange={(e) => onBillingAddressLine1Changed(e)} />
                              </span>
                              <div className={cn("form-input-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded': billingAddressLine1Missing })}>
                                <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                              </div>
                            </div>
                          </div>
                          <div className="box-border flex-initial min-w-0 max-w-full w-full">
                            <div className="relative">
                              <span className="relative block m-0 p-0">
                                <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm", { 
                                  'rounded-b-md': !CountryUtils.hasCity(billingCountry) && !CountryUtils.hasPostal(billingCountry),
                                  'invalid': billingDetailsMissing, 'input-disabled': loading || success
                                })} autoComplete="billing address-line2" autoCorrect="off" spellCheck="false" id="billingAddressLine2" name="billingAddressLine2" type="text" aria-label="Address line 2" placeholder="Address line 2" aria-invalid={billingDetailsMissing} value={billingAddressLine2 || ''} onChange={(e) => onBillingAddressLine2Changed(e)} />
                              </span>
                            </div>
                          </div>
                          {
                            CountryUtils.hasSuburb(billingCountry) &&
                            <div className="box-border flex-initial min-w-0 max-w-full w-full">
                              <div className="relative">
                                <span className="relative block m-0 p-0">
                                  <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm", {
                                    'invalid': billingDetailsMissing, 'input-disabled': loading || success
                                  })} autoComplete="billing address-level3" autoCorrect="off" spellCheck="false" id="billingSuburb" name="billingSuburb" type="text" aria-label={CountryUtils.getSuburbLabel(billingCountry)} placeholder={CountryUtils.getSuburbLabel(billingCountry)} aria-invalid={billingDetailsMissing} value={billingAddressSuburb || ''} onChange={(e) => onBillingAddressSuburbChanged(e)} />
                                </span>
                              </div>
                            </div>
                          }
                          {
                            CountryUtils.hasCity(billingCountry) &&
                            <div className={cn("box-border flex-initial min-w-0 max-w-full", { 'w-1/2': CountryUtils.hasPostal(billingCountry), 'w-full': !CountryUtils.hasPostal(billingCountry) })}>
                              <div className="relative">
                                <span className="relative block m-0 p-0">
                                  <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm", 
                                  {
                                    'rounded-bl-md': !CountryUtils.hasState(billingCountry),
                                    'rounded-b-md': !CountryUtils.hasPostal(billingCountry) && !CountryUtils.hasState(billingCountry),
                                    'invalid': billingAddressCityMissing || billingDetailsMissing, 'input-disabled': loading || success
                                  })} autoComplete="billing address-level2" autoCorrect="off" spellCheck="false" id="billingCity" name="billingCity" type="text" aria-label="City" placeholder="City" aria-invalid={billingAddressCityMissing || billingDetailsMissing} value={billingAddressCity || ''} onChange={(e) => onBillingAddressCityChanged(e)} />
                                </span>
                                <div className={cn("form-input-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded': billingAddressCityMissing })}>
                                  <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                                </div>
                              </div>
                            </div>
                          }
                          {
                            CountryUtils.hasPostal(billingCountry) &&
                            <div className={cn("box-border flex-initial min-w-0 max-w-full", { 'w-1/2': CountryUtils.hasCity(billingCountry), 'w-full': !CountryUtils.hasCity(billingCountry) })}>
                              <div className="relative">
                                <span className="relative block m-0 p-0">
                                  <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm",
                                  {
                                    'rounded-br-md': !CountryUtils.hasState(billingCountry),
                                    'rounded-b-md': !CountryUtils.hasCity(billingCountry) && !CountryUtils.hasState(billingCountry),
                                    'invalid': billingAddressPostalMissing ||  billingDetailsMissing, 'input-disabled': loading || success
                                  })} autoComplete="billing postal-code" autoCorrect="off" spellCheck="false" id="billingPostalCode" name="billingPostalCode" type="text" aria-label={CountryUtils.getPostalLabel(billingCountry)} placeholder={CountryUtils.getPostalLabel(billingCountry)} aria-invalid={billingAddressPostalMissing || billingDetailsMissing} value={billingAddressPostalCode || ''} onChange={(e) => onBillingAddressPostalChanged(e)} />
                                </span>
                                <div className={cn("form-input-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded': billingAddressPostalMissing })}>
                                  <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                                </div>
                              </div>
                            </div>
                          }
                          {
                            CountryUtils.hasState(billingCountry) && CountryUtils.getStates(billingCountry).length > 0 &&
                            <div className="box-border flex-initial min-w-0 max-w-full w-full">
                              <div className="relative">
                                <div>
                                  <div className="flex relative">
                                    <select disabled={loading || success} className={cn("checkout-select w-full h-11 text-base py-2 pl-3 pr-6 leading-normal border-0 bg-white shadow-input transitions-input appearance-none rounded-none rounded-b-md lg:h-9 lg:text-sm", {
                                      'invalid': billingDetailsMissing || billingAddressStateMissing,
                                      'text-placeholder empty': !billingAddressState || billingAddressState === 'true',
                                      'text-input': !!billingAddressState && billingAddressState !== 'true',
                                      'input-disabled': loading || success
                                    })} id="billingAdministrativeArea" name="billingAdministrativeArea" autoComplete="billing address-level1" aria-label={CountryUtils.getStateLabel(billingCountry)} value={billingAddressState} onChange={(e) => onBillingAddressStateChanged(e)}>
                                      <option value>{CountryUtils.getStateLabel(billingCountry)}</option>
                                      {
                                        CountryUtils.getStates(billingCountry).map((item, index) =>
                                          <option key={index} value={item.name}>{item.name}</option>
                                        )
                                      }
                                    </select>
                                    <svg className={cn("absolute top-1/2 right-3 -mt-1.5 pointer-events-none w-3 h-12 z-3", {
                                      'hidden': loading || success
                                    })} focusable="false" width="12" height="12">
                                      <path d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z" fillRule="evenodd"></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                          <div className="opacity-0 h-0">

                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                ) : null
              }
              
            </div>
          </div>
        </AnimateHeight>
      </div>
      {/* <div className="box-border flex-initial min-w-0 max-w-full w-full p-2">
        <div className="pointer-events-auto h-auto opacity-100">
          <div className="box-border flex flex-row -m-2 flex-wrap">
            <div className="box-border flex-initial min-w-0 max-w-full w-full p-2">
              <div>
                <div className="box-border flex flex-no-wrap justify-between relative">
                  <label htmlFor="billingName">
                    <span className="m-0 text-13px font-medium text-gray-600">Name</span>
                  </label>
                  <div className={cn("transition-all duration-300 ease", { 'transform translate-y-full opacity-0': !nameMissing, 'transform translate-y-0 opacity-100': nameMissing })}>
                    <span className="m-0 text-tiny font-medium text-danger uppercase">
                      <span>Required</span>
                    </span>
                  </div>
                </div>
                <div className="m-0 mt-1 border-none p-0">
                  <div className="relative flex flex-wrap">
                    <div className="box-border flex-initial min-w-0 max-w-full w-full">
                      <div className="relative">
                        <span className="relative block m-0 p-0">
                          <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-md lg:h-9 lg:text-sm", {'invalid': nameMissing || nameError})} autoComplete="name" autoCorrect="off" spellCheck="false" id="billingName" name="billingName" type="text" aria-invalid={nameMissing || nameError} value={name} onChange={(e) => onNameChanged(e)} onBlur={(e) => onNameBlur(e)} />
                        </span>
                      </div>
                    </div>
                    <AnimateHeight
                      height={nameErrorMsg ? 'auto' : 0}
                      animateOpacity
                      duration={500}
                      style={{ flexShrink: 0, maxWidth: '100%' }}
                    >
                      <span className="m-0 text-13px text-danger transition-all duration-300 ease origin-top-left">
                        <span>{`${nameErrorMsg} `}</span>
                      </span>
                    </AnimateHeight>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="box-border flex-initial min-w-0 max-w-full w-full p-2 hidden"></div>
      <div className="box-border flex-initial min-w-0 max-w-full w-full p-2">
        <button onClick={(e) => onWalletPayClick(e)} className={cn("submit relative overflow-hidden h-11 w-full mt-3 p-0 text-white shadow-pay border-0 outline-none rounded-md cursor-pointer transitions-pay-button backface-visibility-hidden focus:outline-none", { 'active:shadow-pay-focus': (complete || !complete) && !loading && !success, 'hover:shadow-pay-hover active:transform active:scale-99 is-complete': complete && !loading && !success, 'bg-success success': success })} style={{ backgroundColor: `${success ? '' : ColorUtils.hex2rgba(buttonColor)}`, color: `${ColorUtils.isDark(buttonColor) ? ColorUtils.hex2rgba('#fff') : '#fff'}` }}>
          <div className={cn("shimmer absolute inset-y-0 left-0 h-full w-3/2 opacity-0 transform -translate-x-3/2 transition-opacity duration-200 ease", { 'opacity-100 animate-shimmer': complete && !loading && !success })} style={{ background: `linear-gradient(to right, ${ColorUtils.hex2rgba(buttonColor, 0)} 0%, ${ColorUtils.brighter(buttonColor, 50)} 50%, ${ColorUtils.hex2rgba(buttonColor, 0)} 100%)` }}></div>
          <div>
            <span className={cn("m-0 font-medium truncate absolute top-0 left-0 w-full text-center leading-11 transition-all duration-200 ease", { 'opacity-60': !complete && !loading && !success, 'opacity-0 transform -translate-x-4': loading || success })}>
              {`${CardUtils.getSubmitLabel(submit)} ${NumberUtils.formatCurrency(total/100, currency)}`}
            </span>
            <span className={cn("m-0 font-medium truncate absolute top-0 left-0 w-full text-center leading-11 transition-all duration-200 ease", { 'opacity-0 transform translate-x-4': (!complete || complete) && !loading && !success, 'opacity-0 transform -translate-x-4': success })}>Processing...</span>
          </div>
          <div className="icon-container absolute top-0 left-0 h-full w-full transform -translate-x-4 transition-transform duration-300 ease">
            <div className={cn("flex absolute top-1/2 right-0 transform -translate-y-1/2 opacity-0 transition-all duration-200 ease", { 'opacity-0 transform -translate-y-1/2 scale-90': !complete || loading || success, 'opacity-100': complete && !loading && !success })}>
              <div className="w-4 h-4">
                <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false">
                  <path d="M3 7V5a5 5 0 1 1 10 0v2h.5a1 1 0 0 1 1 1v6a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V8a1 1 0 0 1 1-1zm5 2.5a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1zM11 7V5a3 3 0 1 0-6 0v2z" fill="#ffffff" fillRule="evenodd"></path>
                </svg>
              </div>
            </div>
            <div className={cn("spinner-icon flex absolute top-1/2 right-0 transform -translate-y-1/2 opacity-0 transition-all duration-200", { 'opacity-0 transform -translate-y-1/2 scale-90': !complete || complete, 'opacity-100': complete && loading, 'opacity-100 transform -translate-y-1/2 scale-130 duration-300 post-center': success })}>
              <div className="w-5 h-5">
                <svg className="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false">
                  <ellipse className={cn("origin-center animate-spinner-spin", { 'animate-spinner-complete': success })} cx="12" cy="12" rx="10" ry="10" style={{ stroke: 'rgb(255, 255, 255)' }}></ellipse>
                </svg>
              </div>
            </div>
          </div>
          <div className={cn("checkmark-icon absolute top-1/2 left-1/2", { 'current': success })}>
            <div className="h-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" focusable="false">
                <path d="M 0.5 6 L 8 13.5 L 21.5 0" fill="transparent" strokeWidth="2" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </div>
          </div>
        </button>
        <AnimateHeight
          height={success ? 'auto' : 0}
          animateOpacity
          className="mt-3 transition-height duration-300 ease">
          <div className="w-full rounded leading-4 text-center">
            <span className="text-xs text-gray-500 proportional-nums whitespace-normal text-center">
              Redirecting back to the merchant page please wait...
            </span>
          </div>
        </AnimateHeight>
        <div className="mt-3"></div>
      </div>
    </div>
  )
})

export default WalletInfo;