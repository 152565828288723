import Countries from '../constants/Countries';
import States from '../constants/States';

const getCountries = () => {
  try {
    return Countries.sort((a, b) => a.name.localeCompare(b.name)).map((country) => {
      return { label: country.name, value: country.code }
    })
  } catch (e) {
    console.log(e);
    return [];
  }
}

const getCountriesMobileCodes = () => {
  try {
    return Countries.filter(c => c.country_calling_code !== '').sort((a, b) => a.name.localeCompare(b.name)).map((country) => {
      return { label: `${country.name} (${country.country_calling_code})`, value: country.code }
    })
  } catch (e) {
    console.log(e);
    return [];
  }
}

const getCountriesIso = () => {
  try {
    return Countries.sort((a, b) => a.name.localeCompare(b.name)).map((country) => country.code);
  } catch (e) {
    console.log(e);
    return [];
  }
}

const getCountriesByIso = (arr) => {
  try {
    return Countries.filter((c) => arr.includes(c.code.toUpperCase())).sort((a, b) => a.name.localeCompare(b.name)).map((country) => {
      return { label: country.name, value: country.code}
    })
  } catch (e) {
    console.log(e);
    return [];
  }
}

const getStates = (countryCode) => {
  try {
    return States.filter((state) => state.country_code.toLowerCase() === countryCode.toLowerCase())
  } catch (e) {
    console.log(e);
    return [];
  }
}

const getSuburbLabel = (countryCode) => {
  const suburb_countries = ["AU", "NZ", "NG", "ZA", "TH"];
  const neighborhood_countries = ["BR", "MX"];
  const district_countries = ["CN", "HK", "KR"];
  const townland_countries = ["IE"];
  const village_township_countries = ["MY"];
  const island_countries = ["SC"];
  const brgy_countries = ["PH"];

  if (suburb_countries.includes(countryCode)) {
    return "Suburb";
  } else if (neighborhood_countries.includes(countryCode)) {
    return "Neighborhood";
  } else if (district_countries.includes(countryCode)) {
    return "District";
  } else if (townland_countries.includes(countryCode)) {
    return "Townland";
  } else if (village_township_countries.includes(countryCode)) {
    return "Village or township";
  } else if (island_countries.includes(countryCode)) {
    return "Island";
  } else if (brgy_countries.includes(countryCode)) {
    return "Barangay";
  } else {
    return "Suburb"
  }
}

const getStateLabel = (countryCode) => {
  const state_countries = ["BR", "IN", "MY", "MX", "NG", "US", "VE"];
  const province_countries = ["AR", "AM", "CA", "CL", "CN", "CR", "EG", "SV", "ID", "IQ", "IT", "KZ", "MN", "MZ", "PA", "PG", "PE", "PH", "SO", "ES", "SR", "TH", "TR", "UY", "UZ", "VN"];
  const island_countries = ["BS", "CV", "KY", "PF", "KI", "KN", "TV"];
  const parish_countries = ["BB", "JM"];
  const oblast_countries = ["BY", "RU", "UA"];
  const department_countries = ["CO", "HN", "NI"];
  const area_countries = ["HK"];
  const county_countries = ["IE", "TW"];
  const prefecture_countries = ["JP"];
  const district_countries = ["NR"];
  const dosi_countries = ["KR"];
  const emirate_countries = ["AE"];

  if (state_countries.includes(countryCode)) {
    return "State";
  } else if (province_countries.includes(countryCode)) {
    return "Province";
  } else if (island_countries.includes(countryCode)) {
    return "Island";
  } else if (parish_countries.includes(countryCode)) {
    return "Parish";
  } else if (oblast_countries.includes(countryCode)) {
    return "Oblast";
  } else if (department_countries.includes(countryCode)) {
    return "Department";
  } else if (area_countries.includes(countryCode)) {
    return "Area";
  } else if (county_countries.includes(countryCode)) {
    return "County";
  } else if (prefecture_countries.includes(countryCode)) {
    return "Prefecture";
  } else if (district_countries.includes(countryCode)) {
    return "District";
  } else if (dosi_countries.includes(countryCode)) {
    return "Do Si";
  } else if (emirate_countries.includes(countryCode)) {
    return "Emirate";
  } else {
    return "State"
  }
}

const getPostalLabel = (countryCode) => {
  const zip_countries = ["GU", "PR", "US", "PH"];
  const pin_countries = ["IN"];
  const eircode_countries = ["IE"];

  if (zip_countries.includes(countryCode)) {
    return "ZIP";
  } else if (pin_countries.includes(countryCode)) {
    return "PIN";
  } else if (eircode_countries.includes(countryCode)) {
    return "Eircode";
  } else {
    return "Postal code"
  }
}

const hasSuburb = (countryCode) => {
  const countries_suburb = ["AU", "BR", "CN", "HK", "IE", "MX", "NZ", "NG", "PH", "KR", "SC", "TH"];

  if (countries_suburb.includes(countryCode)) {
    return true;
  }
  return false;
}

const hasCity = (countryCode) => {
  const countries_no_city = ["AU", "KY", "GI", "HK", "JP", "MO", "NR", "PR", "SG", "ZA", "AE"];

  if (countries_no_city.includes(countryCode)) {
    return false;
  }
  return true;
}

const hasState = (countryCode) => {
  const countries_no_state = ["AF", "AX", "AL", "DZ", "AD", "AO", "AI", "AQ", "AG", "AW", "AC", "AT", "AZ", "BH", "BD", "BB", "BE", "BZ", "BJ", "BM", "BT", "BO", "BA", "BW", "BV", "IO", "VG", "BN", "BG", "BF", "BI", "KH", "CM", "BQ", "CF", "TD", "KM", "CG", "CD", "CK", "CI", "HR", "CW", "CY", "CZ", "DK", "DJ", "DM", "DO", "EC", "GQ", "ER", "EE", "SZ", "ET", "FK", "FO", "FJ", "FI", "FR", "GF", "TF", "GA", "GM", "GE", "DE", "GH", "GI", "GR", "GL", "GD", "GP", "GU", "GT", "GG", "GN", "GW", "GY", "HT", "HU", "IS", "IM", "IL", "JE", "JO", "KE", "XK", "KW", "KG", "LA", "LV", "LB", "LS", "LR", "LY", "LI", "LT", "LU", "MO", "MG", "MW", "MV", "ML", "MT", "MQ", "MR", "MU", "MT", "MD", "MC", "ME", "MS", "MA", "MM", "NA", "NP", "NL", "NC", "NZ", "NE", "NU", "MK", "NO", "OM", "PK", "PS", "PY", "PN", "PL", "PT", "PR", "QA", "RE", "RO", "RW", "WS", "SM", "ST", "SA", "SN", "RS", "SC", "SL", "SG", "SX", "SK", "SI", "SB", "ZA", "GA", "SS", "LK", "BL", "SH", "LC", "MF", "PM", "VC", "SJ", "SE", "CH", "TJ", "TZ", "TL", "TG", "TK", "TO", "TT", "TA", "TN", "TM", "TC", "UG", "GB", "VU", "VA", "WF", "EH", "YE", "ZM", "ZW"];

  if (countries_no_state.includes(countryCode)) {
    return false;
  }
  return true;
}

const hasPostal = (countryCode) => {
  const countries_no_postal = ["AO", "AQ", "AG", "AW", "BS", "BZ", "BJ", "BO", "BW", "BV", "BF", "BI", "CM", "BQ", "CF", "TD", "KM", "CG", "CD", "CK", "CI", "CW", "DJ", "DM", "GQ", "ER", "FJ", "TF", "GA", "GM", "GH", "GD", "GY", "HK", "JM", "KI", "LY", "MO", "MW", "ML", "MR", "MS", "NR", "NU", "PS", "PA", "QA", "RW", "WS", "ST", "SC", "SL", "SX", "SB", "SS", "KN", "LC", "SR", "TL", "TG", "TK", "TO", "TT", "TV", "UG", "AE", "VU", "YE", "ZW"];

  if (countries_no_postal.includes(countryCode)) {
    return false;
  }
  return true;
}

const getCountryCallingCode = (countryCode) => {
  try {
    const country = Countries.find((c) => c.code === countryCode);
    if (country) {
      return country.country_calling_code;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
}

const getMobilePlaceholder = (countryCode) => {
  try {
    const country = Countries.find((c) => c.code === countryCode);
    if (country) {
      return country.sample_mobile;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
}

const checkIfShippingCountriesValid = (shipping) => {
  if (!shipping.hasOwnProperty('allowed_countries')) return false;
  if (!Array.isArray(shipping.allowed_countries)) return false;
  if (shipping.allowed_countries.length === 0) return false;
  
  const allCountries = getCountriesIso();
  return shipping.allowed_countries.every(country => allCountries.includes(country));
}

export default {
  getCountries,
  getCountriesMobileCodes,
  getCountriesIso,
  getCountriesByIso,
  getStates,
  getSuburbLabel,
  getStateLabel,
  getPostalLabel,
  hasSuburb,
  hasCity,
  hasState,
  hasPostal,
  getCountryCallingCode,
  getMobilePlaceholder,
  checkIfShippingCountriesValid,
};