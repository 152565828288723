import React from 'react';
import Link from 'next/link';
import cn from 'classnames';

import ColorUtils from '../utils/ColorUtils';

const Header = ({
  merchant,
  colors,
  livemode,
  modalVisible,
  mode,
  multiItems,
  onCancel,
  onToggleModal,
}) => {
  
  const onDetailsClick = (e) => {
    e.preventDefault();
    onToggleModal();
  }
  
  return (
    <header className={cn("header min-h-7 z-12", {'py-0 px-5': mode === 'save_card'})} style={ !modalVisible ? { backgroundColor: `${ColorUtils.hex2rgba(colors.primary)}` } : {}}>
      <div className="header-content box-border flex flex-no-wrap justify-center items-stretch">
        <div className="flex box-border flex-initial min-w-0 max-w-full items-center flex-grow">
          <div className="box-border flex-initial min-w-0 max-w-full w-auto">
            <a hrref="#" className="no-underline cursor-pointer text-blue-500 block -ml-6 pl-6 focus:outline-none" onClick={onCancel}>
              <div className="relative">
                <div className="box-border flex flex-no-wrap items-center">
                  <svg className="header-arrow h-3 mr-2" focusable="false" width="12" height="12" viewBox="0 0 16 16"><path d="M3.417 7H15a1 1 0 0 1 0 2H3.417l4.591 4.591a1 1 0 0 1-1.415 1.416l-6.3-6.3a1 1 0 0 1 0-1.414l6.3-6.3A1 1 0 0 1 8.008 2.41z" fillRule="evenodd"></path></svg>
                  <div className="box-border flex-initial min-w-0 max-w-full flex-basis-0 flex-grow merchant">
                    {
                      merchant.use_logo
                      ? <div className="header-image header-logo flex flex-no-wrap box-border justify-center items-center flex-initial min-w-0 max-w-full flex-basis-auto flex-shrink-0">
                          <img className="max-w-37 max-h-8 mr-2 lg:max-w-75 lg:max-h-7" src={merchant.logo} alt={`${merchant.name} logo`} />
                        </div>
                      : !!merchant.icon
                      ? <div className="header-image header-icon flex flex-no-wrap box-border justify-center items-center h-7 w-7 mr-2 shadow rounded-full flex-initial min-w-0 max-w-full flex-basis-auto flex-shrink-0">
                          <img className="h-full w-full rounded-full object-cover" src={merchant.icon} alt={`${merchant.name} icon`} />
                        </div>
                      : <div className="header-image header-icon flex flex-no-wrap box-border justify-center items-center h-7 w-7 mr-2 shadow rounded-full flex-initial min-w-0 max-w-full flex-basis-auto flex-shrink-0">
                          <img className="h-full w-full rounded-full object-cover" src="https://client-objects.s3-us-west-2.amazonaws.com/storeicon.png" alt="Merchant Store" />
                        </div>
                      
                    }
                    <span className="header-back flex-initial m-0 text-sm font-medium text-gray-800">Back</span>
                    {
                      !merchant.use_logo && <h1 className="header-name flex-initial min-w-0 max-w-full m-0 text-sm font-medium text-gray-800 truncate">{merchant.name}</h1>
                    }
                  </div>
                </div>
              </div>
            </a>
          </div>
          {
            !livemode && 
            <div className="mx-2 box-border flex-initial min-w-0 max-w-full flex-basis-auto flex-shrink-0 lg:mx-3">
              <span className="tag-test inline-flex items-center px-1 py-0.5 rounded text-tiny font-semibold leading-4 uppercase bg-yellow-200 text-orange-600">
                Test Mode
              </span>
              <span className="tag-testmobile inline-flex items-center mt-0.5 px-1 py-0.5 rounded text-tiny font-semibold leading-4 uppercase bg-yellow-200 text-orange-600">
                Test
              </span>
            </div>
          }
        </div>
        {
          multiItems &&
          <div className="header-actions box-border flex-initial min-w-0 max-w-full flex-basis-auto flex-shrink-0">
            <button onClick={(e) => onDetailsClick(e)} className="leading-tighter m-0 overflow-visible normal-case text-xs p-0 text-blue-500 bg-transparent border-none outline-none rounded-none w-full relative text-right transition-opacity duration-250 ease focus:outline-none" style={{ WebkitTapHighlightColor: 'transparent', textDecorationSkipInk: 'none' }}>
              <div className="box-border flex flex-no-wrap justify-end items-center">
                {
                  modalVisible
                  ? <svg className={cn("h-3 flex-shrink-0 mr-0 ml-2 order-1 w-3", { 'fill-dark': !ColorUtils.isDark(colors.primary) || modalVisible, 'fill-light': ColorUtils.isDark(colors.primary) && !modalVisible })} focusable="false" width="12" height="12"><path d="M10.193 9.756a.75.75 0 0 0 1.062-1.062L6.53 3.97a.75.75 0 0 0-1.06 0L.745 8.694a.75.75 0 0 0 1.062 1.062L6 5.563l4.193 4.193z" fillRule="evenodd"></path></svg>
                  : <svg className={cn("h-3 flex-shrink-0 mr-0 ml-2 order-1 w-3", { 'fill-dark': !ColorUtils.isDark(colors.primary) || modalVisible, 'fill-light': ColorUtils.isDark(colors.primary) && !modalVisible })} focusable="false" width="12" height="12"><path d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z" fillRule="evenodd"></path></svg>
                }
                <div className="max-md:absolute max-md:max-w-26 max-md:right-5" style={{ opacity: '1', transform: 'none' }}>
                  <span className={cn("m-0 text-13px font-medium truncate max-md:w-full max-md:block", { 'text-dark': !ColorUtils.isDark(colors.primary) || modalVisible, 'text-light': ColorUtils.isDark(colors.primary) && !modalVisible } )}>Details</span>
                </div>
              </div>
            </button>
          </div>
        }
      </div>
    </header>
  );
}

export default Header;