import React, {useState, useEffect} from 'react';
import cn from 'classnames';
import AnimateHeight from 'react-animate-height';
import ReactTooltip from 'react-tooltip';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';

import CardUtils from '../utils/CardUtils';
import ColorUtils from '../utils/ColorUtils';
import CountryUtils from '../utils/CountryUtils';
import NumberUtils from '../utils/NumberUtils';

const CardInfo = React.forwardRef(({
  brand,
  cardDetailsMissing,
  cardNumberMissing,
  cardNumberError,
  cardNumberErrorMsg,
  cardNumber,
  onCardNumberChanged,
  onCardNumberBlur,
  expiry,
  expiryMissing,
  expiryError,
  expiryErrorMsg,
  onExpiryChanged,
  onExpiryBlur,
  cvc,
  cvcMissing,
  cvcError,
  cvcErrorMsg,
  onCvcChanged,
  onCvcBlur,
  loaded,
  shipping,
  sameBillingAsShipping,
  onUseShippingAsBillingChecked,
  name,
  nameMissing,
  nameError,
  nameErrorMsg,
  onNameChanged,
  onNameBlur,
  billing,
  billingDetailsMissing,
  billingCountry,
  onBillingCountryChanged,
  billingAddressLine1,
  billingAddressLine1Missing,
  onBillingAddressLine1Changed,
  billingAddressLine2,
  onBillingAddressLine2Changed,
  billingAddressSuburb,
  onBillingAddressSuburbChanged,
  billingAddressCity,
  billingAddressCityMissing,
  onBillingAddressCityChanged,
  billingAddressPostalCode,
  billingAddressPostalMissing,
  onBillingAddressPostalChanged,
  billingAddressState,
  billingAddressStateMissing,
  onBillingAddressStateChanged,
  saveInfoChecked,
  onSaveInfoChanged,
  onShowLinkWithMagpieInfo,
  countryCodes,
  saveInfoPhoneCountry,
  onSaveInfoCountryCodeChanged,
  saveInfoPhone,
  saveInfoPhoneLoading,
  saveInfoPhoneVerified,
  onSaveInfoPhoneChanged,
  onSaveInfoPhoneVerify,
  onSaveInfoPhoneBlur,
  onChangeSaveInfoPhone,
  saveInfoPhoneMissing,
  saveInfoPhoneError,
  savedPaymentInfo,
  useSavedPaymentInfo,
  onUseSavedPaymentInfo,
  updateSavedCardInfo,
  onUpdateSavedCardInfo,
  complete,
  loading,
  success,
  buttonColor,
  total,
  currency,
  merchant,
  mode,
  submit,
  onPayClick
}, ref) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    setTooltipVisible(true);
  }, [])

  return (
    <div ref={ref} className="box-border flex flex-row -m-2 flex-wrap">
      {savedPaymentInfo ? useSavedPaymentInfo ? null : (
        <div className="card-container box-border flex-initial min-w-0 max-w-full w-full p-2">
          <div>
            <div className="box-border flex flex-no-wrap justify-between relative">
              <label htmlFor="card-fieldset">
                <span className="m-0 text-13px font-medium text-gray-600">Card information</span>
              </label>
              {cardDetailsMissing ? (
                <div className={cn("transition-all duration-300 ease", { 'transform translate-y-full opacity-0': !cardDetailsMissing, 'transform translate-y-0 opacity-100': cardDetailsMissing })}>
                  <span className="m-0 text-tiny font-medium text-danger uppercase">
                    <span>Required</span>
                  </span>
                </div>
              ) : (
                <button type="button" className="cursor-pointer text-xs p-0 text-magpie bg-transparent border-none outline-none rounded-none focus:outline-none leading-tight transition-button" onClick={onUseSavedPaymentInfo}>
                  <div className="flex flex-no-wrap justify-center items-center">
                    <span className="m-0 font-medium truncate">{`Keep using •••• ${savedPaymentInfo.card.last4}`}</span>
                  </div>
                </button>
              )}
            </div>
            <fieldset id="card-fieldset" className="m-0 mt-1 border-none p-0">
              <div id="card-fieldset" className="relative flex flex-wrap">
                <div className="box-border flex-initial min-w-0 max-w-full w-full">
                  <div className="relative">
                    <span className="relative block m-0 p-0">
                      <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none rounded-t-md lg:h-9 lg:text-sm", { 'invalid': cardNumberMissing || cardNumberError, 'input-disabled': loading || success })} autoComplete="cc-number" autoCorrect="off" spellCheck="false" id="cardNumber" name="cardNumber" inputMode="numeric" aria-label="Card number" placeholder="1234 1234 1234 1234" maxLength="19" aria-invalid={cardNumberError} value={cardNumber || ""} onBlur={(e) => onCardNumberBlur(e)} onChange={(e) => onCardNumberChanged(e)} />
                    </span>
                    {
                      !cardNumberError && <div className="h-full absolute right-0 top-0 mr-2 pointer-events-none z-3 overflow-hidden">
                        <div className={cn("card-icons h-full flex items-center", { 'is-visible': !brand, 'is-hidden': !!brand })}>
                          <div className="">
                            <span className="pointer-events-none flex items-center z-3 pr-1">
                              <img className="h-4" src="/images/visa.svg" alt="visa" />
                            </span>
                          </div>
                          <div className="">
                            <span className="pointer-events-none flex items-center z-3 pr-1">
                              <img className="h-4" src="/images/mastercard.svg" alt="mastercard" />
                            </span>
                          </div>
                          <div className="">
                            <span className="pointer-events-none flex items-center z-3 pr-1">
                              <img className="h-4" src="/images/jcb.svg" alt="jcb" />
                            </span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className={cn("card-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded' : cardNumberError || !!brand })}>
                      {
                        cardNumberError
                        ? <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                        : <img className="h-4" src={CardUtils.getBrandIcon(cardNumber)} alt={brand} />
                      }
                    </div>
                  </div>
                </div>
                <div className="box-border flex-initial min-w-0 max-w-full w-1/2">
                  <div className="relative">
                    <span className="relative block m-0 p-0">
                      <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none rounded-bl-md lg:h-9 lg:text-sm", { 'invalid': expiryMissing || expiryError, 'input-disabled': loading || success })} autoComplete="cc-exp" autoCorrect="off" spellCheck="false" id="cardExpiry" name="cardExpiry" inputMode="numeric" aria-label="Expiration" placeholder="MM / YY" maxLength="7" aria-invalid={expiryError} value={expiry || ""} onChange={(e) => onExpiryChanged(e)} onBlur={(e) => onExpiryBlur(e)}  />
                    </span>
                    <div className={cn("form-input-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded': expiryError })}>
                      <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                    </div>
                  </div>
                </div>
                <div className="box-border flex-initial min-w-0 max-w-full w-1/2">
                  <div className="relative">
                    <span className="relative block m-0 p-0">
                      <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none rounded-br-md lg:h-9 lg:text-sm", { 'invalid': cvcMissing || cvcError, 'input-disabled': loading || success })} autoComplete="cc-csc" autoCorrect="off" spellCheck="false" id="cardCvc" name="cardCvc" inputMode="numeric" aria-label="CVC" placeholder="CVC" maxLength="3" aria-invalid={cvcError} value={cvc || ""} onChange={(e) => onCvcChanged(e)} onBlur={(e) => onCvcBlur(e)} />
                    </span>
                    <div className={cn("form-input-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded': loaded || cvcError })}>
                      {
                        cvcError
                        ? <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                        : <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 21" className="h-4">
                          <g fill="none" fillRule="evenodd"><g className="fill-icon"><g transform="translate(0 2)"><path d="M21.68 0H2c-.92 0-2 1.06-2 2v15c0 .94 1.08 2 2 2h25c.92 0 2-1.06 2-2V9.47a5.98 5.98 0 0 1-3 1.45V11c0 .66-.36 1-1 1H3c-.64 0-1-.34-1-1v-1c0-.66.36-1 1-1h17.53a5.98 5.98 0 0 1 1.15-9z" opacity=".2"></path><path d="M19.34 3H0v3h19.08a6.04 6.04 0 0 1 .26-3z" opacity=".3"></path></g><g transform="translate(18)"><path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14zM4.22 4.1h-.79l-1.93.98v1l1.53-.8V9.9h1.2V4.1zm2.3.8c.57 0 .97.32.97.78 0 .5-.47.85-1.15.85h-.3v.85h.36c.72 0 1.21.36 1.21.88 0 .5-.48.84-1.16.84-.5 0-1-.16-1.52-.47v1c.56.24 1.12.37 1.67.37 1.31 0 2.21-.67 2.21-1.64 0-.68-.42-1.23-1.12-1.45.6-.2.99-.73.99-1.33C8.68 4.64 7.85 4 6.65 4a4 4 0 0 0-1.57.34v.98c.48-.27.97-.42 1.44-.42zm4.32 2.18c.73 0 1.24.43 1.24.99 0 .59-.51 1-1.24 1-.44 0-.9-.14-1.37-.43v1.03c.49.22.99.33 1.48.33.26 0 .5-.04.73-.1.52-.85.82-1.83.82-2.88l-.02-.42a2.3 2.3 0 0 0-1.23-.32c-.18 0-.37.01-.57.04v-1.3h1.44a5.62 5.62 0 0 0-.46-.92H9.64v3.15c.4-.1.8-.17 1.2-.17z"></path></g></g></g>
                        </svg>
                      }
                    </div>
                  </div>
                </div>
                <AnimateHeight
                  height={(cardNumberErrorMsg || expiryErrorMsg || cvcErrorMsg) ? 'auto' : 0}
                  duration={500}
                  animateOpacity
                  style={{ flexShrink: 0, maxWidth: '100%' }}>
                  <span className="m-0 text-13px text-danger">
                    <span>{`${cardNumberErrorMsg || expiryErrorMsg || cvcErrorMsg} `}</span>
                  </span>
                </AnimateHeight>
              </div>
            </fieldset>
          </div>
        </div>
      ) : (
        <div className="card-container box-border flex-initial min-w-0 max-w-full w-full p-2">
          <div>
            <div className="box-border flex flex-no-wrap justify-between relative">
              <label htmlFor="card-fieldset">
                <span className="m-0 text-13px font-medium text-gray-600">Card information</span>
              </label>
              <div className={cn("transition-all duration-300 ease", { 'transform translate-y-full opacity-0': !cardDetailsMissing, 'transform translate-y-0 opacity-100': cardDetailsMissing })}>
                <span className="m-0 text-tiny font-medium text-danger uppercase">
                  <span>Required</span>
                </span>
              </div>
            </div>
            <fieldset id="card-fieldset" className="m-0 mt-1 border-none p-0">
              <div id="card-fieldset" className="relative flex flex-wrap">
                <div className="box-border flex-initial min-w-0 max-w-full w-full">
                  <div className="relative">
                    <span className="relative block m-0 p-0">
                      <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none rounded-t-md lg:h-9 lg:text-sm", { 'invalid': cardNumberMissing || cardNumberError, 'input-disabled': loading || success })} autoComplete="cc-number" autoCorrect="off" spellCheck="false" id="cardNumber" name="cardNumber" inputMode="numeric" aria-label="Card number" placeholder="1234 1234 1234 1234" maxLength="19" aria-invalid={cardNumberError} value={cardNumber || ""} onBlur={(e) => onCardNumberBlur(e)} onChange={(e) => onCardNumberChanged(e)} />
                    </span>
                    {
                      !cardNumberError && <div className="h-full absolute right-0 top-0 mr-2 pointer-events-none z-3 overflow-hidden">
                        <div className={cn("card-icons h-full flex items-center", { 'is-visible': !brand, 'is-hidden': !!brand })}>
                          <div className="">
                            <span className="pointer-events-none flex items-center z-3 pr-1">
                              <img className="h-4" src="/images/visa.svg" alt="visa" />
                            </span>
                          </div>
                          <div className="">
                            <span className="pointer-events-none flex items-center z-3 pr-1">
                              <img className="h-4" src="/images/mastercard.svg" alt="mastercard" />
                            </span>
                          </div>
                          <div className="">
                            <span className="pointer-events-none flex items-center z-3 pr-1">
                              <img className="h-4" src="/images/jcb.svg" alt="jcb" />
                            </span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className={cn("card-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded' : cardNumberError || !!brand })}>
                      {
                        cardNumberError
                        ? <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                        : <img className="h-4" src={CardUtils.getBrandIcon(cardNumber)} alt={brand} />
                      }
                    </div>
                  </div>
                </div>
                <div className="box-border flex-initial min-w-0 max-w-full w-1/2">
                  <div className="relative">
                    <span className="relative block m-0 p-0">
                      <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none rounded-bl-md lg:h-9 lg:text-sm", { 'invalid': expiryMissing || expiryError, 'input-disabled': loading || success })} autoComplete="cc-exp" autoCorrect="off" spellCheck="false" id="cardExpiry" name="cardExpiry" inputMode="numeric" aria-label="Expiration" placeholder="MM / YY" maxLength="7" aria-invalid={expiryError} value={expiry || ""} onChange={(e) => onExpiryChanged(e)} onBlur={(e) => onExpiryBlur(e)}  />
                    </span>
                    <div className={cn("form-input-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded': expiryError })}>
                      <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                    </div>
                  </div>
                </div>
                <div className="box-border flex-initial min-w-0 max-w-full w-1/2">
                  <div className="relative">
                    <span className="relative block m-0 p-0">
                      <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none rounded-br-md lg:h-9 lg:text-sm", { 'invalid': cvcMissing || cvcError, 'input-disabled': loading || success })} autoComplete="cc-csc" autoCorrect="off" spellCheck="false" id="cardCvc" name="cardCvc" inputMode="numeric" aria-label="CVC" placeholder="CVC" maxLength="3" aria-invalid={cvcError} value={cvc || ""} onChange={(e) => onCvcChanged(e)} onBlur={(e) => onCvcBlur(e)} />
                    </span>
                    <div className={cn("form-input-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded': loaded || cvcError })}>
                      {
                        cvcError
                        ? <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                        : <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 21" className="h-4">
                          <g fill="none" fillRule="evenodd"><g className="fill-icon"><g transform="translate(0 2)"><path d="M21.68 0H2c-.92 0-2 1.06-2 2v15c0 .94 1.08 2 2 2h25c.92 0 2-1.06 2-2V9.47a5.98 5.98 0 0 1-3 1.45V11c0 .66-.36 1-1 1H3c-.64 0-1-.34-1-1v-1c0-.66.36-1 1-1h17.53a5.98 5.98 0 0 1 1.15-9z" opacity=".2"></path><path d="M19.34 3H0v3h19.08a6.04 6.04 0 0 1 .26-3z" opacity=".3"></path></g><g transform="translate(18)"><path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14zM4.22 4.1h-.79l-1.93.98v1l1.53-.8V9.9h1.2V4.1zm2.3.8c.57 0 .97.32.97.78 0 .5-.47.85-1.15.85h-.3v.85h.36c.72 0 1.21.36 1.21.88 0 .5-.48.84-1.16.84-.5 0-1-.16-1.52-.47v1c.56.24 1.12.37 1.67.37 1.31 0 2.21-.67 2.21-1.64 0-.68-.42-1.23-1.12-1.45.6-.2.99-.73.99-1.33C8.68 4.64 7.85 4 6.65 4a4 4 0 0 0-1.57.34v.98c.48-.27.97-.42 1.44-.42zm4.32 2.18c.73 0 1.24.43 1.24.99 0 .59-.51 1-1.24 1-.44 0-.9-.14-1.37-.43v1.03c.49.22.99.33 1.48.33.26 0 .5-.04.73-.1.52-.85.82-1.83.82-2.88l-.02-.42a2.3 2.3 0 0 0-1.23-.32c-.18 0-.37.01-.57.04v-1.3h1.44a5.62 5.62 0 0 0-.46-.92H9.64v3.15c.4-.1.8-.17 1.2-.17z"></path></g></g></g>
                        </svg>
                      }
                    </div>
                  </div>
                </div>
                <AnimateHeight
                  height={(cardNumberErrorMsg || expiryErrorMsg || cvcErrorMsg) ? 'auto' : 0}
                  duration={500}
                  animateOpacity
                  style={{ flexShrink: 0, maxWidth: '100%' }}>
                  <span className="m-0 text-13px text-danger">
                    <span>{`${cardNumberErrorMsg || expiryErrorMsg || cvcErrorMsg} `}</span>
                  </span>
                </AnimateHeight>
              </div>
            </fieldset>
          </div>
        </div>
      )}

      {
        shipping &&
        <div className="box-border flex-initial min-w-0 max-w-full w-full p-2">
          <div>
            <div className={cn("flex items-baseline", { 'checked': sameBillingAsShipping })}>
              <div className="mr-3 relative top-1">
                <input disabled={loading || success} className={cn("checkout-checkbox appearance-none bg-transparent border-none shadow-none h-4 opacity-0 outline-none absolute top-0 w-4 cursor-pointer", {'input-disabled': loading || success})} id="useShippingForBilling" name="useShippingForBilling" type="checkbox" defaultChecked={sameBillingAsShipping} onChange={(e) => onUseShippingAsBillingChecked(e)} />
                <span className={cn("checkout-checkbox-styledInput pointer-events-none bg-transparent rounded-0.5 shadow-checkbox block h-4 min-w-4 select-none w-4", {
                  "before:empty-content before:checkbox-checked": sameBillingAsShipping,
                  "cursor-pointer": !loading && !success,
                  "cursor-default pointer-events-none before:cursor-default before:pointer-events-none after:bg-white after:-bottom-px after:empty-content after:-left-px after:opacity-50 after:pointer-events-auto after:absolute after:-right-0.5 after:-top-0.5": loading || success
                })} />
              </div>
              <label htmlFor="useShippingForBilling">
                <span className={cn("m-0 text-13px font-medium text-gray-600 select-none cursor-pointer", {
                  "text-placeholder": loading || success
                })}>
                  <span>Billing address is same as shipping</span>
                </span>
              </label>
            </div>
          </div>
        </div>
      }

      <div className="billing-container box-border flex-initial min-w-0 max-w-full w-full">
        <AnimateHeight
          height={shipping ? (sameBillingAsShipping ? 0 : 'auto') : 'auto'}
          duration={500}
          animateOpacity
          style={{ opacity: 1, padding: '0.5rem' }}
        >
          <div className="pointer-events-auto">
            <div className="box-border flex flex-row -m-2 flex-wrap">
              <div className="box-border flex-initial min-w-0 max-w-full w-full p-2">
                <div>
                  <div className="box-border flex flex-no-wrap justify-between relative">
                    <label htmlFor="billingName">
                      <span className="m-0 text-13px font-medium text-input">Cardholder name</span>
                    </label>
                    <div className={cn("transition-all duration-300 ease", { 'transform translate-y-full opacity-0': !nameMissing, 'transform translate-y-0 opacity-100': nameMissing })}>
                      <span className="m-0 text-tiny font-medium text-danger uppercase">
                        <span>Required</span>
                      </span>
                    </div>
                  </div>
                  <div className="mt-1 mx-0 border-none p-0">
                    <div className="relative flex flex-wrap">
                      <div className="box-border flex-initial min-w-0 max-w-full w-full">
                        <div className="relative">
                          <span className="relative block m-0 p-0">
                            <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-md lg:h-9 lg:text-sm", {'invalid': nameMissing || nameError, 'input-disabled': loading || success})} autoComplete="ccname" autoCorrect="off" spellCheck="false" id="billingName" name="billingName" type="text" placeholder="Full name on card" aria-invalid={nameMissing || nameError} value={name} onChange={(e) => onNameChanged(e)} onBlur={(e) => onNameBlur(e)} />
                          </span>
                        </div>
                      </div>
                      <AnimateHeight
                        height={nameErrorMsg ? 'auto' : 0}
                        animateOpacity
                        duration={500}
                        style={{ flexShrink: 0, maxWidth: '100%' }}
                      >
                        <span className="m-0 text-13px text-danger transition-all duration-300 ease origin-top-left">
                          <span>{`${nameErrorMsg} `}</span>
                        </span>
                      </AnimateHeight>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-border flex-initial min-w-0 max-w-full w-full p-2">
                <div>
                  <div className="box-border flex flex-no-wrap justify-between relative">
                    <label htmlFor={billing ? 'billing-address-fieldset' : 'billingCountry'}>
                      <span className="m-0 text-13px font-medium text-input">
                        { billing ? 'Billing address' : 'Country or region' }
                      </span>
                    </label>
                    <div className={cn("transition-all duration-300 ease", { 'transform translate-y-full opacity-0': !billingDetailsMissing, 'transform translate-y-0 opacity-100': billingDetailsMissing })}>
                      <span className="m-0 text-tiny font-medium text-danger uppercase">
                        <span>Required</span>
                      </span>
                    </div>
                  </div>
                  {
                    billing 
                    ? <fieldset id="billing-address-fieldset" className="mt-1 mx-0 border-none p-0">
                        <div className="relative flex flex-wrap">
                          <div className="box-border flex-initial min-w-0 max-w-full w-full">
                            <div className="relative">
                              <div>
                                <div className="flex relative">
                                  <select disabled={loading || loading} className={cn("checkout-select w-full h-11 text-base py-2 pl-3 pr-6 text-input placeholder-placeholder leading-normal border-0 bg-white shadow-input transitions-input appearance-none rounded-none rounded-t-md lg:h-9 lg:text-sm", {
                                    'invalid': billingDetailsMissing,
                                    'input-disabled': loading || success
                                  })} id="billingCountry" name="billingCountry" autoComplete="billing country" aria-label="Country or region" value={billingCountry || ''} onChange={(e) => onBillingCountryChanged(e)}>
                                    <option value disabled hidden></option>
                                    {
                                      CountryUtils.getCountries().map((item, index) => 
                                        <option key={index} value={item.value}>{item.label}</option>
                                      )
                                    }
                                  </select>
                                  <svg className="absolute top-1/2 right-3 -mt-1.5 pointer-events-none w-3 h-12 z-3" focusable="false" width="12" height="12">
                                    <path d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z" fillRule="evenodd"></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="box-border flex-initial min-w-0 max-w-full w-full">
                            <div className="relative">
                              <span className="relative block m-0 p-0">
                                <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm", {
                                  'invalid': billingAddressLine1Missing || billingDetailsMissing, 'input-disabled': loading || success
                                })} autoComplete="billing address-line1" autoCorrect="off" spellCheck="false" id="billingAddressLine1" name="billingAddressLine1" type="text" aria-label="Address line 1" placeholder="Address line 1" aria-invalid={billingAddressLine1Missing || billingDetailsMissing} value={billingAddressLine1 || ''} onChange={(e) => onBillingAddressLine1Changed(e)} />
                              </span>
                              <div className={cn("form-input-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded': billingAddressLine1Missing })}>
                                <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                              </div>
                            </div>
                          </div>
                          <div className="box-border flex-initial min-w-0 max-w-full w-full">
                            <div className="relative">
                              <span className="relative block m-0 p-0">
                                <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm", { 
                                  'rounded-b-md': !CountryUtils.hasCity(billingCountry) && !CountryUtils.hasPostal(billingCountry),
                                  'invalid': billingDetailsMissing, 'input-disabled': loading || success
                                })} autoComplete="billing address-line2" autoCorrect="off" spellCheck="false" id="billingAddressLine2" name="billingAddressLine2" type="text" aria-label="Address line 2" placeholder="Address line 2" aria-invalid={billingDetailsMissing} value={billingAddressLine2 || ''} onChange={(e) => onBillingAddressLine2Changed(e)} />
                              </span>
                            </div>
                          </div>
                          {
                            CountryUtils.hasSuburb(billingCountry) &&
                            <div className="box-border flex-initial min-w-0 max-w-full w-full">
                              <div className="relative">
                                <span className="relative block m-0 p-0">
                                  <input className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm", {
                                    'invalid': billingDetailsMissing, 'input-disabled': loading || success
                                  })} autoComplete="billing address-level3" autoCorrect="off" spellCheck="false" id="billingSuburb" name="billingSuburb" type="text" aria-label={CountryUtils.getSuburbLabel(billingCountry)} placeholder={CountryUtils.getSuburbLabel(billingCountry)} aria-invalid={billingDetailsMissing} value={billingAddressSuburb || ''} onChange={(e) => onBillingAddressSuburbChanged(e)} />
                                </span>
                              </div>
                            </div>
                          }
                          {
                            CountryUtils.hasCity(billingCountry) &&
                            <div className={cn("box-border flex-initial min-w-0 max-w-full", { 'w-1/2': CountryUtils.hasPostal(billingCountry), 'w-full': !CountryUtils.hasPostal(billingCountry) })}>
                              <div className="relative">
                                <span className="relative block m-0 p-0">
                                  <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm", 
                                  {
                                    'rounded-bl-md': !CountryUtils.hasState(billingCountry),
                                    'rounded-b-md': !CountryUtils.hasPostal(billingCountry) && !CountryUtils.hasState(billingCountry),
                                    'invalid': billingAddressCityMissing || billingDetailsMissing, 'input-disabled': loading || success
                                  })} autoComplete="billing address-level2" autoCorrect="off" spellCheck="false" id="billingCity" name="billingCity" type="text" aria-label="City" placeholder="City" aria-invalid={billingAddressCityMissing || billingDetailsMissing} value={billingAddressCity || ''} onChange={(e) => onBillingAddressCityChanged(e)} />
                                </span>
                                <div className={cn("form-input-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded': billingAddressCityMissing })}>
                                  <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                                </div>
                              </div>
                            </div>
                          }
                          {
                            CountryUtils.hasPostal(billingCountry) &&
                            <div className={cn("box-border flex-initial min-w-0 max-w-full", { 'w-1/2': CountryUtils.hasCity(billingCountry), 'w-full': !CountryUtils.hasCity(billingCountry) })}>
                              <div className="relative">
                                <span className="relative block m-0 p-0">
                                  <input disabled={loading || success} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm",
                                  {
                                    'rounded-br-md': !CountryUtils.hasState(billingCountry),
                                    'rounded-b-md': !CountryUtils.hasCity(billingCountry) && !CountryUtils.hasState(billingCountry),
                                    'invalid': billingAddressPostalMissing ||  billingDetailsMissing,
                                    'input-disabled': loading || success
                                  })} autoComplete="billing postal-code" autoCorrect="off" spellCheck="false" id="billingPostalCode" name="billingPostalCode" type="text" aria-label={CountryUtils.getPostalLabel(billingCountry)} placeholder={CountryUtils.getPostalLabel(billingCountry)} aria-invalid={billingAddressPostalMissing || billingDetailsMissing} value={billingAddressPostalCode || ''} onChange={(e) => onBillingAddressPostalChanged(e)} />
                                </span>
                                <div className={cn("form-input-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded': billingAddressPostalMissing })}>
                                  <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                                </div>
                              </div>
                            </div>
                          }
                          {
                            CountryUtils.hasState(billingCountry) && CountryUtils.getStates(billingCountry).length > 0 &&
                            <div className="box-border flex-initial min-w-0 max-w-full w-full">
                              <div className="relative">
                                <div>
                                  <div className="flex relative">
                                    <select disabled={loading || success} className={cn("checkout-select w-full h-11 text-base py-2 pl-3 pr-6 leading-normal border-0 bg-white shadow-input transitions-input appearance-none rounded-none rounded-b-md lg:h-9 lg:text-sm", {
                                      'invalid': billingAddressStateMissing || billingDetailsMissing,
                                      'text-placeholder empty': !billingAddressState || billingAddressState === 'true',
                                      'text-input': !!billingAddressState && billingAddressState !== 'true',
                                      'input-disabled': loading || success
                                    })} id="billingState" name="billingState" autoComplete="billing address-level1" aria-label={CountryUtils.getStateLabel(billingCountry)} value={billingAddressState || ''} onChange={(e) => onBillingAddressStateChanged(e)}>
                                      <option value>{CountryUtils.getStateLabel(billingCountry)}</option>
                                      {
                                        CountryUtils.getStates(billingCountry).map((item, index) =>
                                          <option key={index} value={item.name}>{item.name}</option>
                                        )
                                      }
                                    </select>
                                    <svg className="absolute top-1/2 right-3 -mt-1.5 pointer-events-none w-3 h-12 z-3" focusable="false" width="12" height="12">
                                      <path d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z" fillRule="evenodd"></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                          <div className="opacity-0 h-0">

                          </div>
                        </div>
                      </fieldset>
                    : <div className="mt-1 mx-0 border-none p-0">
                        <div className="relative flex flex-wrap perspective-800">
                          <div className="box-border flex-initial min-w-0 max-w-full w-full">
                            <div className="relative">
                              <div>
                                <div className="flex relative">
                                  <select disabled={loading || success} className={cn("checkout-select w-full h-11 text-base py-2 pl-3 pr-6 text-input leading-normal border-0 bg-white shadow-input transitions-input appearance-none rounded-md lg:h-9 lg:text-sm", {'input-disabled': loading || success})} id="billingCountry" name="billingCountry" autoComplete="billing country" aria-label="Country or region" value={billingCountry} onChange={(e) => onBillingCountryChanged(e)}>
                                    <option value disabled hidden></option>
                                    {
                                      CountryUtils.getCountries().map((item, index) => 
                                        <option key={index} value={item.value}>{item.label}</option>
                                      )
                                    }
                                  </select>
                                  <svg className="absolute top-1/2 right-3 -mt-1.5 pointer-events-none w-3 h-12 z-3" focusable="false" width="12" height="12">
                                    <path d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z" fillRule="evenodd"></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="opacity-0 h-0">
                            <span className="m-0 text-13px text-danger transition-all duration-300 ease origin-top-left">
                              <span></span>
                            </span>
                          </div>
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </AnimateHeight>
      </div>

      {mode === 'subscription' && (
        <AnimateHeight
          height="auto"
          animateOpacity
          className="w-full transition-height duration-300 ease">
          <div className="box-border flex-initial min-w-0 max-w-full w-full mt-2 p-2">
            <div className="pointer-events-auto h-auto opacity-100">
              <div>
                <div className={cn("flex flex-row justify-between items-start p-3 bg-blue-50 rounded-md shadow-tab lg:py-2", {
                  "rounded-b-none": saveInfoChecked
                })}>
                  <div className="-mt-0.5">
                    <div className="flex items-baseline">
                      <label htmlFor="enableMagpiePass">
                        <span className="m-0 select-none cursor-pointer text-sm font-medium text-blue-700">
                          Secure payment vault
                          <div className="text-label text-13px leading-5 mt-1">
                            Your payment information will be saved securely after successful payment.
                          </div>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimateHeight>
      )}

      {mode === 'save_card' && (
        <AnimateHeight
          height="auto"
          animateOpacity
          className="w-full transition-height duration-300 ease">
          <div className="box-border flex-initial min-w-0 max-w-full w-full mt-2 p-2">
            <div className="pointer-events-auto h-auto opacity-100">
              <div>
                <div className="flex flex-row justify-between items-start p-3 bg-blue-50 rounded-md shadow-tab lg:py-2">
                  <div className="-mt-0.5">
                    <div className="flex items-baseline">
                      <label htmlFor="enableMagpiePass">
                        <span className="m-0 select-none cursor-pointer text-sm font-medium text-blue-700">
                          Secure payment vault
                          <div className="text-label text-13px font-normal mt-1 leading-5">
                            <span>To verify ownership of the card, a </span>
                            <span className="font-medium text-input">{NumberUtils.formatCurrency(total/100, currency)} Test Charge</span>
                            <span> will be charged to your card. This amount will be refunded within 24 hours.</span>
                          </div>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimateHeight>
      )}

      {/* Save info for 1-click checkout */}
      {/* NOTE: Temporarily hide save payment info for 3DSv2 */}
      {/* <AnimateHeight
        height={savedPaymentInfo ? 0 : 'auto'}
        animateOpacity
        className="w-full transition-height duration-300 ease">
        <div className="box-border flex-initial min-w-0 max-w-full w-full mt-2 p-2">
          <div className="pointer-events-auto h-auto opacity-100">
            <div>
              <div className={cn("flex flex-row justify-between items-start p-3 bg-white rounded-md shadow-input lg:py-2", {
                "rounded-b-none": saveInfoChecked
              })}>
                <div className="-mt-0.5">
                  <div className="flex items-baseline">
                    <div className="mr-3 relative top-0.75">
                      <input disabled={loading || success} id="enableMagpiePass" name="enableMagpiePass" type="checkbox" className="appearance-none bg-transparent border-none shadow-none h-4 opacity-0 outline-none absolute top-0 w-4 cursor-pointer" checked={saveInfoChecked} onChange={onSaveInfoChanged}/>
                      <span className={cn("pointer-events-none bg-transparent rounded-sm shadow-input block h-4 min-w-4 w-4 transition-checkbox select-none", {
                        "cursor-pointer": !loading &&  !success,
                        "before:empty-content before:checkbox-checked": saveInfoChecked,
                        "cursor-default pointer-events-none before:cursor-default before:pointer-events-none after:bg-white after:-bottom-px after:empty-content after:-left-px after:opacity-50 after:pointer-events-auto after:absolute after:-right-0.5 after:-top-0.5": loading || success
                      })}></span>
                    </div>
                    <label htmlFor="enableMagpiePass">
                      <span className="m-0 select-none cursor-pointer text-sm font-normal text-input">
                        Save my info for secure 1-click checkout
                        <div className="text-placeholder text-13px mt-1">
                          {`Pay faster on ${merchant} on your next transactions.`}
                        </div>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <AnimateHeight
                height={saveInfoChecked ? 'auto' : 0}
                animateOpacity
                className={cn("transition-height duration-300 ease", {
                  "pointer-events-none": !saveInfoChecked,
                  "pointer-events-auto": saveInfoChecked
                })}>
                <div>
                  <div className="relative">
                    <div className="relative">
                      <div className="group pointer-events-none absolute z-3 top-1/2 left-3 transform -translate-y-1/2">
                        <div className="cursor-pointer h-4 pointer-events-none relative w-4">
                          <img className="absolute left-0 top-0 z-3 w-4 h-4" src={`/images/flags/${saveInfoPhoneCountry}.svg`} alt={saveInfoPhoneCountry} />
                          <select disabled={saveInfoPhoneVerified} className="absolute left-0 top-0 h-0 w-0 z-2 py-2 pl-2 pr-3 pointer-events-auto appearance-none rounded-none border-none outline-none" onChange={(e) => onSaveInfoCountryCodeChanged(e)} value={saveInfoPhoneCountry}>
                            {countryCodes.map((grp, index) => (
                              <optgroup key={index} label={grp.group}>
                                {grp.countries.map((c) => (
                                  <option key={c.value} value={c.value}>{c.label}</option>
                                ))}
                              </optgroup> 
                            ))}
                          </select>
                          <svg className="text-input-svg absolute -bottom-px cursor-pointer h-2 w-2 -right-0.5 z-4 group-hover:text-input" focusable="false" fill="currentColor" viewBox="0 0 16 16"><path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm2.7928932-9.70710678-2.7928932 2.79289322-2.79289322-2.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l3.5 3.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l3.50000002-3.50000002c.3905243-.39052429.3905243-1.02368927 0-1.41421356s-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path></svg>
                        </div>
                      </div>
                      <span className="relative block m-0 p-0">
                        <input disabled={loading || success || saveInfoPhoneVerified} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 rounded-none rounded-b-md text-indent-6 shadow-input transitions-input  appearance-none lg:h-9 lg:text-sm lg:transform lg:rotate-0", {
                          'invalid': saveInfoPhoneError || saveInfoPhoneMissing,
                          'bg-white': !saveInfoPhoneVerified && !loading && !success,
                          'bg-readonly': loading || success || saveInfoPhoneVerified
                        })} autoComplete="tel" autoCorrect="off" spellCheck="false" id="phoneNumber" name="phoneNumber" type="tel" aria-invalid={saveInfoPhoneError || saveInfoPhoneMissing} placeholder={CountryUtils.getMobilePlaceholder(saveInfoPhoneCountry)} value={saveInfoPhone || ''} onChange={(e) => onSaveInfoPhoneChanged(e)} onBlur={(e) => onSaveInfoPhoneBlur(e)} />
                      </span>
                    </div>
                    <div className="pointer-events-none flex items-center absolute z-3 top-1/2 right-3 transform -translate-y-1/2">
                      {saveInfoPhoneMissing || saveInfoPhoneError ? (
                        <div className="pointer-events-auto ml-2">
                          <svg className="w-3 h-3 text-danger" focusable="false" viewBox="0 0 12 12" fill="none">
                            <path d="M6 12C9.28235 12 12 9.28235 12 6C12 2.72353 9.27647 0 5.99412 0C2.71765 0 0 2.72353 0 6C0 9.28235 2.72353 12 6 12ZM6 11C3.22353 11 1.00588 8.77647 1.00588 6C1.00588 3.22941 3.21765 1 5.99412 1C8.77059 1 10.9941 3.22941 11 6C11.0059 8.77647 8.77647 11 6 11ZM5.94706 3.90588C6.37647 3.90588 6.71177 3.56471 6.71177 3.14118C6.71177 2.71176 6.37647 2.37059 5.94706 2.37059C5.52353 2.37059 5.18235 2.71176 5.18235 3.14118C5.18235 3.56471 5.52353 3.90588 5.94706 3.90588ZM4.97059 9.23529H7.36471C7.60588 9.23529 7.79412 9.06471 7.79412 8.82353C7.79412 8.59412 7.60588 8.41177 7.36471 8.41177H6.63529V5.41765C6.63529 5.1 6.47647 4.88824 6.17647 4.88824H5.18235C4.94118 4.88824 4.75294 5.07059 4.75294 5.3C4.75294 5.54118 4.94118 5.71176 5.18235 5.71176H5.7V8.41177H4.97059C4.72941 8.41177 4.54118 8.59412 4.54118 8.82353C4.54118 9.06471 4.72941 9.23529 4.97059 9.23529Z" fill="currentColor"></path>
                          </svg>
                        </div>
                      ) : saveInfoPhoneLoading ? (
                        <div className="spinner-icon flex">
                          <svg className=" w-3.5 h-3.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false">
                            <ellipse className="origin-center animate-spinner-spin" cx="12" cy="12" rx="10" ry="10" style={{ stroke: 'rgb(26, 26, 26, 0.5)' }}></ellipse>
                          </svg>
                        </div>
                      ) : saveInfoPhoneVerified ? (
                        <div className="pointer-events-auto ml-2">
                          <button type="button" className="cursor-pointer text-xs p-0 text-magpie bg-transparent border-none outline-none rounded-none focus:outline-none leading-tight transition-colors duration-100 ease-out" onClick={onChangeSaveInfoPhone}>
                            <div className="flex flex-no-wrap justify-center items-center">
                              <span className="m-0 font-medium truncate">Change</span>
                            </div>
                          </button>
                        </div>
                      ) : isValidPhoneNumber(saveInfoPhone || '', saveInfoPhoneCountry) && !saveInfoPhoneVerified ? (
                        <div className="pointer-events-auto ml-2">
                          <button type="button" className="cursor-pointer text-xs p-0 text-magpie bg-transparent border-none outline-none rounded-none focus:outline-none leading-tight transition-colors duration-100 ease-out" onClick={onSaveInfoPhoneVerify}>
                            <div className="flex flex-no-wrap justify-center items-center">
                              <span className="m-0 font-medium truncate">Verify</span>
                            </div>
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <AnimateHeight
                    height={saveInfoPhoneError ? 'auto' : 0}
                    animateOpacity
                    className={cn("transition-height duration-300 ease", {
                      "pointer-events-none": !saveInfoPhoneError,
                      "pointer-events-auto": saveInfoPhoneError
                    })}>
                    <span className="m-0 text-13px text-danger transition-all duration-300 origin-top-left pointer-events-none">
                      <span>{saveInfoPhoneError}</span>
                    </span>
                  </AnimateHeight>
                </div>
                <div className="flex justify-center items-center mt-2">
                  <div tw-content-after="•" className="m-0 text-xs text-input-svg content-after after:my-0 after:mx-2 after:text-placeholder">Link with Magpie</div>
                  <button className="cursor-pointer p-0 text-xs text-blue-500 bg-transparent border-none outline-none rounded-none focus:outline-none transition-button" onClick={onShowLinkWithMagpieInfo}>
                    <div className="flex flex-no-wrap justify-center items-center">More info</div>
                  </button>
                </div>
              </AnimateHeight>
            </div>
          </div>
        </div>
      </AnimateHeight> */}

      {/* Update saved card info */}
      {/* savedPaymentInfo ? (
        <AnimateHeight
          height={!useSavedPaymentInfo ? 'auto' : 0}
          animateOpacity
          className="w-full transition-height duration-300 ease">
          <div className="flex flex-no-wrap flex-initial min-w-0 max-w-full w-full p-2">
            <div>
              <div className="flex items-baseline">
                <div className="mr-3 relative top-0.75">
                  <input id="updateCardCheckbox" name="updateCardCheckbox" type="checkbox" className="appearance-none bg-transparent border-none shadow-none h-4 opacity-0 outline-none absolute top-0 w-4 cursor-pointer" checked={updateSavedCardInfo} onChange={onUpdateSavedCardInfo}/>
                  <span className={cn("pointer-events-none bg-transparent rounded-sm shadow-input block h-4 min-w-4 w-4 transition-checkbox select-none", {
                    "before:empty-content before:checkbox-checked": updateSavedCardInfo,
                    "cursor-pointer": !loading &&  !success,
                    "before:empty-content before:checkbox-checked": saveInfoChecked,
                    "cursor-default pointer-events-none before:cursor-default before:pointer-events-none after:bg-white after:-bottom-px after:empty-content after:-left-px after:opacity-50 after:pointer-events-auto after:absolute after:-right-0.5 after:-top-0.5": loading || success
                  })}></span>
                </div>
                <label htmlFor="updateCardCheckbox">
                  <span className="m-0 text-13px font-medium text-modal-header select-none cursor-pointer">
                    Update saved payment info
                  </span>
                </label>
              </div>
            </div>
            <div data-tip={`This will securely add new card to your saved payment info.`} data-type="light" data-effect="solid" data-place="top" className="pointer-events-auto -mb-0.5 ml-2 flex items-center justify-center">
              <svg className="w-3 h-3 text-input-svg" focusable="false" viewBox="0 0 12 12" fill="none">
                <path d="M6 12C9.28235 12 12 9.28235 12 6C12 2.72353 9.27647 0 5.99412 0C2.71765 0 0 2.72353 0 6C0 9.28235 2.72353 12 6 12ZM6 11C3.22353 11 1.00588 8.77647 1.00588 6C1.00588 3.22941 3.21765 1 5.99412 1C8.77059 1 10.9941 3.22941 11 6C11.0059 8.77647 8.77647 11 6 11ZM5.94706 3.90588C6.37647 3.90588 6.71177 3.56471 6.71177 3.14118C6.71177 2.71176 6.37647 2.37059 5.94706 2.37059C5.52353 2.37059 5.18235 2.71176 5.18235 3.14118C5.18235 3.56471 5.52353 3.90588 5.94706 3.90588ZM4.97059 9.23529H7.36471C7.60588 9.23529 7.79412 9.06471 7.79412 8.82353C7.79412 8.59412 7.60588 8.41177 7.36471 8.41177H6.63529V5.41765C6.63529 5.1 6.47647 4.88824 6.17647 4.88824H5.18235C4.94118 4.88824 4.75294 5.07059 4.75294 5.3C4.75294 5.54118 4.94118 5.71176 5.18235 5.71176H5.7V8.41177H4.97059C4.72941 8.41177 4.54118 8.59412 4.54118 8.82353C4.54118 9.06471 4.72941 9.23529 4.97059 9.23529Z" fill="currentColor"></path>
              </svg>
            </div>
            {tooltipVisible && <ReactTooltip className="tooltip w-auto text-xs font-normal text-input proportional-nums whitespace-normal bg-white p-0" />}
          </div>
        </AnimateHeight>
      ) : null */}
      

      <div className="box-border flex-initial min-w-0 max-w-full flex-grow p-2 lg:mb-4">
        <button onClick={(e) => onPayClick(e)} className={cn("submit relative overflow-hidden h-11 w-full mt-3 p-0 text-white shadow-pay border-0 outline-none rounded-md cursor-pointer transitions-pay-button backface-visibility-hidden focus:outline-none", { 'active:shadow-pay-focus': (complete || !complete) && !loading && !success, 'hover:shadow-pay-hover active:transform active:scale-99 is-complete': complete && !loading && !success, 'bg-success success': success })} style={{ backgroundColor: `${success ? '' : ColorUtils.hex2rgba(buttonColor)}`, color: `${ColorUtils.isDark(buttonColor) ? ColorUtils.hex2rgba('#fff') : '#fff'}` }}>
          <div className={cn("shimmer absolute inset-y-0 left-0 h-full w-3/2 opacity-0 transform -translate-x-3/2 transition-opacity duration-200 ease", { 'opacity-100 animate-shimmer': complete && !loading && !success })} style={{ background: `linear-gradient(to right, ${ColorUtils.hex2rgba(buttonColor, 0)} 0%, ${ColorUtils.brighter(buttonColor, 50)} 50%, ${ColorUtils.hex2rgba(buttonColor, 0)} 100%)` }}></div>
          <div>
            <span className={cn("m-0 font-medium truncate absolute top-0 left-0 w-full text-center leading-11 transition-all duration-200 ease", { 'opacity-60': !complete && !loading && !success, 'opacity-0 transform -translate-x-4': loading || success })}>
              {
                mode === 'subscription'
                ? 'Subscribe' 
                  : mode === 'save_card'
                  ? 'Save card'
                  : `${CardUtils.getSubmitLabel(submit)} ${NumberUtils.formatCurrency(total/100, currency)}`
              }
            </span>
            <span className={cn("m-0 font-medium truncate absolute top-0 left-0 w-full text-center leading-11 transition-all duration-200 ease", { 'opacity-0 transform translate-x-4': (!complete || complete) && !loading && !success, 'opacity-0 transform -translate-x-4': success })}>Processing...</span>
          </div>
          <div className="icon-container absolute top-0 left-0 h-full w-full transform -translate-x-4 transition-transform duration-300 ease">
            <div className={cn("flex absolute top-1/2 right-0 transform -translate-y-1/2 opacity-0 transition-all duration-200 ease", { 'opacity-0 transform -translate-y-1/2 scale-90': !complete || loading || success, 'opacity-100': complete && !loading && !success })}>
              <div className="w-4 h-4">
                <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false">
                  <path d="M3 7V5a5 5 0 1 1 10 0v2h.5a1 1 0 0 1 1 1v6a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V8a1 1 0 0 1 1-1zm5 2.5a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1zM11 7V5a3 3 0 1 0-6 0v2z" fill="#ffffff" fillRule="evenodd"></path>
                </svg>
              </div>
            </div>
            <div className={cn("spinner-icon flex absolute top-1/2 right-0 transform -translate-y-1/2 opacity-0 transition-all duration-200", { 'opacity-0 transform -translate-y-1/2 scale-90': !complete || complete, 'opacity-100': complete && loading, 'opacity-100 transform -translate-y-1/2 scale-130 duration-300 post-center': success })}>
              <div className="w-5 h-5">
                <svg className="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false">
                  <ellipse className={cn("origin-center animate-spinner-spin", { 'animate-spinner-complete': success })} cx="12" cy="12" rx="10" ry="10" style={{ stroke: 'rgb(255, 255, 255)' }}></ellipse>
                </svg>
              </div>
            </div>
          </div>
          <div className={cn("checkmark-icon absolute top-1/2 left-1/2", { 'current': success })}>
            <div className="h-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" focusable="false">
                <path d="M 0.5 6 L 8 13.5 L 21.5 0" fill="transparent" strokeWidth="2" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </div>
          </div>
        </button>
        {
          mode === 'setup' ? (
            <div className="block mt-3">
              <div className="text-13px text-gray-500 leading-normal text-left proportional-nums whitespace-normal">
                {`By clicking on Pay, you allow ${merchant} to reserve the amount on your card for future payment in accordance with their terms.`}
              </div>
            </div>
          ) : null
        }
        {
          mode === 'subscription' ? (
            <div className="block mt-3">
              <div className="text-13px text-gray-500 leading-normal text-left proportional-nums whitespace-normal">
                {`By confirming your subscription, you allow ${merchant} to charge your card for future payments in accordance with their terms. Please contact the merchant if you wish to cancel.`}
              </div>
            </div>
          ) : null
        }
        {
          mode === 'save_card' ? (
            <div className="block mt-3">
              <div className="text-13px text-gray-500 leading-normal text-left proportional-nums whitespace-normal">
                {`By saving your payment information, you allow ${merchant} to charge your card for future payments in accordance with their terms.`}
              </div>
            </div>
          ) : null
        }
        <AnimateHeight
          height={success ? 'auto' : 0}
          animateOpacity
          className="mt-3 transition-height duration-300 ease">
          <div className="w-full rounded leading-4 text-center">
            <span className="text-xs text-gray-500 proportional-nums whitespace-normal text-center">
              Redirecting back to the merchant page please wait...
            </span>
          </div>
        </AnimateHeight>
        {/* <div className="mt-3"></div> */}
      </div>
    </div>
  )
})

export default CardInfo