import React, { useState, useEffect, useCallback } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import cn from 'classnames'
import moment from 'moment'

import Api from '../api/Api';
import DynamoDB from '../lib/DynamoDB'

import Header from '../components/Header'
import Summary from '../components/Summary'
import Items from '../components/Items'
import PaymentForm from '../components/PaymentForm'
import Footer from '../components/Footer'
import Loading from '../components/Loading'
import NotFound from '../components/NotFound'
import Blocked from '../components/Blocked'
import Expired from '../components/Expired'
import ItemsModal from '../components/ItemsModal'

import ColorUtils from '../utils/ColorUtils'
import ItemUtils from '../utils/ItemUtils'
import CardUtils from '../utils/CardUtils'


export const getServerSideProps = async ({ query }) => {
  let sessionData = null, allowedMethods = [], isBlocked = false;
  console.log('query', query);
  if (query.sessionId) {
    sessionData = await DynamoDB.get(query.sessionId);
    console.log('db', sessionData);
    const methodsRes = await Api.getMerchant(sessionData.secretKey)
    if (methodsRes.status === 200) {
      allowedMethods = methodsRes.data.payment_methods;
      isBlocked = false;
    } else {
      isBlocked = true;
    }
  }
  

  return {
    props: { sessionData, allowedMethods, isBlocked },
  };
}

const Home = ({ sessionData, allowedMethods, isBlocked }) => {
  const router = useRouter();
  const chargeId = router.query["charge_id"];
  const data = router.query["d"];
  const [origin, setOrigin] = useState(null);
  const [session, setSession] = useState(null);
  const [merchant, setMerchant] = useState({ name: '', icon: '', logo: '', use_logo: false });
  const [customer, setCustomer] = useState({id: null, name: null, email: null, phone: null});
  const [currency, setCurrency] = useState('php');
  const [colors, setColors] = useState({ primary: '#ffffff', accent: '#0074d4' });
  const [description, setDescription] = useState(null);
  const [bankCode, setBankCode] = useState(null);
  const [items, setItems] = useState([]);
  const [isBillingRequired, setBillingRequired] = useState(false);
  const [billingData, setBillingData] = useState(null);
  const [requestName, setRequestName] = useState(true);
  const [requestPhone, setRequestPhone] = useState(false);
  const [requestShipping, setRequestShipping] = useState(false);
  const [requireAuth, setRequireAuth] = useState(true);
  const [shippingCountries, setShippingCountries] = useState([]);
  const [shippingData, setShippingData] = useState(null);
  const [livemode, setLivemode] = useState(false);
  const [metadata, setMetadata] = useState({});
  const [mode, setMode] = useState('payment');
  const [submitType, setSubmitType] = useState(null)
  const [status, setStatus] = useState(null);
  const [successUrl, setSuccessUrl] = useState('');
  const [cancelUrl, setCancelUrl] = useState('');
  const [publicKey, setPublicKey] = useState(null);
  const [secretKey, setSecretKey] = useState(null);
  const [version, setVersion] = useState('1');
  const [hasItemWithImage, setHasItemWithImage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [quickPay, setQuickPay] = useState(null);
  const [quickPayMethod, setQuickPayMethod] = useState(null);
  const [banks, setBanks] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [country, setCountry] = useState('PH');

  /* if (data) {
    console.log('charge', chargeId);
    console.log('data', JSON.parse(atob(data)));
  } */
  
  //const [request, setRequest] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const getWalletPaymentMethods = (paymentMethods) => {
    let wallets = [];
    paymentMethods.forEach((method) => {
      if (CardUtils.isWallet(method)) wallets.push(method)
    })
    return wallets.sort((a, b) => a.localeCompare(b));
  }

  const getBankPaymentMethods = (paymentMethods) => {
    let banks = [];
    paymentMethods.forEach((method) => {
      if (CardUtils.isBank(method)) banks.push(method)
    })
    return banks.sort((a, b) => a.localeCompare(b));
  }

  const expireSession = async (sessionId) => {
    await DynamoDB.expireSession(sessionId);
    return true;
  }

  /* const checkMerchant = useCallback(
    async () => {
      if (sessionData) {
        if (sessionData.secretKey) {
          const res = await Api.getMerchant(sessionData.secretKey);
          setIsMerchantSuspended(res.status === 401);
          console.log('suspended', res.status === 401)
        }
      }
    },
    [sessionData],
  ) */
  

  useEffect(() => {
    if (sessionData) {
      setLoading(true);
      if (window) {
        if (window.location) {
          setOrigin(window.location.origin);
        }
      }

      // console.log({sessionData})
      const expiry = 60 * 2; // 2 hours
      const duration = moment.duration(moment().diff(moment(sessionData.createdAt)));

      if (sessionData.paymentStatus === 'unpaid' && duration.asMinutes() >= expiry) {
        console.log('session is expired.');
        expireSession(sessionData.id);
        setStatus('expired');
        setSession({
          id: sessionData.id || null,
          object: sessionData.object || null,
          amount_subtotal: sessionData.amountSubtotal || 0,
          amount_total: sessionData.amount || 0,
          bank_code: sessionData.bankCode || null,
          branding: {
            icon: sessionData.branding.icon || null,
            logo: sessionData.branding.logo || null,
            use_logo: sessionData.branding.useLogo || false,
            primary_color: sessionData.branding.primaryColor || '#fefefe',
            secondary_color: sessionData.branding.secondaryColor || '#0074d4'
          },
          billing: sessionData.billing || null,
          billing_address_collection: sessionData.billingAddressCollection || 'auto',
          cancel_url: sessionData.cancelUrl || null,
          client_reference_id: sessionData.clientReferenceId || null,
          created: sessionData.createdAt,
          currency: sessionData.currency || 'php',
          customer: sessionData.customer || null,
          customer_name: sessionData.customerName || null,
          customer_email: sessionData.customerEmail || null,
          customer_phone: sessionData.customerPhone || null,
          customer_name_collection: sessionData.customerNameCollection !== undefined ? sessionData.customerNameCollection : true,
          description: sessionData.description || null,
          line_items: sessionData.lineItems || [],
          livemode: sessionData.livemode || false,
          locale: sessionData.locale || null,
          merchant: sessionData.merchant || null,
          metadata: sessionData.metadata || null ,
          mode: sessionData.mode || 'payment',
          payment_details: sessionData.paymentDetails || null,
          payment_method_types: sessionData.paymentMethodTypes ? sessionData.paymentMethodTypes.filter(m => allowedMethods.includes(m)) : [],
          payment_status: 'expired',
          payment_url: sessionData.paymentUrl || null,
          phone_number_collection: sessionData.phoneNumberCollection !== undefined ? sessionData.phoneNumberCollection : false,
          public_key: sessionData.publicKey || null,
          require_auth: true, /* sessionData.requireAuth !== undefined ? sessionData.requireAuth : true, */
          secret_key: sessionData.secretKey || null,
          shipping: sessionData.shipping || null,
          shipping_address_collection: sessionData.shippingAddressCollection || null,
          submit_type: sessionData.submitType || 'pay',
          success_url: sessionData.successUrl || null,
          updated: sessionData.lastUpdated,
          version: sessionData.version || '1',
        });
      } else {
        setSession({
          id: sessionData.id || null,
          object: sessionData.object || null,
          amount_subtotal: sessionData.amountSubtotal || 0,
          amount_total: sessionData.amount || 0,
          bank_code: sessionData.bankCode || null,
          branding: {
            icon: sessionData.branding.icon || null,
            logo: sessionData.branding.logo || null,
            use_logo: sessionData.branding.useLogo || false,
            primary_color: sessionData.branding.primaryColor || '#fefefe',
            secondary_color: sessionData.branding.secondaryColor || '#0074d4'
          },
          billing: sessionData.billing || null,
          billing_address_collection: sessionData.billingAddressCollection || 'auto',
          cancel_url: sessionData.cancelUrl || null,
          client_reference_id: sessionData.clientReferenceId || null,
          created: sessionData.createdAt,
          currency: sessionData.currency || 'php',
          customer: sessionData.customer || null,
          customer_name: sessionData.customerName || null,
          customer_email: sessionData.customerEmail || null,
          customer_phone: sessionData.customerPhone || null,
          customer_name_collection: sessionData.customerNameCollection !== undefined ? sessionData.customerNameCollection : true,
          description: sessionData.description || null,
          line_items: sessionData.lineItems || [],
          livemode: sessionData.livemode || false,
          locale: sessionData.locale || null,
          merchant: sessionData.merchant || null,
          metadata: sessionData.metadata || null ,
          mode: sessionData.mode || 'payment',
          payment_details: sessionData.paymentDetails || null,
          payment_method_types: sessionData.paymentMethodTypes ? sessionData.paymentMethodTypes.filter(m => allowedMethods.includes(m)) : [],
          payment_status: sessionData.paymentStatus || 'unpaid',
          payment_url: sessionData.paymentUrl || null,
          phone_number_collection: sessionData.phoneNumberCollection !== undefined ? sessionData.phoneNumberCollection : false,
          public_key: sessionData.publicKey || null,
          require_auth: true, /* sessionData.requireAuth !== undefined ? sessionData.requireAuth : true, */
          secret_key: sessionData.secretKey || null,
          shipping: sessionData.shipping || null,
          shipping_address_collection: sessionData.shippingAddressCollection || null,
          submit_type: sessionData.submitType || 'pay',
          success_url: sessionData.successUrl || null,
          updated: sessionData.lastUpdated,
          version: sessionData.version || '1',
        });
      }
    } else {
      console.log('session not found');
      setSession(null);
      setLoading(false);
    }
  }, [sessionData])

  const getCustomer = useCallback(
    async (key, version, customerId) => {
      if (key && customerId) {
        try {
          const res = await Api.getCustomer(key, version, customerId);
          if (res.status === 200) {
            setCustomer({
              ...customer,
              id: res.data.id,
              name: session.customer_name ? session.customer_name : version === '2' ? res.metadata ? res.metadata.name ? res.metadata.name : session.customer_name : session.customer_name : res.data.description ? res.data.description : session.customer_name,
              email: res.data.email,
              phone: session.customer_phone ? session.customer_phone : res.mobile_number ? res.mobile_number : null,
            });
          }
        } catch (e) {
          console.log('err', e);
        }
      }
    },
    [session],
  )

  useEffect(() => {
    let timer, timer2;
    if (session) {
      // console.log({session})
      if (session.customer) {
        getCustomer(session.secret_key, session.version, session.customer);
      } else if (session.customer_email || session.customer_name || session.customer_phone) {
        setCustomer({
          ...customer,
          id: session.customer,
          email: session.customer_email,
          name: session.customer_name,
          phone: session.customer_phone,
        });
      }

      setStatus(session.payment_status);
      setMerchant({
        ...merchant,
        name: session.merchant.name,
        icon: session.branding.icon,
        logo: session.branding.logo,
        use_logo: session.branding.use_logo || false,
      });
      setColors({
        primary: session.branding.primary_color || '#FEFEFE',
        accent: session.branding.secondary_color || '#0074d4'
      });
      setBankCode(session.bank_code || null);
      setCurrency(session.currency);
      setDescription(session.description);
      setItems(session.line_items);
      const hasImage = session.line_items.findIndex((item) => !!item.image);
      setHasItemWithImage(hasImage === -1 ? false : true);
      setPaymentMethods(session.payment_method_types);
      if (session.payment_method_types.length > 1) {
        /* if (getBankPaymentMethods(session.payment_method_types).length === 1 &&
          (session.payment_method_types.includes("card") || 
          getWalletPaymentMethods(session.payment_method_types).length > 1)) {
          setQuickPay('bank');
          setQuickPayMethod(getBankPaymentMethods(session.payment_method_types)[0]);
        } else if (getWalletPaymentMethods(session.payment_method_types).length === 1) {
          setQuickPay('wallet');
          setQuickPayMethod(getWalletPaymentMethods(session.payment_method_types)[0]);
        } */
        if (getWalletPaymentMethods(session.payment_method_types).length === 1) {
          setQuickPay('wallet');
          setQuickPayMethod(getWalletPaymentMethods(session.payment_method_types)[0]);
        }
      }
      setBanks(getBankPaymentMethods(session.payment_method_types));
      setWallets(getWalletPaymentMethods(session.payment_method_types));
      setBillingRequired(session.billing_address_collection === 'required');
      setBillingData(session.billing);
      // console.log('customer_name_collection', session.customer_name_collection)
      setRequestName(session.customer_name_collection);
      setRequestPhone(session.phone_number_collection || false);
      setRequestShipping(session.shipping_address_collection ? true : false);
      setRequireAuth(session.require_auth);
      setShippingCountries(session.shipping_address_collection ? session.shipping_address_collection.allowed_countries : []);
      setShippingData(session.shipping);
      setSubmitType(session.submit_type);
      setPublicKey(session.public_key);
      setSecretKey(session.secret_key);
      setVersion(session.version);
      setSuccessUrl(session.success_url);
      setCancelUrl(session.cancel_url);
      setLivemode(session.livemode);
      setMetadata(session.metadata);
      setMode(session.mode || 'payment');
      
      
      timer = setTimeout(() => {
        setLoading(false)
        setIsLoaded(true);
      }, 500);
      /* timer = setTimeout(() => {
        setLoading(false)
        timer2 = setTimeout(() => {
          setIsLoaded(true);
        }, 200);
      }, 500); */
    }


    return (() => {
      /* if  (timer2 !== null) {
        clearTimeout(timer2);
      } */
      if (timer !== null) {
        clearTimeout(timer);
      }
    });
  }, [session])

  useEffect(() => {
    if (data) {
      // const d = JSON.parse(decodeURIComponent(escape(atob(data))));
      // console.log({data});
      const d = JSON.parse(decodeURIComponent(Buffer.from(data, 'base64').toString('utf8')));
      // console.log('data', d);
      if (d.is_quick_pay) {
        setQuickPay(d.payment_method);
        setQuickPayMethod(d.payment_type);

        /* setPaymentMethod(d.payment_method);
        if (d.payment_method === 'wallet') {
          setSelectedWallet(d.payment_type);
        } else if (d.payment_method === 'bank') {
          setSelectedBank(d.payment_type)
        } */
      } else {
        setPaymentMethod(d.payment_method);
        if (d.payment_method === 'wallet') {
          setSelectedWallet(d.payment_type);
        } else if (d.payment_method === 'bank') {
          setSelectedBank(d.payment_type)
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (session && successUrl && status === 'paid') {
      console.log('Session paid. Redirecting to merchant page...');
      window.location.href = `${successUrl}${successUrl.includes('?') ? '&' : '?'}session_id=${session.id}`;
    }
  }, [status, session, successUrl]);

  const toggleModalHandler = () => setModalVisible(!modalVisible);

  const closeModalHandler = () => setModalVisible(false);

  const successPaymentHandler = async (sessionId) => {
    try {
      console.log('success', sessionId)
      window.location.href = `${successUrl}${successUrl.includes('?') ? '&' : '?'}session_id=${sessionId}`;
    } catch (err) {
      console.log(err.message);
    }
  }

  const cancelPaymentHandler = async (e) => {
    e.preventDefault();
    if (session) {
      try {
        const response = await Api.cancelSession(session.secret_key, session.version, session.id);

        if (response.status === 200) {
          console.log('expired', response.data)
        } else {
          console.log('err', response.data)
        }

        window.location.href = `${cancelUrl}${cancelUrl.includes('?') ? '&' : '?'}session_id=${session.id}`;
      } catch (e) {
        console.log(e.message);
      }
    }
  }

  return (
    <div className="antialiased">
      <Head>
        <title>{ merchant.name || 'Magpie Checkout' }</title>
        {/* <link rel="stylesheet" href="https://rsms.me/inter/inter.css" /> */}
        <link rel="preload" href="https://use.typekit.net/hxw2jwi.css" as="style" />
        <link rel="preload" href="/css/animations.css" as="style" />
        <link rel="preload" href="/css/custom.css" as="style" />
        {/* <link rel="stylesheet" href={`/css/custom.css?t=${new Date().getTime()}`} /> */}
        <link rel="stylesheet" href="https://use.typekit.net/hxw2jwi.css" />
        <link rel="stylesheet" href="/css/animations.css" />
        <link rel="stylesheet" href="/css/custom.css" />
        <link rel="icon" href={ merchant.icon || '/favicon.ico'} />
      </Head>

      {
        loading 
        ? <Loading /> 
        : session
          ? isBlocked ? <Blocked />
          : status === 'paid' ? <Loading />
          : status === 'unpaid'
            ? (
              <div className={cn("app-container box-border flex flex-no-wrap min-h-screen justify-center lg:min-h-0", {
                "app-container--setupMode": mode === 'save_card',
              })}>
              {
                  ColorUtils.isNotWhite(colors.primary) &&
                  <div className="fixed inset-0 -z-1" style={{ backgroundColor: `${ColorUtils.hex2rgba(colors.primary)}` }}></div>
              }
                <div className={cn("app w-full flex flex-col items-center mt-0 relative animate-enter-no-scale lg:items-start lg:transform lg:translate-y-multistep ", {
                  'pt-4 lg:pt-0 lg:flex-row lg:justify-between lg:animate-enter lg:my-12 max-w-lg': mode !== 'save_card',
                  'single-item': !ItemUtils.isMultiItem(items),
                  'multi-item': ItemUtils.isMultiItem(items),
                  'lg:max-w-111': mode === 'save_card',
                  })}>
                  <div
                    className={cn("overview w-full pt-0 px-4 pb-4 lg:m-0 lg:p-0", {
                      'my-0 mx-auto lg:max-w-95 lg:pb-10 lg:w-95 lg:mb-0': mode !== 'save_card',
                      'is-darkBackground': ColorUtils.isDark(colors.primary) && !modalVisible,
                    })}
                    style={{ backgroundColor: `${ColorUtils.hex2rgba(colors.primary)}` }}>
                    <Header
                      merchant={merchant}
                      colors={colors}
                      livemode={livemode}
                      mode={mode}
                      multiItems={ItemUtils.isMultiItem(items)}
                      modalVisible={modalVisible}
                      onCancel={cancelPaymentHandler}
                      onToggleModal={() => toggleModalHandler()}
                    />
                    {mode !== 'save_card' && (
                      <div>
                        <Summary merchant={merchant} items={items} currency={currency} description={description} />
                        {
                          /* Display if there are more than 1 item */
                          ItemUtils.isMultiItem(items) &&
                          <Items items={items} hasItemWithImage={hasItemWithImage} currency={currency} />
                        }
                      </div>
                    )}
                    
                  </div>
                  <PaymentForm
                    allowedCountries={shippingCountries}
                    banks={banks}
                    bankCode={bankCode}
                    billing={isBillingRequired}
                    billingAddress={billingData}
                    buttonColor={colors.accent}
                    charge={chargeId}
                    currency={currency}
                    defaultCountry={country}
                    description={description ? description : ItemUtils.asDescription(items)}
                    eWallets={wallets}
                    livemode={livemode}
                    loaded={isLoaded}
                    merchant={merchant.name}
                    metadata={metadata}
                    mode={mode}
                    onSuccessPayment={successPaymentHandler}
                    origin={origin}
                    paymentMethods={paymentMethods}
                    publicKey={publicKey}
                    quickPay={quickPay}
                    quickPayMethod={quickPayMethod}
                    requestName={requestName}
                    requestPhone={requestPhone}
                    requireAuth={requireAuth}
                    secretKey={secretKey}
                    selectedBank={selectedBank}
                    selectedMethod={paymentMethod}
                    selectedWallet={selectedWallet}
                    session={session}
                    sessionCustomer={customer}
                    shipping={requestShipping}
                    shippingAddress={shippingData}
                    submitType={submitType}
                    total={ItemUtils.getTotal(items)}
                    version={version}
                  />
                  <Footer colors={colors} mode={mode} />
                </div>
                <ItemsModal
                  modalVisible={modalVisible}
                  closeModalHandler={closeModalHandler}
                  items={items}
                  currency={currency}
                  hasItemWithImage={hasItemWithImage}
                />
              </div>
            ) : <Expired />
          : <NotFound/>
      }
    </div>
  )
}

export default Home;