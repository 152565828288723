import NumberUtils from '../utils/NumberUtils';
import ItemUtils from '../utils/ItemUtils';

const Summary = ({ merchant, items, currency, description }) => {

  return (
    <div className="summary items-center text-center flex flex-col justify-center lg:text-left lg:justify-start lg:items-start mt-8">
      {
        items.length > 0 && 
        ( !!items[0].image &&
          <div className="product-image flex-shrink-0 flex items-center mx-8 mb-4">
            <div className="flex items-center relative text-center h-30">
              <img className="max-w-full max-h-30 rounded-md" src={items[0].image} alt="Product" />
              {
                ItemUtils.getItemCount(items) > 1 && 
                <span className="absolute inset-x-0 mx-auto w-23 -bottom-10px shadow bg-white text-center rounded-full py-0.5 px-2 text-sm font-bold sm:hidden">{`${ItemUtils.getItemCount(items)} items`}</span>
              }
            </div>
          </div>
        )
      }
      <div className="product-info flex flex-col justify-center">
        <span className="m-0 text-base font-medium text-gray-800">
          {/*  !!merchant ? (ItemUtils.isMultiItem(items) ? `Pay ${merchant.name}` : items.length > 0 ? items[0].name : '') : '' */}
          { ItemUtils.isMultiItem(items) ? '' : description ? description : items[0].name}
        </span>
        <span className="product-total m-0 font-semibold leading-tight tabular-nums tracking-numbers">{ItemUtils.getFormattedTotal(items, currency)}</span>
        {ItemUtils.isMultiItem(items) ? description ? (
          <span className="m-0 text-sm font-medium text-gray-500">
            {description}
          </span>
        ) : null : description ? (
          <span className="m-0 text-sm font-medium text-gray-500">
            {ItemUtils.isMultiItem(items) ? '' : items[0].name}
          </span>
        ) : null}
        <span className="m-0 text-sm font-medium text-gray-500">
          {
            !ItemUtils.isMultiItem(items) &&
            ( 
              items.length > 0 && 
              <div className="">
                { !!items[0].description ? `${items[0].description}` : '' }
                { !!items[0].description && items[0].quantity > 1 ? `, ` : '' }
                { items[0].quantity > 1 ? `Qty ${items[0].quantity}, ${
                  NumberUtils.formatCurrency(items[0].amount/100, currency)} each` : ''}
              </div>
            )
          }
        </span>
      </div>
    </div>
  );
}

export default Summary;