import AWS from 'aws-sdk';
import * as Sentry from '@sentry/nextjs';


const secrets = new AWS.SecretsManager({
  region: process.env.NEXT_PUBLIC_AWS_REGION,
});

const timeout = (delay) => {
  return new Promise((resolve) => setTimeout(resolve, delay))
}

const getSecret = async () => {
  const secretId = process.env.NEXT_PUBLIC_MAGPIE_CHECKOUT_SECRET_NAME;
  const params = {
    SecretId: secretId
  };

  for (let retries = 0; retries < 3; ++retries) {
    const result = await secrets.getSecretValue(params).promise();
    const { SecretString } = result;
    // console.log({SecretString})
    if (SecretString) {
      // All good
      return JSON.parse(SecretString.toString());
    } else {
      // Wait for 5 seconds and try again
      await timeout(5000);
    }
  }

  // Out of retries
  Sentry.captureMessage('Failed to retrieve secrets after 3 retries');
  return null;
  
  /* try {
    const params = {
      SecretId: process.env.NEXT_PUBLIC_MAGPIE_CHECKOUT_SECRET_NAME
    }
    const result = await secrets.getSecretValue(params).promise();
    const { SecretString } = result;
    if (SecretString) {
      console.log('secrets', JSON.parse(SecretString.toString()))
      return JSON.parse(SecretString.toString());
    } else {
      Sentry.captureMessage('Failed to retreive secrets values');
      return null
    }
  } catch (error) {
    console.log(error)
    return null;
  } */
}

export default { getSecret };