import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';

const LogoutConfirmModal = ({
  isOpen,
  customer,
  onClose,
  onLogout,
}) => {
  Modal.setAppElement('body');
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      portalClassName="modal-portal"
      overlayClassName={{ base: "modal-overlay logout-modal-overlay", afterOpen: "modal-overlay--after-open", beforeClose: "modal-overlay--before-close" }}
      className={{ base: "modal-content logout-modal", afterOpen: "modal-content--after-open", beforeClose: "modal-content--before-close" }}
      bodyOpenClassName="body--modal-open"
    >
      <div className="mb-2">
        <span className="m-0 text-base font-semibold text-gray-900 leading-6">
          Log out?
        </span>
      </div>
      <div className="mb-4 text-center">
        <span className="m-0 text-sm font-normal text-modal-header leading-4.5">
          {`This will log out `}
          <span className="m-0 font-semibold text-gray-900 break-words">{`"${customer.email}"`}</span>
          {` and remove your saved payment information. You can always log back in by using the same email.`}
        </span>
      </div>
      <div className="w-full mt-2 flex flex-row items-center justify-between">
        <button className="mr-2 border border-solid border-transparent rounded-md cursor-pointer text-sm text-gray-900 p-3 bg-secondary w-full transition-button hover:bg-secondary-hover focus:outline-none focus:shadow-secondary-focus active:bg-secondary-active active:transform active:scale-98" onClick={onClose}>
          <div className="flex flex-no-wrap justify-center items-center">
            <span className="font-medium text-blue-500 truncate">Close</span>
          </div>
        </button>

        <button className="mr-2 border border-solid border-transparent rounded-md cursor-pointer text-sm text-gray-900 p-3 bg-secondary w-full transition-button hover:bg-secondary-hover focus:outline-none focus:shadow-secondary-focus active:bg-secondary-active active:transform active:scale-98" onClick={onLogout}>
          <div className="flex flex-no-wrap justify-center items-center">
            <span className="font-medium text-red-500 truncate">Log out</span>
          </div>
        </button>
      </div>
    </Modal>
  )
}

export default LogoutConfirmModal;