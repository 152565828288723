import React from 'react';
import cn from 'classnames';

import NumberUtils from '../utils/NumberUtils';

const Items = ({ items, hasItemWithImage, currency }) => {

  return (
    <section className="details hidden max-h-screen-1/2 overflow-y-auto pr-2 lg:block lg:-ml-3 lg:pl-3 my-8">
      <ul className="items list-none p-0 m-0">
        {
          items.map((item, index) =>
            <li key={index} className="mb-4">
              <div className={cn("box-border flex flex-no-wrap items-start", { 'indented' : hasItemWithImage && !item.image})}>
                {
                  !!item.image &&
                  <div className="item-image mr-4 box-border flex-initial max-w-full flex-basis-auto flex-shrink-0 w-42px h-42px flex justify-center items-center">
                    <img className="max-w-full max-h-full rounded" src={item.image} alt='' />
                  </div>
                }
                <div className="flex-initial min-w-0 max-w-full flex-basis-0 flex-grow">
                  <div className="box-border flex flex-wrap justify-between">
                    <div className="flex-basis-full w-0" style={{ order: '2' }}></div>
                    <div className="mb-0.5 box-border flex-initial min-w-0 max-w-full w-auto flex-basis-auto" style={{ order: '0' }}>
                      <span className="m-0 text-sm text-black font-medium">{item.name || ''}</span>
                    </div>
                    <div className="box-border flex-initial min-w-0 max-w-full mr-auto text-left flex-basis-auto w-auto" style={{ order: '4' }}>
                      <span className="m-0 text-xs font-normal text-gray-400">{item.description || ''}</span>
                    </div>
                    <div className="box-border flex-initial min-w-0 max-w-full ml-auto text-right flex-basis-auto w-auto" style={{ order: '5' }}>
                      {
                        item.quantity > 1 &&
                        <span className="m-0 text-xs font-normal text-gray-400">
                          {`${NumberUtils.formatCurrency(item.amount/100, currency)} each`}
                        </span>
                      }
                    </div>
                    <div className="box-border flex-initial min-w-0 max-w-full flex-basis-auto w-auto" style={{ order: '3' }}>
                      {
                        item.quantity > 1 &&
                        <span className="m-0 text-xs font-normal text-gray-400">
                          {`Qty ${item.quantity}`}
                          {
                            !!item.description && 
                            <span className="mr-1">, </span>
                          }
                        </span>
                      }
                    </div>
                    <div className="box-border flex-initial min-w-0 max-w-full ml-auto text-right flex-basis-auto w-auto" style={{ order: '1' }}>
                      <span className="m-0 text-sm text-black font-medium tabular-nums tracking-numbers">
                        {
                          NumberUtils.formatCurrency((item.amount * item.quantity)/100, currency)
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          )
        }
      </ul>
    </section>
  );
}

export default Items;