import React, {useEffect, useState, useRef} from 'react'

const SmsCodeInput = ({ code, disabled, onCodeSubmit }) => {
  const numberOnly = /[0-9]/;
  const input0 = useRef(null);
  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);
  const input4 = useRef(null);
  const input5 = useRef(null);

  const [inputs, setInputs] = useState([]);
  const [current, setCurrent] = useState(0);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    const arr = [];
    arr.push(input0);
    arr.push(input1);
    arr.push(input2);
    arr.push(input3);
    arr.push(input4);
    arr.push(input5);
    setInputs(arr);
  }, []);

  useEffect(() => {
    if (code !== undefined) {
      if (code === '') {
        setOtp(['', '', '', '', '', '']);
        setCurrent(0);
      }
    }
  }, [code])

  useEffect(() => {
    setDisable(disabled);
  }, [disabled])

  useEffect(() => {
    console.log('otp', otp);
    const len = otp.filter((i) => i).length;
    console.log('length', len);
    if (len === 6) {
      console.log('otp complete');
      setDisable(true);
      onCodeSubmit(otp.join(''));
    } else {
      setCurrent(len);
    }
  }, [otp]);

  useEffect(() => {
    if (inputs.length > 0) {
      if (inputs[current].current !== null) {
        inputs[current].current.focus();
      }
    }
  }, [inputs, current])

  const onWrapperClicked = (e) => {
    e.preventDefault();
    console.log('inputs clicked');
    if (inputs[current].current !== null) {
      inputs[current].current.focus();
    }
  }

  const onOtpChange = (e) => {
    if (numberOnly.test(e.target.value)) {
      const arr = [...otp];
      arr[current] = e.target.value;
      setOtp(arr);
    }
  }

  const onKeyUp = (e) => {
    console.log('evt', e.key);
    if (e.key === 'Delete' || e.key === "Backspace") {
      const next = current - 1;
      if (next > -1) {
        if (inputs[next].current !== null) {
          otp[current] = '';
          otp[next] = '';
          setOtp([...otp]);
        }
      }
    }
  }

  return (
    <div className="flex max-w-57.5">
      <div className="flex flex-initial" onClick={onWrapperClicked}>
        <input ref={input0} disabled={disable} id="one-time-code" name="one-time-code" type="text" inputMode="numeric" pattern="[0-9]" tabIndex={0} autoComplete="off" maxLength={1} className="w-full flex-initial min-w-0 max-w-full appearance-none text-center text-input text-xl leading-normal border-0 rounded-none shadow-input transition-checkbox rounded-l-md focus:outline-none focus:z-2 focus:shadow-secondary-focus disabled:text-placeholder" value={otp[0]} onChange={onOtpChange} onKeyUp={onKeyUp}/>
        <input ref={input1} disabled={disable} type="text" inputMode="numeric" pattern="[0-9]*" tabIndex={-1} autoComplete="off" maxLength={1} className="w-full flex-initial min-w-0 max-w-full appearance-none text-center text-input text-xl leading-normal border-0 rounded-none shadow-input transition-checkbox focus:outline-none focus:z-2 focus:shadow-secondary-focus disabled:text-placeholder" value={otp[1]} onChange={onOtpChange} onKeyUp={onKeyUp}/>
        <input ref={input2} disabled={disable} type="text" inputMode="numeric" pattern="[0-9]*" tabIndex={-1} autoComplete="off" maxLength={1} className="w-full flex-initial min-w-0 max-w-full appearance-none text-center text-input text-xl leading-normal border-0 rounded-none shadow-input transition-checkbox rounded-r-md focus:outline-none focus:z-2 focus:shadow-secondary-focus disabled:text-placeholder" value={otp[2]} onChange={onOtpChange} onKeyUp={onKeyUp}/>
      </div>
      <div className="bg-sms-separator h-px w-2 p-0 flex-shrink-0 my-0 mx-2 self-center"></div>
      <div className="flex flex-initial" onClick={onWrapperClicked}>
        <input ref={input3} disabled={disable} type="text" inputMode="numeric" pattern="[0-9]*" tabIndex={-1} autoComplete="off" maxLength={1} className="w-full flex-initial min-w-0 max-w-full appearance-none text-center text-input text-xl leading-normal border-0 rounded-none shadow-input transition-checkbox rounded-l-md focus:outline-none focus:z-2 focus:shadow-secondary-focus disabled:text-placeholder" value={otp[3]} onChange={onOtpChange} onKeyUp={onKeyUp}/>
        <input ref={input4} disabled={disable} type="text" inputMode="numeric" pattern="[0-9]*" tabIndex={-1} autoComplete="off" maxLength={1} className="w-full flex-initial min-w-0 max-w-full appearance-none text-center text-input text-xl leading-normal border-0 rounded-none shadow-input transition-checkbox focus:outline-none focus:z-2 focus:shadow-secondary-focus disabled:text-placeholder" value={otp[4]} onChange={onOtpChange} onKeyUp={onKeyUp}/>
        <input ref={input5} disabled={disable} maxLength={1} type="text" inputMode="numeric" pattern="[0-9]*" tabIndex={-1} autoComplete="off" className="w-full flex-initial min-w-0 max-w-full appearance-none text-center text-input text-xl leading-normal border-0 rounded-none shadow-input transition-checkbox rounded-r-md focus:outline-none focus:z-2 focus:shadow-secondary-focus disabled:text-placeholder" value={otp[5]} onChange={onOtpChange} onKeyUp={onKeyUp}/>
      </div>
    </div>
  );
}

export default SmsCodeInput;