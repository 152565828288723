import React from 'react'
import Modal from 'react-modal'
import cn from 'classnames'

const BankModal = React.forwardRef(({
  isOpen,
  onClose,
  color,
  showNav,
  bankNav,
  bankFrameLoading,
  bankFrameUrl,
  onFrameLoad,
}, ref) => {
  Modal.setAppElement('body');
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      portalClassName="modal-portal"
      overlayClassName={{ base: "modal-overlay bank-modal-overlay", afterOpen: "modal-overlay--after-open", beforeClose: "modal-overlay--before-close" }}
      className={{ base: "modal-content bank-modal", afterOpen: "modal-content--after-open", beforeClose: "modal-content--before-close" }}
      bodyOpenClassName="body--modal-open"
    >
      <div className="absolute h-full w-full top-0 left-0">
        <div onClick={() => onClose()} className="modal-header h-10 w-full flex items-center justify-end">
          <button className="bg-transparent border-0 text-white font-semibold py-2 px-1 -mr-1 text-13px cursor-pointer uppercase tracking-wide outline-none focus:outline-none transition-opacity duration-150 ease">Cancel</button>
        </div>
        <div className="modal-body w-full bg-white overflow-hidden rounded relative">
          <div className={cn("absolute top-0 left-0 right-0 h-full w-full flex items-center justify-center transition-opacity duration-700 ease-in-out", { 'opacity-100': bankFrameLoading, 'opacity-0 hidden': !bankFrameLoading })}>
            <svg className="animate-spin -ml-1 mr-3 h-10 w-10" style={{ color: `${color}` }} viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
              <defs> <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a"> <stop stopColor="currentColor" stopOpacity="0" offset="0%"/> <stop stopColor="currentColor" stopOpacity=".631" offset="63.146%"/> <stop stopColor="currentColor" offset="100%"/> </linearGradient> </defs>
              <g fill="none" fillRule="evenodd"> <g transform="translate(1 1)"> <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" strokeWidth="2"> <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"/> </path> <circle fill="currentColor" cx="36" cy="18" r="1"> <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"/> </circle> </g> </g>
            </svg>
          </div>
          {/* <div className={cn("mt-4 mx-4 mb-4 py-1.5 px-3 bg-gray-100 rounded transition-all duration-300 ease-in-out", {
            "transform -translate-y-3/2 opacity-0": !showNav,
            "transform translate-y-0 opacity-100": showNav
          })}>
            <input className="w-full text-xs text-gray-700 truncate" disabled value={bankNav ? bankNav : bankFrameUrl} />
          </div> */}
          <iframe
            ref={ref}
            src={bankFrameUrl}
            width="100%"
            height="100%"
            onLoad={onFrameLoad}
            className={cn("transition-opacity duration-300 ease-in", { 'opacity-0': bankFrameLoading, 'opacity-100': !bankFrameLoading })}
          />
        </div>
      </div>
    </Modal>
  )
})

export default BankModal;