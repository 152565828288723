import NumberUtils from './NumberUtils';

const isMultiItem = (items) => {
  if (!items) return false;
  return items.length > 1;
}

const asDescription = (items) => {
  let description = '';
  items.forEach((i, index) => {
    // description = description.concat(`${i.quantity} x ${i.name}${i.description ? ` ${i.description}` : ''}`);
    description = description.concat(`${i.name}${i.description ? ` ${i.description}` : ''}`);
    //description = description.concat(i.name);
    if (index < (items.length - 1)) {
      description = description.concat(', ');
    }
  })
  return description;
}

const getTotal = (items) => {
  if (!items) return 0;
  let total = 0;
  if (items.length > 0) {
    items.forEach((item) => {
      total = total + (item.amount * item.quantity);
    });
  }
  return total;
}

const getFormattedTotal = (items, currency = 'php') => {
  let total = 0;
  if (items.length > 0) {
    items.forEach((item) => {
      total = total + (item.amount * item.quantity);
    });
  }
  return NumberUtils.formatCurrency(total/100, currency)
}

const getItemCount = (items) => {
  let count = 0;
  if (items.length > 0) {
    items.forEach((item) => {
      count = count + item.quantity;
    });
  }
  return count;
}

const isValidImageURL = async (url) => {
  /* var res = url.match(/(https:)|([/|.|\w|\s])*\.(?:jpg|jpeg|svg|png)/g);
  return (res !== null) */
  try {
    if (!url.startsWith('https://')) return false;
    const res = await fetch(url, { method: 'GET' });
    if (res.ok) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

const checkIfItemsAreValid = (items) => {
  let isValid = true;
  items.forEach(async (item) => {
    if (!item.hasOwnProperty('name') || 
      !item.hasOwnProperty('quantity') ||
      !item.hasOwnProperty('amount')) {
      isValid = false;
    }
  });
  return isValid;
}

const checkIfItemAmountsAreValid = (items) => {
  let isValid = true;
  items.forEach((item) => {
    if (!Number.isInteger(item.amount)) {
      isValid = false;
    } else {
      if (item.amount < 0) {
        isValid = false;
      }
    }
  });
  return isValid;
}

const checkIfItemQuantitiesAreValid = (items) => {
  let isValid = true;
  items.forEach((item) => {
    if (typeof item.quantity !== 'number') {
      isValid = false;
    } else {
      if (item.quantity <= 0) {
        isValid = false;
      }
    }
  });
  return isValid;
}

const checkIfItemImagesAreValid = async (items) => {
  let isValid = true;
  await items.forEach(async (item) => {
    if (!!item.image) {
      const validImage = await isValidImageURL(item.image)
      if (!validImage) isValid = false;
    }
  });
  return isValid;
}

export default {
  isMultiItem,
  asDescription,
  getTotal,
  getFormattedTotal,
  getItemCount,
  isValidImageURL,
  checkIfItemsAreValid,
  checkIfItemAmountsAreValid,
  checkIfItemQuantitiesAreValid,
  checkIfItemImagesAreValid,
};