const NotFound = () => {
  const onBackClicked = (e) => {
    e.preventDefault();
    window.history.back();
  }

  return (
    <div className="items-center absolute inset-0 flex justify-center">
      <div>
        <div className="flex justify-center flex-col text-center max-w-250-px">
          <h2 className="mb-5 text-base font-medium text-gray-600">Let's get you to the right place</h2>
          <p className="mb-2 text-xs leading-normal font-normal text-gray-500 text-center">Trying to submit a payment? Check with the merchant for the correct payment link.</p>
          <p className="m-0 text-sm font-normal text-gray-400">
            <a className="bg-transparent no-underline cursor-pointer text-blue-500" target="_self" onClick={(e) => onBackClicked(e)}>Back</a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default NotFound;