import tinycolor from 'tinycolor2';

const isValid = (hexColor) => {
  return tinycolor(hexColor).isValid();
}

const isDark = (color) => {
  return tinycolor(color).isDark();
}

const isNotWhite = (color) => {
  return color.toLowerCase() !== '#fff' && color.toLowerCase() !== '#ffffff' && color.toLowerCase() !== '#ffff';
}

const hex2rgba = (hex, alpha = 100) => {
  /* const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`; */
  return tinycolor(hex).setAlpha(alpha).toRgbString();
};

const brighter = (hex, value = 50) => {
  return tinycolor(hex).brighten(value).toRgbString();
};

const darker = (hex, value = 50) => {
  return tinycolor(hex).darken(value).toRgbString();
};



export default {
  isValid,
  isDark,
  isNotWhite,
  hex2rgba,
  brighter,
  darker,
}