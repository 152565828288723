import moment from 'moment';
import luhn from 'luhn';
/* import Image from 'next/image'

import IconBDO from '../assets/images/bdo.svg';
import IconBPI from '../assets/images/bpi2.svg';
import IconMetrobank from '../assets/images/metrobank.svg';
import IconPNB from '../assets/images/pnb.svg';
import IconRCBC from '../assets/images/rcbc.svg';
import IconUnionbank from '../assets/images/unionbank.svg'; */

const visa_pattern = /^4/;
const mastercard_pattern = /^(?:5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01][0-9]|2720)/;
const jcb_pattern = /^(?:2131|1800|35)/;

const getBrand = (cardNumber) => {
  if (cardNumber === '') return '';

  if (visa_pattern.test(cardNumber)) {
    return 'visa';
  } else if (mastercard_pattern.test(cardNumber)) {
    return 'mastercard';
  } else if (jcb_pattern.test(cardNumber)) {
    return 'jcb';
  } else {
    return '';
  }
}

const getCardIcon = (brand) => {
  if (brand.toLowerCase() === 'visa') return '/images/visa.svg'
  else if (brand.toLowerCase() === 'mastercard') return '/images/mastercard.svg'
  else if (brand.toLowerCase() === 'jcb') return '/images/jcb.svg'
  else return '/images/icon_cards.png'
}

const getBrandIcon = (cardNumber) => {
  const brand = getBrand(cardNumber.replace(/\s+/g, ''));
  if (brand.toLowerCase() === 'visa') return '/images/visa.svg'
  else if (brand.toLowerCase() === 'mastercard') return '/images/mastercard.svg'
  else if (brand.toLowerCase() === 'jcb') return '/images/jcb.svg'
  else return ''
}

const formatCardNumber = (value) => {
  const format = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
  const onlyNumbers = value.replace(/[^\d]/g, '');

  return onlyNumbers.replace(format, (regex, $1, $2, $3, $4) => [$1, $2, $3, $4].filter((group) => !!group).join(' '));
};

const formatExpiry = (value) => {
  return value.replace(
    /[^0-9]/g, '' // To allow only numbers
  ).replace(
    /^([2-9])$/g, '0$1' // To handle 3 > 03
  ).replace(
    /^(1{1})([3-9]{1})$/g, '0$1 / $2' // 13 > 01/3
  ).replace(
    /^0{1,}/g, '0' // To handle 00 > 0
  )
    .replace(
      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1 / $2' // To handle 113 > 11/3
    );
};

const isCompleteEmail = (value) => {
  if (!value) return false;
  if (!value.includes('@') || value.startsWith('@') || value.endsWith('@')) return false;

  const [local, domain] = value.split('@');
  if (!local || !domain || !domain.includes('.') || domain.startsWith('.') || domain.endsWith('.')) return false;
  return true;
}

const isValidEmail = (value) => {
  if (!value) return false;
  const email_pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return email_pattern.test(value);
}

const isValidCardNumber = (value) => {
  return luhn.validate(value);
}

const isValidExpiry = (value) => {
  const expiry = moment(value, 'MM / YY');
  const today = moment();
  if (expiry.isValid()) {
    if (expiry.year() < today.year()) {
      return {
        valid: false,
        message: 'Your card\'s expiration year is in the past'
      }
    } else if (expiry.isBefore(today)) {
      return {
        valid: false,
        message: 'Your card\'s expiration date is in the past'
      }
    } else {
      return {
        valid: true,
        message: ''
      }
    }
  } else {
    return {
      valid: false,
      message: 'Your card\'s expiration date is invalid'
    }
  }
}

const isValidCvc = (value) => {
  if (!value) return false;
  return value.length > 2;
}

const isWallet = (value) => {
  const WALLETS = [
    "alipay",
    "coins",
    "gcash",
    "grab",
    "paymaya",
    "unionpay",
    "wechat"
  ];
  return WALLETS.includes(value);
}

const getWalletPayTitle = (value) => {
  switch (value) {
    case 'alipay': return 'Alipay';
    case 'coins': return 'Coins.ph';
    case 'gcash': return 'GCash';
    case 'grab': return 'GrabPay';
    case 'paymaya': return 'Maya';
    case 'unionpay': return 'UnionPay'
    case 'wechat': return 'WeChat Pay'
    default: return '';
  }
}

const getWalletName = (value) => {
  switch (value) {
    case 'alipay': return 'Alipay';
    case 'coins': return 'Coins.ph';
    case 'gcash': return 'GCash';
    case 'grab': return 'GrabPay';
    case 'paymaya': return 'Maya';
    case 'unionpay': return 'UnionPay'
    case 'wechat': return 'WeChat Pay'
    default: return '';
  }
}

const getWalletIcon = (value) => {
  switch (value) {
    case 'alipay': return 'images/icons/alipay.svg';
    case 'coins': return 'images/icons/coins.svg';
    case 'gcash': return 'images/icons/gcash.png';
    case 'grab': return 'images/icons/grab.svg';
    case 'paymaya': return 'images/icons/maya.png';
    case 'unionpay': return 'images/icons/unionpay.svg';
    case 'wechat': return 'images/icons/wechat.svg'
    default: return '';
  }
}

const getLogo = (value) => {
  switch (value) {
    case 'alipay': return 'images/logos/alipay.svg';
    case 'bdo': return 'images/logos/bdo.svg';
    case 'bpi': return 'images/logos/bpi2.svg';
    case 'coins': return 'images/logos/coins.png';
    case 'gcash': return 'images/logos/gcash.png';
    case 'grab': return 'images/logos/grab.svg';
    case 'instapay': return 'images/logos/instapay.png';
    case 'metrobank': return 'images/logos/metrobank.svg';
    case 'paymaya': return 'images/logos/maya.svg';
    case 'pnb': return 'images/logos/pnb.svg';
    case 'rcbc': return 'images/logos/rcbc.svg';
    case 'unionbank': return 'images/logos/unionbank.svg';
    case 'unionpay': return 'images/logos/unionpay.svg';
    case 'wechat': return 'images/logos/wechat.png'
    default: return '';
  }
}

const getButtonColor = (value) => {
  switch (value) {
    case 'alipay': return '#1677FF';
    case 'bdo': return '#122A72';
    case 'bpi': return '#9A191F';
    case 'coins': return '#049BE5';
    case 'gcash': return '#007BFF';
    case 'grab': return '#00AF41';
    case 'instapay': return '#FFFFFF';
    case 'metrobank': return '#01529e';
    case 'paymaya': return '#1f2122';
    case 'pnb': return '#FEFEFE';
    case 'rcbc': return '#3983D8';
    case 'unionbank': return '#FF8000';
    case 'unionpay': return '#FAFAFA';
    case 'wechat': return '#00B019';
    default: return '#ffffff';
  }
}

const isBank = (value) => {
  const BANKS = ["bpi", "bdo", "metrobank", "pnb", "rcbc", "unionbank"];
  return BANKS.includes(value);
}

const getBankPayTitle = (value) => {
  switch (value) {
    case 'bpi': return 'BPI Online';
    case 'bdo': return 'BDO Online';
    case 'metrobank': return 'Metrobank Online';
    case 'pnb': return 'PNB Online';
    case 'rcbc': return 'RCBC Online'
    case 'unionbank': return 'UnionBank Online';
    default: return '';
  }
}

const getBankName = (value) => {
  switch (value) {
    case 'bpi': return 'Bank of the Philippine Islands';
    case 'bdo': return 'BDO Unibank, Inc.';
    case 'metrobank': return 'Metropolitan Bank and Trust Co.';
    case 'pnb': return 'Philippine National Bank';
    case 'rcbc': return 'Rizal Commercial Banking Corporation'
    case 'unionbank': return 'Unionbank of the Philippines';
    default: return '';
  }
}

/* const getBankIcon = (value) => {
  switch (value) {
    case 'bpi': return <Image src={IconBPI} height={14} width={14} layout="fixed" className="object-center object-contain" />;
    case 'bdo': return <Image src={IconBDO} height={18} width={18} layout="fixed" className="object-center object-contain rounded-0.75" />;
    case 'metrobank': return <Image src={IconMetrobank} height={18} width={18} layout={"fixed"} className="object-center object-contain rounded-0.75" />;
    case 'pnb': return <Image src={IconPNB} height={18} width={18} layout="fixed" className="object-center object-contain rounded-0.75" />;
    case 'rcbc': return <Image src={IconRCBC} height={18} width={18} layout="fixed" className="object-center object-contain rounded-0.75" />;
    case 'unionbank': return <Image src={IconUnionbank} height={18} width={18} layout="fixed" className="object-center object-contain rounded-0.75" />;
    default: return <></>;
  }
} */

const getBankIcon = (value) => {
  switch (value) {
    case 'bpi': return 'images/icons/bpi2.svg';
    case 'bdo': return 'images/icons/bdo.svg';
    case 'metrobank': return 'images/icons/metrobank.svg';
    case 'pnb': return 'images/icons/png.svg';
    case 'rcbc': return 'images/icons/rcbc.svg';
    case 'unionbank': return 'images/icons/unionbank.svg';
    default: return <></>;
  }
}

const getBankCode = (value) => {
  switch (value) {
    case 'bpi': return 'magpiebpi';
    case 'bdo': return 'bdo';
    case 'metrobank': return 'metrobank';
    case 'pnb': return 'pnb';
    case 'rcbc': return 'rcbc';
    case 'unionbank': return 'unionbank';
    default: return '';
  }
}

const getSubmitLabel = (value) => {
  switch (value) {
    case 'pay': return 'Pay';
    case 'book': return 'Book';
    case 'donate': return 'Donate';
    case 'send': return 'Send';
    default: return '';
  }
}


export default {
  getBrand,
  getBrandIcon,
  getCardIcon,
  formatCardNumber,
  formatExpiry,
  isCompleteEmail,
  isValidEmail,
  isValidCardNumber,
  isValidExpiry,
  isValidCvc,
  isWallet,
  getWalletPayTitle,
  getWalletName,
  getWalletIcon,
  isBank,
  getBankPayTitle,
  getBankName,
  getBankIcon,
  getBankCode,
  getLogo,
  getButtonColor,
  getSubmitLabel,
}