import * as Sentry from '@sentry/nextjs';
import SecretsManager from './SecretsManager';
const AWS = require('aws-sdk');

const dynamoDb = new AWS.DynamoDB.DocumentClient({
  region: process.env.NEXT_PUBLIC_AWS_REGION,
});

// MAGPIE_CHECKOUT_SESSIONS_TABLE
// MAGPIE_CHECKOUT_SESSIONS_OTP_TABLE
// MAGPIE_CHECKOUT_SESSIONS_LOGS_TABLE

const get = async (id) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_TABLE,
      Key: {
        id: id,
      },
    };

    const data = await dynamoDb.get(params).promise();
    return data.Item;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const getByChargeId = async (chargeId) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_TABLE,
      IndexName: 'chargeId-index',
      KeyConditionExpression: 'chargeId = :key',
      ExpressionAttributeValues: { ':key': chargeId },
    };

    const result = await dynamoDb.query(params).promise();
    return result.Items;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const listBySessionKey = async (sessionKey) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_TABLE,
      IndexName: 'sessionKey-index',
      KeyConditionExpression: 'sessionKey = :key',
      ExpressionAttributeValues: { ':key': sessionKey },
    };

    const result = await dynamoDb.query(params).promise();
    return result.Items;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const listBySecretKey = async (secretKey) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_TABLE,
      IndexName: 'secretKey-index',
      KeyConditionExpression: 'secretKey = :key',
      ExpressionAttributeValues: { ':key': secretKey },
    };

    const result = await dynamoDb.query(params).promise();
    return result.Items;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const list = async () => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_TABLE,
    };
    const result = await dynamoDb.scan(params).promise();
    return result.Items;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const create = async (item) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_TABLE,
      Item: item,
    };

    await dynamoDb.put(params).promise();
    const result = await get(item.id);
    return result;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const updateSession = async (id, { customer, shipping, billing }) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_TABLE,
      Key: { id: id },
      ConditionExpression: '#id = :id',
      UpdateExpression:
        'SET #customer = :customerId, #customerName = :customerName, #customerEmail = :customerEmail, #customerPhone = :customerPhone, #shipping = :shipping, #billing = :billing, #updated = :updated',
      ExpressionAttributeNames: {
        '#id': 'id',
        '#billing': 'billing',
        '#customer': 'customer',
        '#customerEmail': 'customerEmail',
        '#customerName': 'customerName',
        '#customerPhone': 'customerPhone',
        '#shipping': 'shipping',
        '#updated': 'lastUpdated',
      },
      ExpressionAttributeValues: {
        ':id': id,
        ':billing': billing,
        ':customerEmail': customer.email,
        ':customerId': customer.id,
        ':customerName': customer.name,
        ':customerPhone': customer.phone ? customer.phone : null,
        ':shipping': shipping,
        ':updated': new Date().getTime(),
      },
    };

    await dynamoDb.update(params).promise();
    const result = await get(id);
    return result;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const expireSession = async (id) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_TABLE,
      Key: { id: id },
      ConditionExpression: '#id = :id',
      UpdateExpression: 'SET #status = :status, #updated = :updated',
      ExpressionAttributeNames: {
        '#id': 'id',
        '#status': 'paymentStatus',
        '#updated': 'lastUpdated',
      },
      ExpressionAttributeValues: {
        ':id': id,
        ':status': 'expired',
        ':updated': new Date().getTime(),
      },
    };

    await dynamoDb.update(params).promise();
    const result = await get(id);
    return result;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const updateSessionPayment = async (
  id,
  { customer, paymentDetails, shipping, billing, status }
) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_TABLE,
      Key: { id: id },
      ConditionExpression: '#id = :id',
      UpdateExpression:
        'SET #chargeId = :chargeId, #customer = :customerId, #customerName = :customerName, #customerEmail = :customerEmail, #customerPhone = :customerPhone, #paymentDetails = :paymentDetails, #shipping = :shipping, #billing = :billing, #status = :status, #updated = :updated',
      ExpressionAttributeNames: {
        '#id': 'id',
        '#billing': 'billing',
        '#chargeId': 'chargeId',
        '#customer': 'customer',
        '#customerEmail': 'customerEmail',
        '#customerName': 'customerName',
        '#customerPhone': 'customerPhone',
        '#paymentDetails': 'paymentDetails',
        '#shipping': 'shipping',
        '#status': 'paymentStatus',
        '#updated': 'lastUpdated',
      },
      ExpressionAttributeValues: {
        ':id': id,
        ':billing': billing,
        ':chargeId': paymentDetails.id,
        ':customerId': customer.id,
        ':customerEmail': customer.email,
        ':customerName': customer.name,
        ':customerPhone': customer.phone ? customer.phone : null,
        ':paymentDetails': paymentDetails,
        ':shipping': shipping,
        ':status': status,
        ':updated': new Date().getTime(),
      },
    };

    await dynamoDb.update(params).promise();
    const result = await get(id);
    return result;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const updateSessionPaymentStatus = async (
  id,
  paymentDetails,
  status
) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_TABLE,
      Key: { id: id },
      ConditionExpression: '#id = :id',
      UpdateExpression:
        'SET #chargeId = :chargeId, #paymentDetails = :paymentDetails, #status = :status, #updated = :updated',
      ExpressionAttributeNames: {
        '#id': 'id',
        '#chargeId': 'chargeId',
        '#paymentDetails': 'paymentDetails',
        '#status': 'paymentStatus',
        '#updated': 'lastUpdated',
      },
      ExpressionAttributeValues: {
        ':id': id,
        ':chargeId': paymentDetails.id,
        ':paymentDetails': paymentDetails,
        ':status': status,
        ':updated': new Date().getTime(),
      },
    };

    await dynamoDb.update(params).promise();
    const result = await get(id);
    return result;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const getOtpItem = async (id) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_OTP_TABLE,
      Key: {
        id: id,
      },
    };

    const data = await dynamoDb.get(params).promise();
    return data.Item;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const insertOtpItem = async (item) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_OTP_TABLE,
      Item: item,
    };

    await dynamoDb.put(params).promise();
    const result = await getOtpItem(item.id);
    return result;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const updateOtpItem = async (id, status) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_OTP_TABLE,
      Key: { id: id },
      ConditionExpression: '#id = :id',
      UpdateExpression: 'SET #status = :status, #updated = :updated',
      ExpressionAttributeNames: {
        '#id': 'id',
        '#status': 'status',
        '#updated': 'updated',
      },
      ExpressionAttributeValues: {
        ':id': id,
        ':status': status,
        ':updated': new Date().getTime(),
      },
    };

    await dynamoDb.update(params).promise();
    const result = await getOtpItem(id);
    return result;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const getPendingOtpItems = async (mobileNumber) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_OTP_TABLE,
      IndexName: 'recipient-index',
      KeyConditionExpression: 'recipient = :key',
      FilterExpression: '#status = :status',
      ExpressionAttributeNames: { '#status': 'status' },
      ExpressionAttributeValues: {
        ':key': mobileNumber,
        ':status': 'pending',
      },
    };

    const result = await dynamoDb.query(params).promise();
    return result.Items;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const listLogs = async (sessionId) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_LOGS_TABLE,
      IndexName: 'sessionId-index',
      KeyConditionExpression: 'sessionId = :sessionId',
      ExpressionAttributeValues: { ':sessionId': sessionId },
    };

    const result = await dynamoDb.query(params).promise();
    return result.Items;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const getLogItem = async (id) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_LOGS_TABLE,
      Key: {
        id: id,
      },
    };

    const data = await dynamoDb.get(params).promise();
    return data.Item;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

const insertLogItem = async (item) => {
  try {
    const secret = await SecretsManager.getSecret();
    const params = {
      TableName: secret.MAGPIE_CHECKOUT_SESSIONS_LOGS_TABLE,
      Item: item,
    };

    await dynamoDb.put(params).promise();
    const result = await getLogItem(item.id);
    return result;
  } catch (err) {
    console.log('dynamo_error', err);
    Sentry.captureException(err);
    return null;
  }
};

export default {
  get,
  getByChargeId,
  listBySessionKey,
  listBySecretKey,
  list,
  create,
  updateSession,
  updateSessionPayment,
  updateSessionPaymentStatus,
  expireSession,
  getByChargeId,
  insertOtpItem,
  updateOtpItem,
  getOtpItem,
  getPendingOtpItems,
  listLogs,
  getLogItem,
  insertLogItem,
};
