import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import AnimateHeight from 'react-animate-height';
import { Transition } from '@headlessui/react';
import ReactTooltip from 'react-tooltip';
import parsePhoneNumber, {AsYouType, isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength} from 'libphonenumber-js/mobile';
import moment from 'moment';
import * as Sentry from '@sentry/nextjs'

import CardInfo from './CardInfo';
import CardModal from './CardModal';
import BankInfo from './BankInfo';
import BankModal from './BankModal';
import WalletInfo from './WalletInfo';
import WalletModal from './WalletModal';
import ErrorModal from './ErrorModal';
import SaveInfoMoreModal from './SaveMoreInfoModal';
import LoginVerificationModal from './LoginVerificationModal';
import LogoutConfirmModal from './LogoutConfirmModal';
import PhoneVerificationModal from './PhoneVerificationModal';

import CardUtils from '../utils/CardUtils';
import ColorUtils from '../utils/ColorUtils';
import CountryUtils from '../utils/CountryUtils';

import Api from '../api/Api';
import StringUtils from '../utils/StringUtils';
import NumberUtils from '../utils/NumberUtils';

const PaymentForm = ({
  allowedCountries,
  banks = [],
  bankCode,
  billing,
  billingAddress,
  buttonColor,
  charge,
  currency,
  defaultCountry,
  description,
  eWallets = [],
  livemode = false,
  loaded,
  mode = 'payment',
  merchant,
  metadata,
  origin,
  onSuccessPayment,
  paymentMethods = [],
  publicKey = null,
  quickPay = null,
  quickPayMethod = null,
  requestName = true,
  requestPhone = false,
  requireAuth = true,
  secretKey = null,
  selectedBank,
  selectedMethod,
  selectedWallet,
  session,
  sessionCustomer,
  shipping,
  shippingAddress,
  submitType,
  total,
  version = '1',
}) => {
  const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || window.location.origin;
  const timerRef = useRef(null);
  const payRef = useRef(null);
  const cardRef = useRef(null);
  const cardFrameRef = useRef(null);
  const bankRef = useRef(null);
  const walletRef = useRef(null);
  const bankFrameRef = useRef(null);
  const walletFrameRef = useRef(null);

  const [formHeight, setFormHeight] = useState('auto');
  const [initialHeight, setInitialHeight] = useState(0);
  const [paymentHeight, setPaymentHeight] = useState('auto');
  const [email, setEmail] = useState(sessionCustomer.email || '');
  const [emailMissing, setEmailMissing] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [countryCodes, setCountryCodes] = useState([]);
  const [countryCode, setCountryCode] = useState('PH');
  const [phone, setPhone] = useState();
  const [phoneMissing, setPhoneMissing] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState('');
  const [showCard, setShowCard] = useState(false);
  const [brand, setBrand] = useState('');
  const [cardDetailsMissing, setCardDetailsMissing] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cardNumberMissing, setCardNumberMissing] = useState(false);
  const [cardNumberError, setCardNumberError] = useState(false);
  const [cardNumberErrorMsg, setCardNumberErrorMsg] = useState('');
  const [expiry, setExpiry] = useState('');
  const [expiryMissing, setExpiryMissing] = useState(false);
  const [expiryError, setExpiryError] = useState(false);
  const [expiryErrorMsg, setExpiryErrorMsg] = useState('');
  const [cvc, setCvc] = useState('');
  const [cvcMissing, setCvcMissing] = useState(false);
  const [cvcError, setCvcError] = useState(false);
  const [cvcErrorMsg, setCvcErrorMsg] = useState('');
  const [name, setName] = useState(sessionCustomer.name || '');
  const [nameMissing, setNameMissing] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [nameErrorMsg, setNameErrorMsg] = useState('');

  const [paymentMethod, setPaymentMethod] = useState('');

  const [cardModalVisible, setCardModalVisible] = useState(false);
  const [cardFrameLoading, setCardFrameLoading] = useState(true);
  const [cardFrameUrl, setCardFrameUrl] = useState('');
  const [cardFrameNav, setCardFrameNav] = useState('');
  const [cardNavVisible, setCardNavVisible] = useState(true);
  const [cardCustomer, setCardCustomer] = useState(null);
  
  const [saveInfoChecked, setSaveInfoChecked] = useState(false);
  const [linkWithMagpieInfoVisible, setLinkWithMagpieInfoVisible] = useState(false);
  const [saveInfoPhoneCountry, setSaveInfoPhoneCountry] = useState('PH');
  const [saveInfoPhone, setSaveInfoPhone] = useState();
  const [saveInfoPhoneLoading, setSaveInfoPhoneLoading] = useState(false);
  const [saveInfoPhoneVerified, setSaveInfoPhoneVerified] = useState(false);
  const [saveInfoPhoneMissing, setSaveInfoPhoneMissing] = useState(false);
  const [saveInfoPhoneError, setSaveInfoPhoneError] = useState();
  const [phoneVerifyModalVisible, setPhoneVerifyModalVisible] = useState(false);
  const [otpRefId, setOtpRefId] = useState();
  const [loginVerifyModalVisible, setLoginVerifyModalVisible] = useState(false);
  const [logoutConfirmVisible, setLogoutConfirmVisible] = useState(false);
  const [customerFound, setCustomerFound] = useState(null);
  const [emailLookupLoading, setEmailLookupLoading] = useState(false);
  const [emailHasSavedInfo, setEmailHasSavedInfo] = useState(false);
  const [savedInfo, setSavedInfo] = useState([]);
  const [selectedSavedInfo, setSelectedSavedInfo] = useState(null);
  const [useSavedInfo, setUseSavedInfo] = useState(false);
  const [savedInfoErrorMsg, setSavedInfoErrorMsg] = useState();
  const [updateSavedInfo, setUpdateSavedInfo] = useState(false);
  const [saveRetries, setSaveRetries] = useState(0);

  const allowedWallets = ['gcash', 'paymaya', 'alipay', 'wechat', 'unionpay'];
  const [wallet, setWallet] = useState('');
  const [walletMissing, setWalletMissing] = useState(false);
  const [walletError, setWalletError] = useState(false);
  const [walletErrorMsg, setWalletErrorMsg] = useState('');
  const [walletModalVisible, setWalletModalVisible] = useState(false);
  const [walletFrameLoading, setWalletFrameLoading] = useState(true);
  const [walletFrameUrl, setWalletFrameUrl] = useState('');
  const [walletFrameNav, setWalletFrameNav] = useState('');
  const [walletNavVisible, setWalletNavVisible] = useState(true);
  const [walletCustomer, setWalletCustomer] = useState(null);

  const allowedBanks = ['bpi', 'bdo', 'metrobank', 'pnb', 'rcbc', 'unionbank'];
  const [bank, setBank] = useState('');
  const [bankMissing, setBankMissing] = useState(false);
  const [bankError, setBankError] = useState(false);
  const [bankErrorMsg, setBankErrorMsg] = useState('');
  const [bankModalVisible, setBankModalVisible] = useState(false);
  const [bankFrameLoading, setBankFrameLoading] = useState(true);
  const [bankFrameUrl, setBankFrameUrl] = useState('');
  const [bankFrameNav, setBankFrameNav] = useState('');
  const [bankNavVisible, setBankNavVisible] = useState(true);
  const [bankCustomer, setBankCustomer] = useState(null);

  const [shippingCountry, setShippingCountry] = useState(defaultCountry);
  const [shippingDetailsMissing, setShippingDetailsMissing] = useState(false);
  const [shippingName, setShippingName] = useState('');
  const [shippingNameMissing, setShippingNameMissing] = useState(false);
  const [shippingAddressLine1, setShippingAddressLine1] = useState('');
  const [shippingAddressLine1Missing, setShippingAddressLine1Missing] = useState(false);
  const [shippingAddressLine2, setShippingAddressLine2] = useState('');
  const [shippingAddressSuburb, setShippingAddressSuburb] = useState('');
  const [shippingAddressSuburbMissing, setShippingAddressSuburbMissing] = useState(false);
  const [shippingAddressCity, setShippingAddressCity] = useState('');
  const [shippingAddressCityMissing, setShippingAddressCityMissing] = useState(false);
  const [shippingAddressPostalCode, setShippingAddressPostalCode] = useState('');
  const [shippingAddressPostalMissing, setShippingAddressPostalMissing] = useState(false);
  const [shippingAddressState, setShippingAddressState] = useState('');
  const [shippingAddressStateMissing, setShippingAddressStateMissing] = useState(false);

  const [sameBillingAsShipping, setSameBillingAsShipping] = useState(true);
  const [billingCountry, setBillingCountry] = useState(defaultCountry);
  const [billingDetailsMissing, setBillingDetailsMissing] = useState(false);
  const [billingAddressLine1, setBillingAddressLine1] = useState('');
  const [billingAddressLine1Missing, setBillingAddressLine1Missing] = useState(false);
  const [billingAddressLine2, setBillingAddressLine2] = useState('');
  const [billingAddressSuburb, setBillingAddressSuburb] = useState('');
  const [billingAddressCity, setBillingAddressCity] = useState('');
  const [billingAddressCityMissing, setBillingAddressCityMissing] = useState(false);
  const [billingAddressPostalCode, setBillingAddressPostalCode] = useState('');
  const [billingAddressPostalMissing, setBillingAddressPostalMissing] = useState(false);
  const [billingAddressState, setBillingAddressState] = useState('');
  const [billingAddressStateMissing, setBillingAddressStateMissing] = useState(false);

  const [isQuickPay, setIsQuickPay] = useState(false);
  const [quickPayLoading, setQuickPayLoading] = useState(false);
  const [quickPaySuccess, setQuickPaySuccess] = useState(false);
  const [error, setError] = useState('');
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    setTooltipVisible(true);
    const groups = CountryUtils.getCountriesMobileCodes().reduce((a, b) => {
      let group = b.label.charAt(0);
      if (!a[group]) {
        a[group] = {
          group,
          countries: [b]
        };
      } else {
        a[group].countries.push(b);
      }

      return a;
    }, {});
    const countryItems = Object.values(groups);
    setCountryCodes(countryItems);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (payRef.current !== null) {
        let totalHeight = initialHeight + 10;
        let payHeight = initialHeight;
        if (initialHeight === 0) {
          setInitialHeight(payRef.current.clientHeight);
          payHeight = payRef.current.clientHeight;
          totalHeight = payRef.current.clientHeight + 10;
        }
        //console.log(totalHeight)
        if (hasOtherPaymentMethods()) {
          if (paymentMethod === 'card') {
            if (cardRef.current !== null) {
              payHeight = payHeight + cardRef.current.clientHeight;
              totalHeight = totalHeight + cardRef.current.clientHeight;
            }
          } else if (paymentMethod === 'bank') {
            if (bankRef.current !== null) {
              payHeight = payHeight + bankRef.current.clientHeight;
              totalHeight = totalHeight + bankRef.current.clientHeight;
            }
          } else if (paymentMethod === 'wallet') {
            if (walletRef.current !== null) {
              payHeight = payHeight + walletRef.current.clientHeight;
              totalHeight = totalHeight + walletRef.current.clientHeight;
            }
          }
        }
        setPaymentHeight(`${payHeight}px`)
        setFormHeight(`${totalHeight}px`);
        // console.log(`paymentHeight: ${payHeight}px`);
        // console.log(`formHeight: ${totalHeight}px`);
      }
    }, 2000);

    return () => {
      if (timer !== null) clearTimeout(timer);
    }
  })

 useEffect(() => {
    if (sessionCustomer) {
      setEmail(sessionCustomer.email || '');
      setName(sessionCustomer.name || '');

      if (requestPhone) {
        if (sessionCustomer.phone) {
          const phoneNumber = parsePhoneNumber(sessionCustomer.phone);
          if (phoneNumber) {
            setCountryCode(phoneNumber.country);
            setPhone(phoneNumber.number);
          } else {
            setPhone(undefined);
          }
        } else {
          setPhone(undefined);
        }
      } else {
        setPhone(undefined);
      }
    }
 }, [sessionCustomer ,requestPhone])

  useEffect(() => {
    let timer = null;
    const getCharge = async (key, chargeId) => {
      const is_quick_pay = quickPay ? quickPay === selectedMethod && (quickPayMethod === selectedBank || quickPayMethod === selectedWallet) : false;
      
      if (is_quick_pay) {
        setQuickPayLoading(true);
      } else if (selectedMethod) {
        setLoading(true);
      }
      //console.log('charge', chargeId);
      //console.log('customer', sessionCustomer);
      if (chargeId && sessionCustomer.email) {
        const chargeRes = await Api.getCharge(key, version, chargeId);
        console.log('charge', chargeRes.data);
        //console.log('session', session);
        
        if (chargeRes.status === 200) {
          if (!chargeRes.data.authorized && !chargeRes.data.captured) {
            if (is_quick_pay) {
              timer = setTimeout(() => {
                setError('Payment failed');
                setErrorModalVisible(true);
                setQuickPayLoading(false);
                setIsQuickPay(false);
              }, 800)
            } else {
              if (selectedMethod === 'bank') {
                  timer = setTimeout(() => {
                    setBankError(true);
                    setBankErrorMsg('Payment was cancelled');
                  }, 800)
              } else if (selectedMethod === 'wallet') {
                  timer = setTimeout(() => {
                    setWalletError(true);
                    setWalletErrorMsg('Payment was cancelled');
                  }, 800)
              } else {
                // card
                timer = setTimeout(() => {
                  setCardNumberError(true);
                  setCardNumberErrorMsg('Payment authorization failed');
                }, 800)
              }
            }
            setLoading(false);
            setComplete(false);
          } else {
            if (chargeRes.data.status === 'refunded' && chargeRes.data.amount === chargeRes.data.amount_refunded) {
              setCardNumberError(true);
              setCardNumberErrorMsg('Payment was refunded');
              setLoading(false);
              setComplete(false);
              return;
            }

            setBankModalVisible(false);
            setWalletModalVisible(false);
            const updateRes = await Api.updateSessionPayment(
              key,
              version,
              session.id,
              {
                id: sessionCustomer.id,
                name: sessionCustomer.name,
                email: sessionCustomer.email,
                phone: sessionCustomer.phone,
              },
              chargeRes.data,
              session.shipping,
              session.billing ? session.billing : getBillingAddress(),
            );
            console.log('updateSessionPayment', updateRes.data)

            if (updateRes.status === 200) {
              // if mode is subscription, save the payment source to customer if card is not yet saved
              if (mode === 'subscription' && sessionCustomer.id) {
                const customerRes = await Api.getCustomer(key, version, sessionCustomer.id);
                const chargeData = chargeRes.data;
                const source = chargeData.source;
                if (customerRes.status === 200) {
                  const saveCardRes = await Api.attachCustomerSource(key, sessionCustomer.id, source.id);
                  if (saveCardRes.status !== 200) {
                    // refund the charge if saving the card failed
                    await Api.refundCharge(key, chargeId, chargeData.amount);
                    setError(Array.isArray(saveCardRes.data.detail) ? saveCardRes.data.detail[0].msg : saveCardRes.data.detail || 'Failed to save payment information.');
                    setErrorModalVisible(true);
                    setLoading(false);
                    setComplete(false);
                    return;
                  }
                } else {
                  // refund the charge if fetching customer data failed
                  await Api.refundCharge(key, chargeId, chargeData.amount);
                  setError(Array.isArray(customerRes.data.detail) ? customerRes.data.detail[0].msg : customerRes.data.detail || 'Failed to retrieve customer information.');
                  setErrorModalVisible(true);
                  setLoading(false);
                  setComplete(false);
                  return;
                }
              }

              // if mode is save_card, save the payment source to customer if card is not yet saved, and void the authorized charge
              if (mode === 'save_card' && sessionCustomer.id) {
                const customerRes = await Api.getCustomer(key, version, sessionCustomer.id);
                const chargeData = chargeRes.data;
                const source = chargeData.source;
                if (customerRes.status === 200) {
                  const saveCardRes = await Api.attachCustomerSource(key, sessionCustomer.id, source.id);
                  if (saveCardRes.status !== 200) {
                    // saving card failed
                    console.error('Attach source error', {status: saveCardRes.status, data: saveCardRes.data});
                    // void the authorized charge
                    const voidRes = await Api.voidCharge(key, chargeId);
                    if (voidRes.status !== 200) {
                      console.error('Void charge error', {status: voidRes.status, data: voidRes.data});
                      setError('Your payment was authorized, but we were unable to save your payment details. Additionally, an issue was encountered while refunding the Test Charge of Php 25.00. Please contact the merchant for refund assistance.');
                      setErrorModalVisible(true);
                      setLoading(false);
                      setComplete(false);
                      return;
                    } else {
                      setError('Your payment was authorized, but unable to save your payment details. The Test Charge of Php 25.00 has been successfully refunded. Please try entering your payment information again.');
                      setErrorModalVisible(true);
                      setLoading(false);
                      setComplete(false);
                      return;
                    }
                  } else {
                    // save card success, void the authorized charge
                    const voidRes = await Api.voidCharge(key, chargeId);
                    if (voidRes.status !== 200) {
                      console.error('Void charge error', {status: voidRes.status, data: voidRes.data});
                      setError('Your payment details have been successfully saved. However, an issue was encountered while attempting to refund the Test Charge of Php 25.00. Please contact the merchant for refund assistance.');
                      setErrorModalVisible(true);
                      setLoading(false);
                      setComplete(false);
                      return;
                    }
                  }
                }
              }

              if (is_quick_pay) {
                setQuickPayLoading(false);
                setQuickPaySuccess(true);
              } else {
                setLoading(false);
                setSuccess(true);
              }

              timer = setTimeout(() => {
                onSuccessPayment(session.id);
              }, 2000);
            } else {
              if (is_quick_pay) {
                setError(updateRes.data.message);
                setErrorModalVisible(true);
                setQuickPayLoading(false);
                setIsQuickPay(false);
              } else {
                if (selectedMethod === 'bank') {
                  setBankError(true);
                  setBankErrorMsg(updateRes.data.message)
                } else if (selectedMethod === 'wallet') {
                  setWalletError(true);
                  setWalletErrorMsg(updateRes.data.message);
                } else {
                  setError(updateRes.data.message);
                  setErrorModalVisible(true);
                }
                setLoading(false);
                setComplete(false);
              }
            }
          }
          
        } else {
          if (is_quick_pay) {
            setError(chargeRes.data.message);
            setErrorModalVisible(true);
            setQuickPayLoading(false);
            setIsQuickPay(false);
          } else {
            if (selectedMethod === 'bank') {
              setBankError(true);
              setBankErrorMsg(chargeRes.data.message)
            } else if (selectedMethod === 'wallet') {
              setWalletError(true);
              setWalletErrorMsg(chargeRes.data.message);
            }
            setLoading(false);
            setComplete(false);
          }
        }
      } else {
        if (is_quick_pay) {
          timer = setTimeout(() => {
            setError('Payment failed');
            setErrorModalVisible(true);
            setQuickPayLoading(false);
            setIsQuickPay(false);
          }, 800)
        } else {
          if (selectedMethod === 'bank') {
              timer = setTimeout(() => {
                setBankError(true);
                setBankErrorMsg('Payment was cancelled');
              }, 800)
          } else if (selectedMethod === 'wallet') {
              timer = setTimeout(() => {
                setWalletError(true);
                setWalletErrorMsg('Payment was cancelled');
              }, 800)
          } else {
            // card
            timer = setTimeout(() => {
              setCardNumberError(true);
              setCardNumberErrorMsg('Payment authorization failed');
            }, 800)
          }
        }
        setLoading(false);
        setComplete(false);
      }
    }

    if (secretKey && charge) {
      setLoading(true);
      timer = setTimeout(() => {
        getCharge(secretKey, charge);
      }, 1000)
    }

    return () => {
      if (timer !== null) clearTimeout(timer);
    }
  }, [charge, secretKey, session, sessionCustomer, selectedMethod, selectedBank, selectedWallet])

  // Handle redirect after BPI OAuth Login
  useEffect(() => {
    if (!!secretKey && !!session && !!sessionCustomer) {
      // console.log({secretKey, session, sessionCustomer})
      
      if (selectedMethod === 'bank' && selectedBank === 'bpi') {
        console.log({selectedMethod, selectedBank})
        setBankCustomer(sessionCustomer);
        const code = !!bankCode ? bankCode : CardUtils.getBankCode(selectedBank.toLowerCase());

        const data = `session_id=${session.id}&key=${secretKey}&merchant=${StringUtils.truncate(merchant, 22, false).replace(/[^a-zA-Z0-9 ]/g, '')}&total=${total}&currency=${currency}&description=${description}&customer=${sessionCustomer.id}&code=${code}&submit=${submitType}`;

        console.log('params', data);

        const bankUrl = `${BASE_URL}/bank/v2/bpi/checkout?d=${Buffer.from(encodeURIComponent(data), 'utf8').toString('base64')}`;

        console.log({bankUrl});

        setBankFrameUrl(bankUrl);
        setBankFrameLoading(true);
        setBankModalVisible(true);
      } else {
        setBankFrameUrl('');
        setBankFrameLoading(false);
        setBankModalVisible(false);
      }
    }
  }, [secretKey, session, sessionCustomer, selectedMethod, selectedBank]);

  // Handle redirect after BPI OAuth Login for Quick Pay Button
  /* useEffect(() => {
    if (secretKey && session && sessionCustomer) {
      console.log({quickPay, quickPayMethod})
      if (quickPay === 'bank' && quickPayMethod === 'bpi') {
        setBankCustomer(sessionCustomer);
        const code = !!bankCode ? bankCode : CardUtils.getBankCode(quickPayMethod.toLowerCase());

        const data = `session_id=${session.id}&key=${secretKey}&merchant=${StringUtils.truncate(merchant, 22, false).replace(/[^a-zA-Z0-9 ]/g, '')}&total=${total}&currency=${currency}&description=${description}&customer=${sessionCustomer.id}&code=${code}&submit=${submitType}`;

        console.log('params', data);

        const bankUrl = `${window.location.origin}/bank/v2/bpi/checkout?d=${Buffer.from(encodeURIComponent(data), 'utf8').toString('base64')}`;

        console.log({bankUrl});

        setBankFrameUrl(bankUrl);
        setBankFrameLoading(true);
        setBankModalVisible(true);
      }
    }
  }, [secretKey, session, sessionCustomer, quickPay, quickPayMethod]); */

  useEffect(() => {
    if (selectedMethod) {
      setPaymentMethod(selectedMethod);
    }
  }, [selectedMethod]);

  useEffect(() => {
    if (selectedBank) {
      setBank(selectedBank);
    }
  }, [selectedBank]);

  useEffect(() => {
    if (selectedWallet) {
      setWallet(selectedWallet);
    }
  }, [selectedWallet])


  useEffect(() => {
    if (shippingAddress) {
      //console.log('shipping', shippingAddress);
      setShippingCountry(shippingAddress.country);
      setShippingName(shippingAddress.name);
      setShippingAddressLine1(shippingAddress.line_1);
      setShippingAddressLine2(shippingAddress.line_2);
      setShippingAddressSuburb(shippingAddress.suburb);
      setShippingAddressCity(shippingAddress.city);
      setShippingAddressState(shippingAddress.state);
      setShippingAddressPostalCode(shippingAddress.postal_code);
    }
  }, [shippingAddress])


  useEffect(() => {
    if (billingAddress) {
      //console.log('shipping', shippingAddress);
      setBillingCountry(billingAddress.country ? billingAddress.country : defaultCountry);
      setName(billingAddress.name ? billingAddress.name : '');
      setBillingAddressLine1(billingAddress.line_1 ? billingAddress.line_1 : '');
      setBillingAddressLine2(billingAddress.line_2 ? billingAddress.line_2 : '');
      setBillingAddressSuburb(billingAddress.suburb ? billingAddress.suburb : '');
      setBillingAddressCity(billingAddress.city ? billingAddress.city : '');
      setBillingAddressState(billingAddress.state ? billingAddress.state : '');
      setBillingAddressPostalCode(billingAddress.postal_code ? billingAddress.postal_code : '');
    }
  }, [billingAddress])

  useEffect(() => {
    setShowCard(paymentMethods.findIndex(i => i === 'card') !==  -1);
    if (banks.length === 1) {
      setBank(banks[0]);
    }
    if (eWallets.length === 1) {
      setWallet(eWallets[0]);
    }
  }, [paymentMethods, banks, eWallets])

  useEffect(() => {
    if (showCard && !selectedMethod) {
      setPaymentMethod('card');
    } else {
      if ((quickPay !== 'bank' && banks.length > 0) && !selectedMethod) {
        setPaymentMethod('bank');
      } else if ((quickPay !== 'wallet' && eWallets.length > 0) && !selectedMethod) {
        setPaymentMethod('wallet');
      }
    }
  }, [showCard])

  useEffect(() => {
    setBrand(CardUtils.getBrand(cardNumber.replace(/[^\d]/g, '')));
    if (cardNumber.length === 19) {
      if (CardUtils.isValidCardNumber(cardNumber)) {
        if (CardUtils.getBrand(cardNumber.replace(/[^\d]/g, '')) === '') {
          setCardNumberError(true);
          setCardNumberErrorMsg('Your card number is invalid');
        } else {
          setCardNumberError(false);
          setCardNumberErrorMsg('');
        }
      } else {
        setCardNumberError(true);
        setCardNumberErrorMsg('Your card number is invalid');
      }
    }
    checkIfComplete();
  }, [cardNumber]);

  useEffect(() => {
    if (expiry.length === 7) {
      const validity = CardUtils.isValidExpiry(expiry);
      setExpiryError(!validity.valid);
      setExpiryErrorMsg(validity.message);
    }
    checkIfComplete();
  }, [expiry]);

  useEffect(() => {
    if (paymentMethod === 'card') {
      checkIfComplete();
    } else if (paymentMethod === 'wallet') {
      checkIfCompleteWallet();
    } else if (paymentMethod === 'bank') {
      checkIfCompleteBank();
    }
  }, [email, phone, name, cvc, billingCountry, billingAddressLine1, billingAddressCity, billingAddressPostalCode, billingAddressState, shippingCountry, shippingName, shippingAddressLine1, shippingAddressSuburb, shippingAddressCity, shippingAddressPostalCode, shippingAddressState, sameBillingAsShipping, paymentMethod, wallet, bank, useSavedInfo, saveInfoChecked, saveInfoPhone, saveInfoPhoneVerified, emailError, nameError, phoneError])

  const getSeparatorLabel = () => {
    let label = '';
    /* console.log('quickpay', quickPay);
    console.log('showCard', showCard);
    console.log('banks', banks);
    console.log('wallets', eWallets); */
    if (quickPay) {
      if (showCard) {
        if (quickPay === 'bank' && eWallets.length > 0) {
          label = `Or ${CardUtils.getSubmitLabel(submitType).toLowerCase()} with another method`;
        } else if (quickPay === 'wallet' && banks.length > 0) {
          label = `Or ${CardUtils.getSubmitLabel(submitType).toLowerCase()} with another method`;
        } else {
          label = `Or ${CardUtils.getSubmitLabel(submitType).toLowerCase()} with card`;
        }
      } else {
        if (quickPay !== 'bank' && banks.length > 0) {
          label = `Or ${CardUtils.getSubmitLabel(submitType).toLowerCase()} with bank`;
        } else if (quickPay !== 'wallet' && eWallets.length > 0) {
          label = `Or ${CardUtils.getSubmitLabel(submitType).toLowerCase()} with wallet`;
        }
      }
    }

    return label;
  }

  const resetErrors = () => {
    //console.log('resetting errors');
    setEmailError(false);
    setEmailErrorMsg('');
    setPhoneMissing(false);
    setPhoneError(false);
    setPhoneErrorMsg('');
    setCardDetailsMissing(false);
    setCardNumberMissing(false);
    setCardNumberError(false);
    setCardNumberErrorMsg('');
    setExpiryMissing(false);
    setExpiryError(false);
    setExpiryErrorMsg('');
    setCvcMissing(false);
    setCvcError(false);
    setCvcErrorMsg('');
    setNameMissing(false);
    setNameError(false);
    setNameErrorMsg('');
    setWalletMissing(false);
    setWalletError(false);
    setWalletErrorMsg('');
    setBankMissing(false);
    setBankError(false);
    setBankErrorMsg('');
    setShippingDetailsMissing(false);
    setShippingNameMissing(false);
    setShippingAddressLine1Missing(false);
    setShippingAddressSuburbMissing(false);
    setShippingAddressCityMissing(false);
    setShippingAddressPostalMissing(false);
    setShippingAddressStateMissing(false);
    setBillingDetailsMissing(false);
    setBillingAddressLine1Missing(false);
    setBillingAddressCityMissing(false);
    setBillingAddressPostalMissing(false);
    setBillingAddressStateMissing(false);
    setSaveInfoPhoneMissing(false);
    setSaveInfoPhoneError(undefined);
    setSavedInfoErrorMsg(undefined);
  }

  const onLogin = async (e) => {
    e.preventDefault();
    if (customerFound) {
      setEmailLookupLoading(true);
      setLoginVerifyModalVisible(true);
      const requestRes = await Api.requestOtp(customerFound.mobile_number);
      if (requestRes.status === 200) {
        setOtpRefId(requestRes.data.id);
        setEmailLookupLoading(false);
      } else {
        setEmailLookupLoading(false);
      }
    }
    
  }

  const closeLoginVerifyModalHandler = () => {
    setLoginVerifyModalVisible(false);
  }

  const loginVerifySuccessHandler = () => {
    if (customerFound) {
      setSaveInfoChecked(false);
      setSaveInfoPhone(customerFound.mobile_number);
      const sources = customerFound.sources.filter((s) => s.type === 'card' && moment(`${s.card.exp_month}/${s.card.exp_year}`, 'MM/YYYY').isAfter(moment()));
      if (sources.length > 0) {
        console.log('sources', sources);
        setSavedInfo(sources);
        setSelectedSavedInfo(sources[0]);
        setName(sources[0].card.name);
        
        if (sources[0].card.address_country) {
          console.log('country', sources[0].card.address_country);
          setBillingCountry(sources[0].card.address_country);
        }
        setUseSavedInfo(true);
        
      }
      setLoginVerifyModalVisible(false);
    }
  }

  const onLogout = (e) => {
    e.preventDefault();
    setLogoutConfirmVisible(true);
  }

  const closeLogoutModalHandler = () => {
    setLogoutConfirmVisible(false);
  }

  const logoutHandler = (e) => {
    e.preventDefault();
    setCustomerFound(null)
    setSavedInfo([]);
    setSelectedSavedInfo(null);
    setUseSavedInfo(false);
    setEmailLookupLoading(false);
    setEmailHasSavedInfo(false);
    setEmail('');
    setName('');
    setLogoutConfirmVisible(false);
  }

  const onSelectSavedInfoSource = (e, source) => {
    e.preventDefault();
    setSavedInfoErrorMsg(undefined);
    setSelectedSavedInfo(source);
  }

  const onChangePaymentDetails = (e) => {
    e.preventDefault();
    if (savedInfo) {
      setUseSavedInfo(false);
      setUpdateSavedInfo(true);
    }
  }

  const onUseSavedPaymentInfoHandler = (e) => {
    e.preventDefault();
    if (savedInfo) {
      setUseSavedInfo(true);
      setUpdateSavedInfo(false);
    }
  }

  const onUpdateSavedPaymentInfoHandler = (e) => {
    setUpdateSavedInfo(e.target.checked);
  }

  const onRemovePayment = async (e, source) => {
    e.preventDefault();
    if (source) {
      const res = await Api.removeCustomerSource(secretKey, customerFound.id, source);
      if (res.status === 200) {
        console.log('removed', res.data);
        const cus = res.data;
        const sources = cus.sources.filter((s) => s.type === 'card' && moment(`${s.card.exp_month}/${s.card.exp_year}`, 'MM/YYYY').isAfter(moment()));
        if (sources.length > 0) {
          console.log('sources', sources);
          setSavedInfo(sources);
          setSelectedSavedInfo(sources[0]);
          setName(sources[0].card.name);
          
          if (sources[0].card.address_country) {
            console.log('country', sources[0].card.address_country);
            setBillingCountry(sources[0].card.address_country);
          }
          setUseSavedInfo(true);
        } else {
          setSavedInfo([]);
          setSelectedSavedInfo(null);
          setUseSavedInfo(false);
          setEmailHasSavedInfo(false);
        }
      } else {
        setError(Array.isArray(res.data.detail) ? res.data.detail[0].msg : res.data.detail || 'Failed to remove saved payment info.');
        setErrorModalVisible(true);
      }
    }
  }
  
  const onEmailChanged = async (e) => {
    setEmailMissing(false);
    setEmailError(false);
    setEmailErrorMsg('');
    setEmail(e.target.value);

    // Disable customer lookup for now
    /* if (paymentMethods.includes('card') && paymentMethod === 'card') {
      if (CardUtils.isValidEmail(e.target.value)) {
        setEmailLookupLoading(true);
        const res = await Api.getCustomerByEmail(secretKey, e.target.value);
        if (res.status === 200) {
          const cus = res.data;
          console.log('customer', cus);
          if (cus.mobile_number) {
            if (isValidPhoneNumber(cus.mobile_number.includes('+') ? cus.mobile_number : `+${cus.mobile_number}`, countryCode)) {
              if (cus.sources.length > 0) {
                if (cus.sources.filter((s) => s.type === 'card' && moment(`${s.card.exp_month}/${s.card.exp_year}`, 'MM/YYYY').isAfter(moment())).length > 0) {
                  setCustomerFound(cus);
                  setEmailHasSavedInfo(true);
                  setLoginVerifyModalVisible(true);
                  const requestRes = await Api.requestOtp(cus.mobile_number);
                  
                  if (requestRes.status === 200) {
                    setOtpRefId(requestRes.data.id);
                    setEmailLookupLoading(false);
                  } else {
                    setEmailHasSavedInfo(false);
                    setEmailLookupLoading(false);
                  }
                } else {
                  setEmailHasSavedInfo(false);
                  setEmailLookupLoading(false);
                }
              } else {
                setEmailHasSavedInfo(false);
                setEmailLookupLoading(false);
              }
            } else {
              setEmailHasSavedInfo(false);
              setEmailLookupLoading(false);
            }
          } else {
            setEmailHasSavedInfo(false);
            setEmailLookupLoading(false);
          }
        } else {
          setEmailHasSavedInfo(false);
          setEmailLookupLoading(false);
        }
      } else {
        setEmailHasSavedInfo(false);
      }
    } */
  }

  const onEmailBlur = (e) => {
    if (e.target.value !== undefined) {
      if (e.target.value.length > 0) {
        setEmailMissing(false);
        if (CardUtils.isCompleteEmail(e.target.value)) {
          if (!CardUtils.isValidEmail(e.target.value)) {
            setEmailHasSavedInfo(false);
            setEmailError(true);
            setEmailErrorMsg('Your email is not valid');
          }
        } else {
          setEmailHasSavedInfo(false);
          setEmailError(true);
          setEmailErrorMsg('Your email is incomplete');
        }
        
      } else {
        setEmailHasSavedInfo(false);
        setEmailMissing(true);
      }
    }
    checkIfComplete();
  }

  const onCountryCodeSelected = (e) => {
    setCountryCode(e.target.value);
  }

  const onPhoneChanged = (e) => {
    setPhoneMissing(false);
    setPhoneError(false);
    setPhoneErrorMsg(undefined);
    setPhone(new AsYouType(countryCode).input(e.target.value));
    if (isValidPhoneNumber(e.target.value, countryCode)) {
      setPhoneError(false);
      setPhoneErrorMsg(undefined);
    } else {
      //console.log('invalid phone');
      setPhoneError(true);
      setPhoneErrorMsg('Your mobile number is invalid');
    }
  }

  const onPhoneBlur = (e) => {
    if (e.target.value !== undefined) {
      if (e.target.value.length > 0) {
        setPhoneMissing(false);
        if (validatePhoneNumberLength(e.target.value, saveInfoPhoneCountry) === 'TOO_SHORT') {
          setPhoneError(true);
          setPhoneErrorMsg('Your mobile number is incomplete');
        }
      } else {
        setPhoneMissing(true);
      }
    }
    checkIfComplete();
  }

  const hasOtherPaymentMethods = () => {
    if (paymentMethods.length > 1) {
      if (paymentMethods.findIndex(p => p === 'card') !== -1) {
        return ((banks.length > 0 && quickPay !== 'bank') || (eWallets.length > 0 && quickPay !== 'wallet'));
      } else {
        return ((banks.length > 0 && quickPay !== 'bank') && (eWallets.length > 1 && quickPay !== 'wallet'))
      }
    }  else {
      return false;
    }

    /* if (paymentMethods.findIndex(p => p === 'card') !== -1 && paymentMethods.length > 1) {
      return ((banks.length > 0 && quickPay !== 'bank') || (eWallets.length > 0 && quickPay !== 'wallet'));
    } else {
      return false;
    } */
  }

  const onPaymentMethodClick = (e, method) => {
    e.preventDefault();
    setPaymentMethod(method);
    if (method === 'bank' || method === 'wallet') {
      if (nameMissing && !billing) {
        setNameMissing(false);
      }
    }
  }

  const onCardNumberChanged = (e) => {
    //console.log('autofill', e.target.value);
    if (e.target.value.length > 0) {
      setCardDetailsMissing(false);
      setCardNumberMissing(false);
      setCardNumberError(false);
      setCardNumberErrorMsg('');
    }
    setCardNumber(CardUtils.formatCardNumber(e.target.value));
  }

  const onCardNumberKeyDown = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      setCardDetailsMissing(false);
      setCardNumberError(false);
      setCardNumberErrorMsg('');
      setCardNumber(cardNumber.toString().slice(0, -1));
    } else {
      if (cardNumber.length < 19) {
        setCardDetailsMissing(false);
        setCardNumberMissing(false);
        setCardNumberError(false);
        setCardNumberErrorMsg('');
        const value = CardUtils.formatCardNumber(`${cardNumber}${e.key.toString()}`);
        setCardNumber(value);
      }
    }
  }

  const onCardNumberBlur = (e) => {
    if (e.target.value !== undefined) {
      if (e.target.value.length > 0) {
        setCardDetailsMissing(false);
        setCardNumberMissing(false);
        if (e.target.value.length < 19) {
          setCardNumberError(true);
          if (e.target.value.length > 0 && e.target.value.length < 19) {
            setCardNumberErrorMsg('Your card number is incomplete');
          }
        }
      } else {
        setCardNumberMissing(true);
      }
    }
    checkIfComplete();
  }

  const onExpiryChanged = (e) => {
    if (e.target.value.length > 0) {
      setCardDetailsMissing(false);
      setExpiryMissing(false)
      setExpiryError(false);
      setExpiryErrorMsg('');
    }

    if (e.target.value.length === 7 && !e.target.value.includes(' ')) {
      const exp = e.target.value.split('/');
      exp[1] = exp[1].substr(2, 2);
      const value = exp.join('/');
      setExpiry(CardUtils.formatExpiry(value));
    } else {
      setExpiry(CardUtils.formatExpiry(e.target.value));
    }
  }

  const onExpiryKeydown = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      setCardDetailsMissing(false);
      setExpiryError(false);
      setExpiryErrorMsg('');
      setExpiry(expiry.toString().slice(0, -1));
    } else {
      if (expiry.length < 7) {
        setCardDetailsMissing(false);
        setExpiryMissing(false)
        setExpiryError(false);
        setExpiryErrorMsg('');
        setExpiry(CardUtils.formatExpiry(`${expiry}${e.key.toString()}`));
      }
    }
  }

  const onExpiryBlur = (e) => {
    if (e.target.value !== undefined) {
      if (e.target.value.length > 0) {
        setCardDetailsMissing(false);
        setExpiryMissing(false);
        if (e.target.value.length < 7) {
          setExpiryError(true);
          setExpiryErrorMsg('');
          if (e.target.value.length > 0 && e.target.value.length < 7) {
            setExpiryErrorMsg('Your card\'s expiration date is incomplete');
          }
        }
      } else {
        setExpiryMissing(true);
      }
    }
    checkIfComplete();
  }

  const onCvcChanged = (e) => {
    if (cvc.length > 0) {
      setCardDetailsMissing(false);
      setCvcMissing(false);
      setCvcError(false);
      setCvcErrorMsg('');
    }
    setCvc(e.target.value);
  }

  const onCvcKeyDown = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      setCardDetailsMissing(false);
      setCvcError(false);
      setCvcErrorMsg('');
      setCvc(cvc.toString().slice(0, -1));
    } else {
      if (cvc.length < 3) {
        setCardDetailsMissing(false);
        setCvcMissing(false);
        setCvcError(false);
        setCvcErrorMsg('');
        setCvc(`${cvc}${e.key.toString()}`.replace(/[^0-9]/g, ''));
      }
    }
  }

  const onCvcBlur = (e) => {
    if (e.target.value !== undefined) {
      if (e.target.value.length > 0) {
        setCardDetailsMissing(false);
        setCvcMissing(false);
        if (e.target.value.length < 3) {
          setCvcError(true);
          setCvcErrorMsg('');
          if (e.target.value.length > 0 && e.target.value.length < 3) {
            setCvcErrorMsg('Your card\'s security code is incomplete');
          }
        }
      } else {
        setCvcMissing(true);
      }
    }
    checkIfComplete();
  }

  const onNameChanged = (e) => {
    setNameMissing(false);
    setNameError(false);
    setNameErrorMsg('');
    setName(StringUtils.sanitize(e.target.value));
    checkIfComplete();
  }

  const onSaveInfoChanged = (e) => {
    console.log('save_info', e.target.checked)
    setSaveInfoChecked(e.target.checked);
  }

  const onSaveInfoCountryCodeChanged = (e) => {
    setSaveInfoPhoneCountry(e.target.value);
  }

  const onSaveInfoPhoneChanged = async (e) => {
    setSaveInfoPhoneMissing(false);
    setSaveInfoPhoneError(undefined);
    setSaveInfoPhone(new AsYouType(saveInfoPhoneCountry).input(e.target.value));
    if (isValidPhoneNumber(e.target.value, saveInfoPhoneCountry)) {
      //console.log('valid phone');
      const phoneNumber = parsePhoneNumber(e.target.value, saveInfoPhoneCountry);
      if (phoneNumber) {
        setSaveInfoPhoneLoading(true);
        setPhoneVerifyModalVisible(true);
        const requestRes = await Api.requestOtp(phoneNumber.number.replace('+', ''));
        if (requestRes.status === 200) {
          setOtpRefId(requestRes.data.id);
        }
      } else {
        setSaveInfoPhoneError('Your mobile number is invalid');
      }
    } else {
      //console.log('invalid phone');
      setSaveInfoPhoneError('Your mobile number is invalid');
    }
  }

  const onSaveInfoPhoneBlur = (e) => {
    if (e.target.value !== undefined) {
      if (e.target.value.length > 0) {
        setSaveInfoPhoneMissing(false);
        if (validatePhoneNumberLength(e.target.value, saveInfoPhoneCountry) === 'TOO_SHORT') {
          setSaveInfoPhoneError('Your mobile number is incomplete');
        }
      } else {
        setSaveInfoPhoneMissing(true);
        setSaveInfoPhoneError(undefined);
      }
    }
  }

  const closePhoneVerifyModalHandler = () => {
    setSaveInfoPhoneLoading(false);
    setSaveInfoPhoneVerified(false);
    setPhoneVerifyModalVisible(false);
  }

  const verifyPhoneHandler = async () => {
    if (saveInfoPhone) {
      if (isValidPhoneNumber(saveInfoPhone, saveInfoPhoneCountry)) {
        const phoneNumber = parsePhoneNumber(saveInfoPhone, saveInfoPhoneCountry);
        if (phoneNumber) {
          setSaveInfoPhoneLoading(true);
          setPhoneVerifyModalVisible(true);
          const requestRes = await Api.requestOtp(phoneNumber.number.replace('+', ''));
          if (requestRes.status === 200) {
            setOtpRefId(requestRes.data.id);
          }
        } else {
          setSaveInfoPhoneError('Your mobile number is invalid');
        }
      } else {
        setSaveInfoPhoneError('Your mobile number is invalid');
      }
    }
  }

  const phoneVerifiedHandler = () => {
    setSaveInfoPhoneLoading(false);
    setSaveInfoPhoneVerified(true);
    setPhoneVerifyModalVisible(false);
  }

  const changeSaveInfoPhoneHandler = () => {
    setSaveInfoPhoneVerified(false);
    setSaveInfoPhone(undefined);
  }

  const onShowLinkWithMagpieInfo = (e) => {
    e.preventDefault();
    setLinkWithMagpieInfoVisible(true);
  }

  const closeSaveInfoMoreModalHandler = (e) => {
    e.preventDefault();
    setLinkWithMagpieInfoVisible(false);
  }

  const onNameBlur = (e) => {
    if (billing || paymentMethod === 'card') {
      if (e.target.value !== undefined) {
        if (e.target.value.length > 0) {
          setNameMissing(false);
        } else {
          setNameMissing(true);
        }
      } else {
        setNameMissing(true);
      }
    } else {
      setNameMissing(false);
    }
  }

  const onShippingCountryChanged = (e) => {
    console.log('change country', e.target.value);
    const country = e.target.value;
    setShippingCountry(country);
    if (CountryUtils.hasState(country)) {
      if (shippingAddressState && shippingAddressState !== 'true') {
        const states = CountryUtils.getStates(country);
        console.log('states', states);
        let found = false;
        for (let i = 0; i < states.length; i++) {
          if (states[i].name === shippingAddressState) {
            found = true;
            break;
          }
        }
        if (!found) {
          console.log(`${shippingAddressState} not found in ${country} states`)
          setShippingAddressState('');
        }
      }
    }
  }

  const onShippingNameChanged = (e) => {
    setShippingName(StringUtils.sanitize(e.target.value));
    if (sameBillingAsShipping) {
      setNameMissing(false);
      setNameError(false);
      setNameErrorMsg('');
      setName(StringUtils.sanitize(e.target.value));
    }
    if (e.target.value.length === 0) {
      setShippingNameMissing(true);
    } else {
      setShippingNameMissing(false);
      if (shippingDetailsMissing) {
        checkShippingDetails();
      }
    }
  }

  const onShippingAddressLine1Changed = (e) => {
    setShippingAddressLine1(StringUtils.sanitize(e.target.value));
    if (e.target.value.length === 0) {
      setShippingAddressLine1Missing(true);
    } else {
      setShippingAddressLine1Missing(false);
      if (shippingDetailsMissing) {
        checkShippingDetails();
      }
    }
  }

  const onShippingAddressLine2Changed = (e) => {
    setShippingAddressLine2(StringUtils.sanitize(e.target.value));
  }

  const onShippingAddressSuburbChanged = (e) => {
    setShippingAddressSuburb(StringUtils.sanitize(e.target.value));
    if (e.target.value.length === 0) {
      setShippingAddressSuburbMissing(true);
    } else {
      setShippingAddressSuburbMissing(false);
      if (shippingDetailsMissing) {
        checkShippingDetails();
      }
    }
  }

  const onShippingAddressCityChanged = (e) => {
    setShippingAddressCity(StringUtils.sanitize(e.target.value));
    if (e.target.value.length === 0) {
      setShippingAddressCityMissing(true);
    } else {
      setShippingAddressCityMissing(false);
      if (shippingDetailsMissing) {
        checkShippingDetails();
      }
    }
  }

  const onShippingAddressPostalChanged = (e) => {
    setShippingAddressPostalCode(StringUtils.sanitize(e.target.value));
    if (e.target.value.length === 0) {
      setShippingAddressPostalMissing(true);
    } else {
      setShippingAddressPostalMissing(false);
      if (shippingDetailsMissing) {
        checkShippingDetails();
      }
    }
  }

  const onShippingAddressStateChanged = (e) => {
    setShippingAddressState(e.target.value);
    if (CountryUtils.hasState(shippingCountry)) {
      if (e.target.value === 'true') {
        setShippingAddressStateMissing(true);
      } else {
        setShippingAddressStateMissing(false);
        if (shippingDetailsMissing) {
          checkShippingDetails();
        }
      }
    }
  }

  const checkShippingDetails = () => {
    setTimeout(() => {
      if (!shippingName && 
        !shippingAddressLine1 && 
        (CountryUtils.hasSuburb(shippingCountry) && !shippingAddressSuburb) &&
        (CountryUtils.hasCity(shippingCountry) && !shippingAddressCity) &&
        (CountryUtils.hasState(shippingCountry) && (!shippingAddressState || shippingAddressState === 'true')) &&
        (CountryUtils.hasPostal(shippingCountry) && !shippingAddressPostalCode)) {
          setShippingDetailsMissing(true);
      } else {
        setShippingDetailsMissing(false);
        setShippingNameMissing(!shippingName);
        setShippingAddressLine1Missing(!shippingAddressLine1);
        setShippingAddressSuburbMissing(CountryUtils.hasSuburb(shippingCountry) && !shippingAddressSuburb);
        setShippingAddressCityMissing(CountryUtils.hasCity(shippingCountry) && !shippingAddressCity);
        setShippingAddressStateMissing(CountryUtils.hasState(shippingCountry) && (!shippingAddressState || shippingAddressState === 'true'));
        setShippingAddressPostalMissing(CountryUtils.hasPostal(shippingCountry) && !shippingAddressPostalCode);
      }
    }, 300);
  }

  const onUseShippingAsBillingChecked = (e) => {
    setSameBillingAsShipping(e.target.checked);
    if (e.target.checked) {
      setName(shippingName);
      setBillingCountry(shippingCountry);
      if (billing) {
        setBillingAddressLine1(shippingAddressLine1);
        setBillingAddressLine2(shippingAddressLine2);
        setBillingAddressSuburb(shippingAddressSuburb);
        setBillingAddressCity(shippingAddressCity);
        setBillingAddressPostalCode(shippingAddressPostalCode);
        setBillingAddressState(shippingAddressState)
      }
    } else {
      console.log('not same with shipping');
      console.log('billing', getBillingAddress())
      setName(name);
      setBillingCountry(billingCountry);
      if (billing) {
        setBillingAddressLine1(billingAddressLine1);
        setBillingAddressLine2(billingAddressLine2);
        setBillingAddressSuburb(billingAddressSuburb);
        setBillingAddressCity(billingAddressCity);
        setBillingAddressPostalCode(billingAddressPostalCode);
        setBillingAddressState(billingAddressState);
      }
    }
    
  }

  const onBillingCountryChanged = (e) => {
    const country = e.target.value;
    setBillingCountry(country);
    if (CountryUtils.hasState(country)) {
      if (billingAddressState && billingAddressState !== 'true') {
        const states = CountryUtils.getStates(country);
        let found = false;
        for (let i = 0; i < states.length; i++) {
          if (states[i].name === billingAddressState) {
            found = true;
            break;
          }
        }
        if (!found) {
          setBillingAddressState('');
        }
      }
    }
  }

  const onBillingAddressLine1Changed = (e) => {
    setBillingAddressLine1(StringUtils.sanitize(e.target.value));
    if (e.target.value.length === 0) {
      setBillingAddressLine1Missing(true);
    } else {
      setBillingAddressLine1Missing(false);
      if (billingDetailsMissing) {
        checkBillingDetails();
      }
    }
  }

  const onBillingAddressLine2Changed = (e) => {
    setBillingAddressLine2(StringUtils.sanitize(e.target.value));
  }

  const onBillingAddressSuburbChanged = (e) => {
    setBillingAddressSuburb(StringUtils.sanitize(e.target.value));
  }

  const onBillingAddressCityChanged = (e) => {
    setBillingAddressCity(StringUtils.sanitize(e.target.value));
    if (e.target.value.length === 0) {
      setBillingAddressCityMissing(true);
    } else {
      setBillingAddressCityMissing(false);
      if (billingDetailsMissing) {
        checkBillingDetails();
      }
    }
  }

  const onBillingAddressPostalChanged = (e) => {
    setBillingAddressPostalCode(StringUtils.sanitize(e.target.value));
    if (e.target.value.length === 0) {
      setBillingAddressPostalMissing(true);
    } else {
      setBillingAddressPostalMissing(false);
      if (billingDetailsMissing) {
        checkBillingDetails();
      }
    }
  }

  const onBillingAddressStateChanged = (e) => {
    setBillingAddressState(e.target.value);
    if (CountryUtils.hasState(billingCountry)) {
      if (e.target.value === 'true') {
        setBillingAddressStateMissing(true);
      } else {
        setBillingAddressStateMissing(false);
        if (billingDetailsMissing) {
          checkBillingDetails();
        }
      }
    }
  }

  const checkBillingDetails = () => {
    setTimeout(() => {
      setNameMissing(!name);

      if (!billingAddressLine1 && 
        (CountryUtils.hasSuburb(billingCountry) && !billingAddressSuburb) &&
        (CountryUtils.hasCity(billingCountry) && !billingAddressCity) &&
        (CountryUtils.hasState(billingCountry) && (!billingAddressState || billingAddressState === 'true')) &&
        (CountryUtils.hasPostal(billingCountry) && !billingAddressPostalCode)) {
          setBillingDetailsMissing(true);
      } else {
        setBillingDetailsMissing(false);
        setBillingAddressLine1Missing(!billingAddressLine1);
        setBillingAddressCityMissing(CountryUtils.hasCity(billingCountry) && !billingAddressCity);
        if (CountryUtils.hasState(billingCountry)) {
          if (!billingAddressState || billingAddressState === 'true') {
            setBillingAddressStateMissing(true);
          } else {
            setBillingAddressStateMissing(false);
          }
        } else {
          setBillingAddressStateMissing(false);
        }
        
        //setBillingAddressStateMissing(CountryUtils.hasState(billingCountry) && (!billingAddressState || billingAddressState === 'true'));
        setBillingAddressPostalMissing(CountryUtils.hasPostal(billingCountry) && !billingAddressPostalCode);
      }
    }, 400);
  }

  const onWalletChanged = (e) => {
    setWalletMissing(false);
    setWalletError(false);
    setWalletErrorMsg('');
    setWallet(e.target.value);

    // TODO: Remove when other wallets are working
    if (!allowedWallets.includes(e.target.value)) {
      setWalletError(true);
      setWalletErrorMsg(`${CardUtils.getWalletName(e.target.value)} is not yet supported`);
    }
  }

  const onBankChanged = (e) => {
    setBankMissing(false);
    setBankError(false);
    setBankErrorMsg('');
    setBank(e.target.value);

    // TODO: Remove when other banks are working
    if (!allowedBanks.includes(e.target.value)) {
      setBankError(true);
      setBankErrorMsg(`${CardUtils.getBankName(e.target.value)} is not yet supported`);
    }
  }

  const isShippingAddressValid = () => {
    let isValid = true;
    if (!shippingName) {
      isValid = false;
    }

    if (!shippingCountry) {
      isValid = false;
    } else {
      if (!shippingAddressLine1) {
        isValid = false;
      }
  
      if (CountryUtils.hasCity(shippingCountry) && !shippingAddressCity) {
        isValid = false;
      }
  
      if (CountryUtils.hasSuburb(shippingCountry) && !shippingAddressSuburb) {
        isValid = false;
      }
  
      if (CountryUtils.hasState(shippingCountry)) {
        if (!shippingAddressState || shippingAddressState === 'true') {
          isValid = false;
        } else {
          const states = CountryUtils.getStates(shippingCountry);
          let found = false;
          for (let i = 0; i < states.length; i++) {
            if (states[i].name === shippingAddressState) {
              found = true;
              break;
            }
          }
          if (!found) {
            isValid = false;
          }
        }
      }

      if (CountryUtils.hasPostal(shippingCountry) && !shippingAddressPostalCode) {
        isValid = false;
      }
    }

    return isValid;
  }

  const isBillingAddressValid = () => {
    let isValid = true;

    if (!billingCountry) {
      isValid = false;
    } else {
      if (!billingAddressLine1) {
        isValid = false;
      }
  
      if (CountryUtils.hasCity(billingCountry) && !billingAddressCity) {
        isValid = false;
      }
  
      if (CountryUtils.hasSuburb(billingCountry) && !billingAddressSuburb) {
        isValid = false;
      }
  
      if (CountryUtils.hasState(billingCountry)) {
        if (!billingAddressState || billingAddressState === 'true') {
          isValid = false;
        } else {
          const states = CountryUtils.getStates(billingCountry);
          let found = false;
          for (let i = 0; i < states.length; i++) {
            if (states[i].name === billingAddressState) {
              found = true;
              break;
            }
          }
          if (!found) {
            isValid = false;
          }
        }
      }

      if (CountryUtils.hasPostal(billingCountry) && !billingAddressPostalCode) {
        isValid = false;
      }
    }

    return isValid;
  }

  const getPhoneNumber = () => {
    if (phone) {
      const phoneNumber = parsePhoneNumber(phone, countryCode);
      if (phoneNumber) {
        return phoneNumber.number;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  const checkIfComplete = () => {
    if (useSavedInfo && selectedSavedInfo) {
      if (requestName && !name) {
        setComplete(false);
        return;
      }

      if (requestPhone && !phone) {
        setComplete(false);
        return;
      }

      if (requestPhone && !isValidPhoneNumber(phone, countryCode)) {
        setComplete(false);
        return;
      }

      /* if (!!name) {
        if (requestPhone) {
          // console.log('phone', phone);
          if (!phone) {
            setComplete(false);
            return;
          } else {
            if (!isValidPhoneNumber(phone, countryCode)) {
              setComplete(false);
              return;
            }
          }
        }

        if (shipping) {
          if (isShippingAddressValid()) {
            if (billing) {
              if (sameBillingAsShipping) {
                setComplete(true);
              } else {
                setComplete(isBillingAddressValid());
              }
            } else {
              setComplete(true);
            }
          } else {
            setComplete(false);
          }
        } else {
          if (billing) {
            setComplete(isBillingAddressValid());
          } else {
            setComplete(true);
          }
        }
      } else {
        setComplete(false);
        return
      } */
      
    } else {
      if (!email) {
        setComplete(false);
        return;
      }

      if (!cardNumber) {
        setComplete(false);
        return;
      }

      if (!expiry) {
        setComplete(false);
        return;
      }

      if (!cvc) {
        setComplete(false);
        return;
      }

      if (!name) {
        setComplete(false);
        return;
      }

      if (!billingCountry) {
        setComplete(false);
        return;
      }

      if (!CardUtils.isCompleteEmail(email)) {
        setComplete(false);
        return;
      }

      if (!CardUtils.isValidEmail(email)) {
        setComplete(false);
        return;
      }

      if (!CardUtils.isValidCardNumber(cardNumber)) {
        setComplete(false);
        return;
      }

      if (!CardUtils.isValidExpiry(expiry).valid) {
        setComplete(false);
        return;
      }

      if (!CardUtils.isValidCvc(cvc)) {
        setComplete(false);
        return;
      }

      if (requestPhone && !phone) {
        setComplete(false);
        return;
      }

      if (requestPhone && !isValidPhoneNumber(phone, countryCode)) {
        setComplete(false);
        return;
      }

      if (saveInfoChecked && !saveInfoPhone) {
        setComplete(false);
        return;
      }

      if (saveInfoChecked && !saveInfoPhoneVerified) {
        setComplete(false);
        return;
      }
      /* if (!!email && !!cardNumber && !!expiry && !!cvc && !!name && !!billingCountry &&
        CardUtils.isValidEmail(email) && CardUtils.isValidCardNumber(cardNumber) &&
        CardUtils.isValidExpiry(expiry) && CardUtils.isValidCvc(cvc)) {
          // if requirePhone is enabled, check if phone is supplied
          if (requestPhone) {
            // console.log('phone', phone);
            if (!phone) {
              setComplete(false);
              return;
            } else {
              if (!isValidPhoneNumber(phone, countryCode)) {
                setComplete(false);
                return;
              }
            }
          }

          if (saveInfoChecked) {
            if (!saveInfoPhone) {
              setComplete(false);
              return;
            } else {
              if (!saveInfoPhoneVerified) {
                setComplete(false);
                return;
              }
            }
          }

          // if shipping is enabled check if shipping details is supplied
          // if billing is enabled and not same with shipping check if billing details is supplied
          if (shipping) {
            if (isShippingAddressValid()) {
              if (billing) {
                if (sameBillingAsShipping) {
                  setComplete(true);
                } else {
                  setComplete(isBillingAddressValid());
                }
              } else {
                setComplete(true);
              }
            } else {
              setComplete(false);
            }
          } else {
            if (billing) {
              setComplete(isBillingAddressValid());
            } else {
              setComplete(true);
            }
          }
      } else {
        setComplete(false);
      } */
    }

    if (shipping && !isShippingAddressValid()) {
      setComplete(false);
      return;
    }

    if (billing && shipping && sameBillingAsShipping && !isShippingAddressValid()) {
      setComplete(false);
      return;
    }

    if (billing && !sameBillingAsShipping && !isBillingAddressValid()) {
      setComplete(false);
      return;
    }

    setComplete(true);
  }

  const checkIfCompleteWallet = () => {
    //resetErrors();
    if (!email) {
      setComplete(false);
      return;
    }

    if (!CardUtils.isCompleteEmail(email)) {
      setComplete(false);
      return;
    }

    if (!CardUtils.isValidEmail(email)) {
      setComplete(false);
      return;
    }

    if (!wallet) {
      setComplete(false);
      return;
    }

    if (requestPhone && !phone) {
      setComplete(false);
      return;
    }

    if (requestPhone && !isValidPhoneNumber(phone, countryCode)) {
      setComplete(false);
      return;
    }

    if (requestName && !name) {
      setComplete(false);
      return;
    }


    if (shipping && !isShippingAddressValid()) {
      setComplete(false);
      return;
    }

    if (billing && shipping && sameBillingAsShipping && !isShippingAddressValid()) {
      setComplete(false);
      return;
    }

    if (billing && !sameBillingAsShipping && !isBillingAddressValid()) {
      setComplete(false);
      return;
    }

    setComplete(true);

    /* if (!!email && !!wallet) {
      if (!emailError && !walletError) {
        // if requirePhone is enabled, check if phone is supplied
        if (requestPhone) {
          if (!phone) {
            setComplete(false);
            return;
          } else {
            if (!isValidPhoneNumber(phone, countryCode)) {
              setComplete(false);
              return;
            }
          }
        }

        // if shipping is enabled check if shipping details is supplied
        // if billing is enabled and not same with shipping check if billing details is supplied
        if (shipping) {
          if (isShippingAddressValid()) {
            if (billing) {
              if (sameBillingAsShipping) {
                setComplete(true);
              } else {
                setComplete(isBillingAddressValid());
              }
            } else {
              setComplete(true);
            }
          } else {
            setComplete(false);
          }
        } else {
          if (billing) {
            setComplete(isBillingAddressValid());
          } else {
            setComplete(true);
          }
        }
      } else {
        setComplete(false);
      }
    } else {
      setComplete(false);
    } */
  }

  const checkIfCompleteBank = () => {
    if (!email) {
      setComplete(false);
      return;
    }

    if (!CardUtils.isCompleteEmail(email)) {
      setComplete(false);
      return;
    }

    if (!CardUtils.isValidEmail(email)) {
      setComplete(false);
      return;
    }

    if (!bank) {
      setComplete(false);
      return;
    }

    if (requestPhone && !phone) {
      setComplete(false);
      return;
    }

    if (requestPhone && !isValidPhoneNumber(phone, countryCode)) {
      setComplete(false);
      return;
    }

    if (shipping && !isShippingAddressValid()) {
      setComplete(false);
      return;
    }

    if (billing && shipping && sameBillingAsShipping && !isShippingAddressValid()) {
      setComplete(false);
      return;
    }

    if (billing && !sameBillingAsShipping && !isBillingAddressValid()) {
      setComplete(false);
      return;
    }

    setComplete(true);

    //resetErrors();
    /* if (!!email && !!bank) {
      if (!emailError && !bankError) {
        // if requirePhone is enabled, check if phone is supplied
        if (requestPhone) {
          if (!phone) {
            setComplete(false);
            return;
          } else {
            if (!isValidPhoneNumber(phone, countryCode)) {
              setComplete(false);
              return;
            }
          }
        }

        // if shipping is enabled check if shipping details is supplied
        // if billing is enabled and not same with shipping check if billing details is supplied
        if (shipping) {
          if (isShippingAddressValid()) {
            if (billing) {
              if (sameBillingAsShipping) {
                setComplete(true);
              } else {
                setComplete(isBillingAddressValid());
              }
            } else {
              setComplete(true);
            }
          } else {
            checkShippingDetails();
            setComplete(false);
          }
        } else {
          if (billing) {
            setComplete(isBillingAddressValid());
          } else {
            setComplete(true);
          }
        }
      } else {
        setComplete(false);
      }
    } else {
      setComplete(false);
    } */
  }

  const getCustomerData = async () => {
    let customerData = null;
    if (saveInfoChecked) {
      //save mobile number to customer object
      const phoneNumber = parsePhoneNumber(saveInfoPhone, saveInfoPhoneCountry);
      if (phoneNumber) {
        const customerRes = await Api.getCustomerByEmail(secretKey, email);
        if (customerRes.status === 200) {
          if (customerRes.data.mobile_number !== phoneNumber.number.replace('+', '')) {
            const updateCustomerRes = await Api.updateCustomer(secretKey, version, customerRes.data.id, phoneNumber.number);
            if (updateCustomerRes.status === 200) {
              customerData = updateCustomerRes.data;
            }
          } else {
            customerData = customerRes.data;
          }
        } else {
          const createCustomerRes = await Api.createCustomer(secretKey, version, email, name, phoneNumber.number);
          if (createCustomerRes.status === 200) {
            customerData = createCustomerRes.data;
          }
        }
      }
    } else if (updateSavedInfo) {
      customerData = customerFound;
    }

    return customerData;
  }

  const saveCustomerPaymentInfo = async (customerData) => {
    if (customerData) {
      if (saveRetries < 3) {
        setSaveRetries(saveRetries + 1);
      
        let cardData = {
          name: StringUtils.sanitize(name),
          number: cardNumber.replace(/\s/g, ''),
          exp_month: expiry.split('/')[0],
          exp_year: `20${expiry.split('/')[1].replace(/\s/g, '')}`,
          cvc,
          address_country: billingCountry,
        };

        if (customerData.sources.find((s) => s.type === 'card' && s.card.last4 === cardData.number.slice(-4) && s.card.exp_month === cardData.exp_month && s.card.exp_year === cardData.exp_year && s.card.brand === CardUtils.getBrand(cardData.number)) === undefined) {
          const sourceRes = await Api.createSource(
            publicKey,
            version,
            total,
            currency,
            'card',
            {
              successUrl: `${BASE_URL}/card/success?session_id=${session.id}`,
              failUrl: `${BASE_URL}/card/fail?session_id=${session.id}`,
              notifyUrl: `${BASE_URL}/api/v2/sessions/${session.id}/notify`,
            },
            cardData,
          );

          if (sourceRes.status === 200 || sourceRes.status === 201) {
            const attachRes = await Api.attachCustomerSource(secretKey, customerData.id, sourceRes.data.id);

            if (attachRes.status === 200) {
              console.log(`Attached fund source ${sourceRes.data.id} to customer ${customerData.id}`);
              setSaveRetries(0);
              return true;
            } else {
              console.log('Failed to attach fund source to customer. Trying again...');
              await saveCustomerPaymentInfo(customerData);
            }
          } else {
            await saveCustomerPaymentInfo(customerData);
          }
        } else {
          console.log('Payment info is already saved.');
          return false;
        }
      } else {
        console.log('Failed to attach fund source to customer after 3 retries. Skipping attach source.');
        return false;
      }
    } else {
      return false;
    }
  }

  const updatePaymentSession = async (customer, chargeData) => {
    console.log('update customer', customer);
    const updateRes = await Api.updateSessionPayment(
      secretKey,
      version,
      session.id,
      customer,
      chargeData,
      shipping ? getShippingAddress() : null,
      getBillingAddress()
    );

    if (updateRes.status === 200) {
      setCardModalVisible(false);
      setLoading(false);
      setSuccess(true);

      timerRef.current = setTimeout(() => {
        onSuccessPayment(session.id);
      }, 800);
    } else {
      setCardNumberError(true);
      setCardNumberErrorMsg(updateRes.data.message);
      setSavedInfoErrorMsg(updateRes.data.message);
      setLoading(false);
      setComplete(false);
      setCardModalVisible(false);
    }
  }

  const onQuickPayClick = async (e) => {
    e.preventDefault();
    try {
      resetErrors();
      if (!loading && !success) {
        if (email) {
          if (requestPhone) {
            if (!phone) {
              setPhoneMissing(true);
              return;
            } else {
              if (!isValidPhoneNumber(phone, countryCode)) {
                setPhoneError(true);
                setPhoneErrorMsg('Your mobile number is invalid');
                return;
              }
            }
          }

          if (shipping) {
            if (isShippingAddressValid()) {
              if (billing) {
                if (sameBillingAsShipping) {
                  if (quickPay === 'wallet') {
                    await quickPayWallet();
                  } else if (quickPay === 'bank') {
                    await quickPayBank();
                  }
                } else {
                  if (isBillingAddressValid()) {
                    if (quickPay === 'wallet') {
                      await quickPayWallet();
                    } else if (quickPay === 'bank') {
                      await quickPayBank();
                    }
                  } else {
                    checkBillingDetails();
                  }
                }
              } else {
                if (quickPay === 'wallet') {
                  await quickPayWallet();
                } else if (quickPay === 'bank') {
                  await quickPayBank();
                }
              }
            } else {
              checkShippingDetails();
            }
          } else {
            if (billing) {
              if (isBillingAddressValid()) {
                if (quickPay === 'wallet') {
                  await quickPayWallet();
                } else if (quickPay === 'bank') {
                  await quickPayBank();
                }
              } else {
                checkBillingDetails();
              }
            } else {
              if (quickPay === 'wallet') {
                await quickPayWallet();
              } else if (quickPay === 'bank') {
                await quickPayBank();
              }
            }
          }
          
        } else {
          setEmailMissing(true);
        }
      }
    } catch (e) {
      console.log('error', e);
      Sentry.captureException(e);
      setError('We are unable to process this transaction at this time.');
      setErrorModalVisible(true);
      setQuickPayLoading(false);
      setIsQuickPay(false);
    }
  }

  const quickPayWallet = async () => {
    try {
      if (allowedWallets.includes(quickPayMethod.toLowerCase())) {
        setIsQuickPay(true);
        setQuickPayLoading(true);
        //console.log('params', data);
  
        const customer = {
          id: sessionCustomer.id ? sessionCustomer.id : null,
          name: name ? name : sessionCustomer.name,
          email: email ? email : sessionCustomer.email,
          phone: requestPhone ? getPhoneNumber() : undefined,
        };
        setWalletCustomer(customer);
  
        if (wallet.toLowerCase() === 'paymaya' || wallet.toLowerCase() === 'gcash') { 
          const updateSessionRes = await Api.updateSession(
            secretKey,
            version,
            session.id,
            customer,
            null,
            shipping ? getShippingAddress() : null,
            getBillingAddress(),
          )
          //console.log('update', updateSessionRes);
          if (updateSessionRes.status === 200) {
            const data = {
              payment_method: 'wallet',
              // payment_type: 'paymaya',
              payment_type: wallet.toLowerCase(),
              is_quick_pay: true
            };

            const redirectUrls = {
              successUrl: `${BASE_URL}/${session.id}?d=${Buffer.from(encodeURIComponent(JSON.stringify(data)), 'utf8').toString('base64')}`,
              failUrl: `${BASE_URL}/${session.id}?d=${Buffer.from(encodeURIComponent(JSON.stringify(data)), 'utf8').toString('base64')}`,
              notifyUrl: `${BASE_URL}/api/v2/sessions/${session.id}/notify`,
            };

            const sourceRes = await Api.createSource(
              publicKey,
              version,
              total,
              currency,
              version === '2' ? wallet.toLowerCase() : wallet.toUpperCase(),
              redirectUrls,
              /* {
                successUrl: `${window.location.origin}/${session.id}?d=${btoa(unescape(encodeURIComponent(JSON.stringify(data))))}`,
                failUrl: `${window.location.origin}/${session.id}?d=${btoa(unescape(encodeURIComponent(JSON.stringify(data))))}`
              }, */
              undefined,
              {
                name: customer.name ? StringUtils.sanitize(customer.name) : customer.email,
                mobile_number: customer.phone ? StringUtils.sanitize(customer.phone) : null,
                address_line1: StringUtils.sanitize(billingAddressLine1 ? billingAddressLine1 : shippingAddressLine1 ? shippingAddressLine1 : null),
                address_line2: StringUtils.sanitize(billingAddressLine2 ? billingAddressLine2 : shippingAddressLine2 ? shippingAddressLine2 : null),
                address_city: StringUtils.sanitize(billingAddressCity ? billingAddressCity : shippingAddressCity ? shippingAddressCity : null),
                address_state: StringUtils.sanitize(billingAddressState ? billingAddressState : shippingAddressState ? shippingAddressState : null),
                address_country: StringUtils.sanitize(billingCountry ? billingCountry : shippingCountry ? shippingCountry : null),
                address_zip: StringUtils.sanitize(billingAddressPostalCode ? billingAddressPostalCode : shippingAddressPostalCode ? shippingAddressPostalCode : null)
              }
            );
          
            //console.log('source', sourceRes);
            if (sourceRes.status === 200 || sourceRes.status === 201) {
              const chargeRes = await Api.createCharge(
                secretKey,
                version,
                total,
                currency,
                sourceRes.data.id,
                StringUtils.truncate(description, 200, true).replace(/[^a-zA-Z0-9 ]/g, ''),
                StringUtils.truncate(merchant, 22, false).replace(/[^a-zA-Z0-9 ]/g, ''),
                true,
                undefined,
                { ...metadata, session_id: session.id },
                undefined,
              );
              //console.log('charge', chargeRes);
              if (chargeRes.status === 201) {
                if (version === '2') {
                  if (chargeRes.data.action.type === 'redirect_to_url') {
                    window.location.href = chargeRes.data.action.url;
                  } else {
                    //console.log('Unsupported action type:', chargeRes.data.action.type);
                    setError(`Unsupported action: ${chargeRes.data.action.type}`);
                    setErrorModalVisible(true);
                    setQuickPayLoading(false);
                    setIsQuickPay(false);
                  }
                } else {
                  window.location.href = chargeRes.data.checkout_url;
                }
                
              } else {
                if (version === '2') {
                  setError(Array.isArray(chargeRes.data.detail) ? chargeRes.data.detail[0].msg : chargeRes.data.detail || 'We are unable to process this transaction at this time.');
                } else {
                  setError(chargeRes.data.message || 'We are unable to process this transaction at this time.');
                }
                setErrorModalVisible(true);
                setQuickPayLoading(false);
                setIsQuickPay(false);
              }
            } else {
              if (version === '2') {
                setError(Array.isArray(sourceRes.data.detail) ? sourceRes.data.detail[0].msg : sourceRes.data.detail || 'We are unable to process this transaction at this time.');
              } else {
                setError(sourceRes.data.message || 'We are unable to process this transaction at this time.');
              }
              setErrorModalVisible(true);
              setQuickPayLoading(false);
              setIsQuickPay(false);
            }
          } else {
            setError(updateSessionRes.data.message || 'We are unable to process this transaction at this time.');
            setErrorModalVisible(true);
            setQuickPayLoading(false);
            setIsQuickPay(false);
          }
        } else {
          const sourceRes = await Api.createSource(
            publicKey,
            version,
            total,
            currency,
            version === '2' ? wallet.toLowerCase() : wallet.toUpperCase(),
            {
              successUrl: `${BASE_URL}/wallet/success?session_id=${session.id}`,
              failUrl: `${BASE_URL}/wallet/fail?session_id=${session.id}`,
              notifyUrl: `${BASE_URL}/api/v2/sessions/${session.id}/notify`,
            },
            undefined,
            {
              name: customer.name ? StringUtils.sanitize(customer.name) : customer.email,
              mobile_number: customer.phone ? StringUtils.sanitize(customer.phone) : null,
              address_line1: StringUtils.sanitize(billingAddressLine1 ? billingAddressLine1 : shippingAddressLine1 ? shippingAddressLine1 : null),
              address_line2: StringUtils.sanitize(billingAddressLine2 ? billingAddressLine2 : shippingAddressLine2 ? shippingAddressLine2 : null),
              address_city: StringUtils.sanitize(billingAddressCity ? billingAddressCity : shippingAddressCity ? shippingAddressCity : null),
              address_state: StringUtils.sanitize(billingAddressState ? billingAddressState : shippingAddressState ? shippingAddressState : null),
              address_country: StringUtils.sanitize(billingCountry ? billingCountry : shippingCountry ? shippingCountry : null),
              address_zip: StringUtils.sanitize(billingAddressPostalCode ? billingAddressPostalCode : shippingAddressPostalCode ? shippingAddressPostalCode : null)
            }
          );
          if (sourceRes.status === 200 || sourceRes.status === 201) {
            const chargeRes = await Api.createCharge(
              secretKey,
              version,
              total,
              currency,
              sourceRes.data.id,
              StringUtils.truncate(description, 200, true).replace(/[^a-zA-Z0-9 ]/g, ''),
              StringUtils.truncate(merchant, 22, false).replace(/[^a-zA-Z0-9 ]/g, ''),
              true,
              undefined,
              { ...metadata, session_id: session.id },
              undefined,
            );
            //console.log('charge', chargeRes);
            if (chargeRes.status === 201) {
              if (version === '2') {
                if (chargeRes.data.action.type === 'redirect_to_url') {
                  setWalletFrameUrl(chargeRes.data.action.url);
                } else {
                  //console.log('Unsupported action type:', chargeRes.data.action.type);
                  setError(`Unsupported action: ${chargeRes.data.action.type}`);
                  setErrorModalVisible(true);
                  setQuickPayLoading(false);
                  setIsQuickPay(false);
                }
              } else {
                setWalletFrameUrl(chargeRes.data.checkout_url);
              }
  
              setWalletFrameLoading(true);
              setWalletModalVisible(true);
            } else {
              if (version === '2') {
                setError(Array.isArray(chargeRes.data.detail) ? chargeRes.data.detail[0].msg : chargeRes.data.detail || 'We are unable to process this transaction at this time.');
              } else {
                setError(chargeRes.data.message || 'We are unable to process this transaction at this time.');
              }
              setErrorModalVisible(true);
              setQuickPayLoading(false);
              setIsQuickPay(false);
            }
          } else {
            if (version === '2') {
              setError(Array.isArray(sourceRes.data.detail) ? sourceRes.data.detail[0].msg : sourceRes.data.detail || 'We are unable to process this transaction at this time.');
            } else {
              setError(sourceRes.data.message || 'We are unable to process this transaction at this time.');
            }
            setErrorModalVisible(true);
            setQuickPayLoading(false);
            setIsQuickPay(false);
          }
        }
      } else {
        setError(`${CardUtils.getWalletName(quickPayMethod)} is not yet supported`);
        setErrorModalVisible(true);
        setQuickPayLoading(false);
        setIsQuickPay(false);
      }
    } catch (e) {
      console.log('error', e);
      Sentry.captureException(e);
      setError('We are unable to process this transaction at this time.');
      setErrorModalVisible(true);
      setQuickPayLoading(false);
      setIsQuickPay(false);
    }
  }

  const quickPayBank = async () => {
    try {
      if (allowedBanks.includes(quickPayMethod.toLowerCase())) {
        setIsQuickPay(true);
        setQuickPayLoading(true);
        //console.log('params', data);
  
        let customer = {
          id: sessionCustomer.id ? sessionCustomer.id : null,
          name: name ? name : sessionCustomer.name,
          email: email ? email : sessionCustomer.email,
          phone: requestPhone ? getPhoneNumber() : undefined,
        };
        setBankCustomer(customer);
  
        if (quickPayMethod.toLowerCase() === 'bpi') {
          if (version === '2')  {
            const code = !!bankCode ? bankCode : CardUtils.getBankCode(quickPayMethod.toLowerCase());

            let customerRes = await Api.getCustomerByEmail(secretKey, customer.email);

            if (customerRes.status === 200) {
              customer = {
                id: customerRes.data.id,
                name: name ? name : customerRes.data.metadata ? customerRes.data.metadata.name ? customerRes.data.metadata.name : name : name,
                email: customerRes.data.email,
                phone: requestPhone ? getPhoneNumber() : undefined,
              };
              setBankCustomer(customer);
            } else {
              customerRes = await Api.createCustomer(
                secretKey,
                version,
                customer.email,
                customer.name || `${merchant} customer`,
                requestPhone ? getPhoneNumber() : undefined
              );

              if (customerRes.status === 201) {
                customer = {
                  id: customerRes.data.id,
                  name: customerRes.data.metadata.name,
                  email: customerRes.data.email,
                  phone: customerRes.data.mobile_number,
                };
                setBankCustomer(customer);
              } else {
                setError(customerRes.data.message);
                setErrorModalVisible(true);
                setQuickPayLoading(false);
                setIsQuickPay(false);
                return;
              }
            }

            const updateSessionRes = await Api.updateSession(
              secretKey,
              version,
              session.id,
              customer,
              null,
              shipping ? getShippingAddress() : null,
              getBillingAddress()
            );

            if (updateSessionRes.status === 200) {
              const data = {
                payment_method: 'bank',
                payment_type: 'bpi',
                is_quick_pay: true
              };

              const redirectUrl = `${BASE_URL}/${session.id}?d=${Buffer.from(encodeURIComponent(JSON.stringify(data)), 'utf8').toString('base64')}`;

              console.log({redirectUrl});

              const loginRes = await Api.createBankLogin(secretKey, '2', code, customer.id, redirectUrl);

              if (loginRes.status === 200) {
                if (loginRes.data.redirect) {
                  console.log('redirect', loginRes.data.redirect);
                  window.location = loginRes.data.redirect;
                } else {
                  setError('We are unable to process this transaction at this time.');
                  setErrorModalVisible(true);
                  setQuickPayLoading(false);
                  setIsQuickPay(false);
                  Sentry.captureException(new Error('Missing redirect in login response'), {
                    extra: { data: loginRes }
                  });
                  return;
                }
              } else {
                setError(Array.isArray(loginRes.data.detail) ? loginRes.data.detail[0].msg : loginRes.data.detail || 'We are unable to process this transaction at this time.');
                setErrorModalVisible(true);
                setQuickPayLoading(false);
                setIsQuickPay(false);
                Sentry.captureException(new Error('Failed to initiate BPI Login'), {
                  extra: { data: loginRes }
                });
                return;
              }
            } else {
              setError(updateSessionRes.data.message || 'We are unable to process this transaction at this time.');
              setErrorModalVisible(true);
              setQuickPayLoading(false);
              setIsQuickPay(false);
              return;
            }

          } else {
            if (customer.id === null) {
              const customerRes = await Api.createCustomer(
                secretKey,
                version,
                customer.email,
                customer.name || `${merchant} customer`,
              );
    
              if (customerRes.status === 201) {
                customer = {
                  id: customerRes.data.id,
                  name: customerRes.data.description,
                  email: customerRes.data.email,
                };
                setBankCustomer(customer);
              } else {
                setError(customerRes.data.message);
                setErrorModalVisible(true);
                setQuickPayLoading(false);
                setIsQuickPay(false);
                return;
              }
            }
    
            const code = !!bankCode ? bankCode : CardUtils.getBankCode(quickPayMethod.toLowerCase());

            let desc = description;
            if (code !== 'bpi2cash') {
              desc = StringUtils.truncate(desc, 32, false)
            }

            const data = `key=${secretKey}&merchant=${StringUtils.truncate(merchant, 22, false).replace(/[^a-zA-Z0-9 ]/g, '')}&total=${total}&currency=${currency}&livemode=${livemode}&description=${desc}`;
            
            const redirectUrl = `${BASE_URL}/bank/${quickPayMethod.toLowerCase()}/checkout?d=${Buffer.from(encodeURIComponent(data), 'utf8').toString('base64')}`;
            
            const bankRes = await Api.createBankLogin(
              secretKey,
              version,
              code,
              customer.id,
              redirectUrl,
              // `${window.location.origin}/bank/${quickPayMethod.toLowerCase()}/checkout?d=${btoa(unescape(encodeURIComponent(data)))}`
            );
            //console.log('bank', bankRes);
            if (bankRes.status === 200) {
              //console.log('bank url', bankRes.data.redirect);
              setBankFrameUrl(bankRes.data.redirect);
              setBankFrameLoading(true);
              setBankModalVisible(true);
            } else {
              setError(bankRes.data.message);
              setErrorModalVisible(true);
              setQuickPayLoading(false);
              setIsQuickPay(false);
            }
          }
        } else {
          // request URL from Brankas
          const code = !!bankCode ? bankCode : CardUtils.getBankCode(quickPayMethod.toLowerCase());
  
          const updateSessionRes = await Api.updateSession(
            secretKey,
            version,
            session.id,
            customer,
            null,
            shipping ? getShippingAddress() : null,
            getBillingAddress()
          )
          //console.log('update', updateSessionRes);
          if (updateSessionRes.status === 200) {
            const data = {
              payment_method: 'bank',
              payment_type: bank,
              is_quick_pay: false
            };

            const redirectUrls = {
              successUrl: `${BASE_URL}/${session.id}?d=${Buffer.from(encodeURIComponent(JSON.stringify(data)), 'utf8').toString('base64')}`,
              failUrl: `${BASE_URL}/${session.id}?d=${Buffer.from(encodeURIComponent(JSON.stringify(data)), 'utf8').toString('base64')}`,
              notifyUrl: `${BASE_URL}/api/v2/sessions/${session.id}/notify`,
            };

            const sourceRes = await Api.createSource(
              publicKey,
              version,
              total,
              currency,
              version === '2' && code === 'magpiebpi' ? 'bpi' : code,
              redirectUrls,
              /* {
                successUrl: `${window.location.origin}/${session.id}?d=${btoa(unescape(encodeURIComponent(JSON.stringify(data))))}`,
                failUrl: `${window.location.origin}/${session.id}?d=${btoa(unescape(encodeURIComponent(JSON.stringify(data))))}`
              }, */
              undefined,
              {
                name: customer.name ? StringUtils.sanitize(customer.name) : customer.email,
                mobile_number: customer.phone ? StringUtils.sanitize(customer.phone) : null,
                address_line1: StringUtils.sanitize(billingAddressLine1 ? billingAddressLine1 : shippingAddressLine1 ? shippingAddressLine1 : null),
                address_line2: StringUtils.sanitize(billingAddressLine2 ? billingAddressLine2 : shippingAddressLine2 ? shippingAddressLine2 : null),
                address_city: StringUtils.sanitize(billingAddressCity ? billingAddressCity : shippingAddressCity ? shippingAddressCity : null),
                address_state: StringUtils.sanitize(billingAddressState ? billingAddressState : shippingAddressState ? shippingAddressState : null),
                address_country: StringUtils.sanitize(billingCountry ? billingCountry : shippingCountry ? shippingCountry : null),
                address_zip: StringUtils.sanitize(billingAddressPostalCode ? billingAddressPostalCode : shippingAddressPostalCode ? shippingAddressPostalCode : null)
              }
            );
          
            //console.log('source', sourceRes);
            if (sourceRes.status === 200 || sourceRes.status === 201) {
              const chargeRes = await Api.createCharge(
                secretKey,
                version,
                total,
                currency,
                sourceRes.data.id,
                StringUtils.truncate(description, 25).replace(/[^a-zA-Z0-9# ]/g, ''),
                StringUtils.truncate(merchant, 22, false).replace(/[^a-zA-Z0-9 ]/g, ''),
                true,
                undefined,
                { ...metadata, session_id: session.id }
              );
              //console.log('charge', chargeRes);
              if (chargeRes.status === 201) {
                if (version === '2') {
                  if (chargeRes.data.action.type === 'redirect_to_url') {
                    window.location.href = chargeRes.data.action.url;
                  } else {
                    //console.log('Unsupported action type:', chargeRes.data.action.type);
                    setError(`Unsupported action type: ${chargeRes.data.action.type}`)
                    setErrorModalVisible(true);
                    setQuickPayLoading(false);
                    setIsQuickPay(false);
                  }
                } else {
                  window.location.href = chargeRes.data.checkout_url;
                }
              } else {
                if (version === '2') {
                  setError(Array.isArray(chargeRes.data.detail) ? chargeRes.data.detail[0].msg : chargeRes.data.detail || 'We are unable to process this transaction at this time.');
                } else {
                  setError(chargeRes.data.message || 'We are unable to process this transaction at this time.');
                }
                setErrorModalVisible(true);
                setQuickPayLoading(false);
                setIsQuickPay(false);
              }
            } else {
              if (version === '2') {
                setError(Array.isArray(sourceRes.data.detail) ? sourceRes.data.detail[0].msg : sourceRes.data.detail || 'We are unable to process this transaction at this time.');
              } else {
                setError(sourceRes.data.message || 'We are unable to process this transaction at this time.');
              }
              setErrorModalVisible(true);
              setQuickPayLoading(false);
              setIsQuickPay(false);
            }
          } else {
            setError(updateSessionRes.data.message || 'We are unable to process this transaction at this time.');
            setErrorModalVisible(true);
            setQuickPayLoading(false);
            setIsQuickPay(false);
          }
        }
        
        
      } else {
        setError(`${CardUtils.getBankName(quickPayMethod)} is not yet supported`);
        setErrorModalVisible(true);
        setQuickPayLoading(false);
        setIsQuickPay(false);
      }
    } catch (e) {
      console.log('error', e);
      Sentry.captureException(e);
      setError('We are unable to process this transaction at this time.');
      setErrorModalVisible(true);
      setQuickPayLoading(false);
      setIsQuickPay(false);
    }
  }

  const onPayClick = async (e) => {
    e.preventDefault();
    try {
      // console.log('phone', phone);
      // console.log('formatted', getPhoneNumber())
      if (complete && !loading && !success) {
        setCardNumberError(false);
        setCardNumberErrorMsg('');
        setLoading(true);
        // NOTE: If mode is NOT subscription or save_card, check if amount is below minimum for each available currency
        if (mode !== 'subscription' && mode !== 'save_card') {
          const minimum = NumberUtils.getCurrencyMinimum(currency);
          if ((total / 100) < minimum) {
            if (useSavedInfo) {
              setSavedInfoErrorMsg(`Minimum amount for card payments should be ${NumberUtils.formatCurrency(minimum, currency)} ${currency.toUpperCase()} or above.`);
            } else {
              setCardNumberError(true);
              setCardNumberErrorMsg(`Minimum amount for card payments should be ${NumberUtils.formatCurrency(minimum, currency)} ${currency.toUpperCase()} or above.`);
            }
            setLoading(false);
            setComplete(false);
            return;
          }
        }
        /* if (version === '2') {
          if ((total / 100) < NumberUtils.getCurrencyMinimum(currency)) {
            setCardNumberError(true);
            setCardNumberErrorMsg(`Minimum amount for card payments should be ${NumberUtils.formatCurrency(NumberUtils.getCurrencyMinimum(currency), currency)} ${currency.toUpperCase()} or above`);
            setLoading(false);
            setComplete(false);
            return;
          }
        } else {
          if ((total / 100) < 5000) {
            setCardNumberError(true);
            setCardNumberErrorMsg('Minimum amount for card payments should be 50.00 or above');
            setLoading(false);
            setComplete(false);
            return;
          }
        } */

        
        //onSubmitPayment(data);
        //console.log('params', data);

        let customer = {
          id: sessionCustomer.id ? sessionCustomer.id : null,
          name: name ? name : sessionCustomer.name,
          email: email ? email : sessionCustomer.email,
          phone: requestPhone ? getPhoneNumber() : null,
        };

        // Check if mode is subscription and customer is not yet created
        if (mode === 'subscription' && !customer.id) {
          const customerRes = await Api.getCustomerByEmail(secretKey, email);
          if (customerRes.status === 200) {
            const customerData = customerRes.data;
            customer = {
              id: customerData.id,
              name: name ? name : customerData.metadata ? customerData.metadata.name ? customerData.metadata.name : name : name,
              email: customerData.email,
              phone: customerData.mobile_number ? customerData.mobile_number : requestPhone ? getPhoneNumber() : null,
            }
          } else {
            const createCustomerRes = await Api.createCustomer(
              secretKey,
              version,
              email,
              name,
              requestPhone ? getPhoneNumber() : null,
              'Customer for subscription'
            );
            if (createCustomerRes.status === 201) {
              const customerData = createCustomerRes.data;
              customer = {
                id: customerData.id,
                name: customerData.metadata ? customerData.metadata.name ? customerData.metadata.name : name : name,
                email: customerData.email,
                phone: customerData.mobile_number ? customerData.mobile_number : requestPhone ? getPhoneNumber() : null,
              }
            } else {
              setEmailError(true);
              setEmailErrorMsg('We are unable to create a customer for this transaction at this time.');
              setLoading(false);
              setComplete(false);
              return;
            }
          }
        }
        console.log({ customer })
        setCardCustomer(customer);

        const updateSessionRes = await Api.updateSession(
          secretKey,
          version,
          session.id,
          customer,
          null,
          shipping ? getShippingAddress() : null,
          getBillingAddress(),
        )

        //console.log('update', updateSessionRes);
        if (updateSessionRes.status === 200) {
          if (version === '2') {
            if (useSavedInfo && !!selectedSavedInfo) {
              const chargeRes = await Api.createCharge(
                secretKey,
                version,
                total,
                currency,
                selectedSavedInfo.id,
                StringUtils.truncate(description, 200, true).replace(/[^a-zA-Z0-9 ]/g, ''),
                StringUtils.truncate(merchant, 22, false).replace(/[^a-zA-Z0-9 ]/g, ''),
                mode === 'setup' ? false : true,
                undefined,
                { ...metadata, session_id: session.id },
                requireAuth,
              );

              if (chargeRes.status === 201) {
                //console.log('url', chargeRes.data.checkout_url);
                if (mode === 'payment') {
                  if (chargeRes.data.captured) {
                    await updatePaymentSession(customer, chargeRes.data);
                  } else {
                    setSavedInfoErrorMsg('Payment authorization failed');
                    setLoading(false);
                    setComplete(false);
                  }
                } else if (mode === 'setup') {
                  if (chargeRes.data.authorized) {
                    await updatePaymentSession(customer, chargeRes.data);
                  } else {
                    setSavedInfoErrorMsg('Payment authorization failed');
                    setLoading(false);
                    setComplete(false);
                  }
                }
                
              } else {
                setCardNumberError(true);
                setCardNumberErrorMsg(Array.isArray(chargeRes.data.detail) ? chargeRes.data.detail[0].msg : chargeRes.data.detail || 'We are unable to process this transaction at this time.');
                setLoading(false);
                setComplete(false);
              }
            } else {
              console.log('charge not saving info')
              let data = {
                name,
                email,
                country: billingCountry,
                card_information: {
                  card_number: cardNumber.replace(/\s/g, ''),
                  expiry_month: expiry.split('/')[0],
                  expiry_year: `20${expiry.split('/')[1].replace(/\s/g, '')}`,
                  cvc
                },
                billing_address: null,
                shipping_address: null,
              };
      
              if (shipping) {
                data = {
                  ...data,
                  shipping_address: {
                    country: shippingCountry,
                    name: shippingName,
                    line_1: shippingAddressLine1,
                    line_2: shippingAddressLine2,
                    suburb: shippingAddressSuburb,
                    city: shippingAddressCity,
                    postal_code: shippingAddressPostalCode,
                    state: shippingAddressState
                  }
                };
      
                if (sameBillingAsShipping) {
                  data = {
                    ...data,
                    name: shippingName,
                    country: shippingCountry
                  };
      
                  if (billing) {
                    data = {
                      ...data,
                      billing_address: {
                        country: shippingCountry,
                        line_1: shippingAddressLine1,
                        line_2: shippingAddressLine2,
                        suburb: shippingAddressSuburb,
                        city: shippingAddressCity,
                        postal_code: shippingAddressPostalCode,
                        state: shippingAddressState
                      }
                    };
                  }
                } else {
                  if (billing) {
                    data = {
                      ...data,
                      billing_address: {
                        country: billingCountry,
                        line_1: billingAddressLine1,
                        line_2: billingAddressLine2,
                        suburb: billingAddressSuburb,
                        city: billingAddressCity,
                        postal_code: billingAddressPostalCode,
                        state: billingAddressState
                      }
                    };
                  }
                }
              } else {
                if(billing) {
                  data = {
                    ...data,
                    billing_address: {
                      country: billingCountry,
                      line_1: billingAddressLine1,
                      line_2: billingAddressLine2,
                      suburb: billingAddressSuburb,
                      city: billingAddressCity,
                      postal_code: billingAddressPostalCode,
                      state: billingAddressState
                    }
                  };
                }
              }

              let cardData = {
                name: StringUtils.sanitize(data.name),
                number: data.card_information.card_number,
                exp_month: parseInt(data.card_information.expiry_month.trim()),
                exp_year: parseInt(data.card_information.expiry_year.trim()),
                cvc: data.card_information.cvc
              };
    
              if (billing) {
                cardData = {
                  ...cardData,
                  address_line1: StringUtils.sanitize(data.billing_address.line_1),
                  address_line2: data.billing_address.line_2 ? StringUtils.sanitize(data.billing_address.line_2) : null,
                  address_city: StringUtils.sanitize(data.billing_address.city),
                  address_state: StringUtils.sanitize(data.billing_address.state),
                  address_country: StringUtils.sanitize(data.billing_address.country),
                  address_zip: StringUtils.sanitize(data.billing_address.postal_code)
                }
              }

              console.log({ data })

              const redirectUrls = {
                successUrl: `${BASE_URL}/${session.id}?d=${Buffer.from(encodeURIComponent(JSON.stringify(data)), 'utf8').toString('base64')}`,
                failUrl: `${BASE_URL}/${session.id}?d=${Buffer.from(encodeURIComponent(JSON.stringify(data)), 'utf8').toString('base64')}`,
                notifyUrl: `${BASE_URL}/api/v2/sessions/${session.id}/notify`,
              };
    
              const sourceRes = await Api.createSource(
                publicKey,
                version,
                total,
                currency,
                'card',
                redirectUrls,
                cardData,
                {
                  name: StringUtils.sanitize(customer.name),
                  address_country: StringUtils.sanitize(billingCountry ? billingCountry : shippingCountry ? shippingCountry : null),
                  billing: data.billing_address ? {
                    name: StringUtils.sanitize(customer.name),
                    phone_number: customer.phone ? customer.phone.replace('+', '') : null,
                    email: customer.email,
                    line1: StringUtils.sanitize(data.billing_address.line_1 ? data.billing_address.line_1 : null),
                    line2: StringUtils.sanitize(data.billing_address.line_2 ? data.billing_address.line_2 : null),
                    city: StringUtils.sanitize(data.billing_address.city ? data.billing_address.city : null),
                    state: StringUtils.sanitize(data.billing_address.state ? data.billing_address.state : null),
                    country: StringUtils.sanitize(data.billing_address.country ? data.billing_address.country : null),
                    zip_code: StringUtils.sanitize(data.billing_address.postal_code ? data.billing_address.postal_code : null),
                  } : null,
                  shipping: data.shipping_address ? {
                    name: StringUtils.sanitize(shippingName),
                    phone_number: customer.phone ? customer.phone.replace('+', '') : null,
                    email: customer.email,
                    line1: StringUtils.sanitize(data.shipping_address.line_1 ? data.shipping_address.line_1 : null),
                    line2: StringUtils.sanitize(data.shipping_address.line_2 ? data.shipping_address.line_2 : null),
                    city: StringUtils.sanitize(data.shipping_address.city ? data.shipping_address.city : null),
                    state: StringUtils.sanitize(data.shipping_address.state ? data.shipping_address.state : null),
                    country: StringUtils.sanitize(data.shipping_address.country ? data.shipping_address.country : null),
                    zip_code: StringUtils.sanitize(data.shipping_address.postal_code ? data.shipping_address.postal_code : null)
                  } : null,
                }
              );
    
              if (sourceRes.status === 200 || sourceRes.status === 201) {
                const chargeRes = await Api.createCharge(
                  secretKey,
                  version,
                  total,
                  currency,
                  sourceRes.data.id,
                  StringUtils.truncate(description, 200, true).replace(/[^a-zA-Z0-9 ]/g, ''),
                  StringUtils.truncate(merchant, 22, false).replace(/[^a-zA-Z0-9 ]/g, ''),
                  mode === 'setup' || mode === 'save_card' ? false : true,
                  undefined,
                  { ...metadata, session_id: session.id },
                  requireAuth,
                );
                console.log('charge', chargeRes.data);
                if (chargeRes.status === 201) {
                  // console.log('url', chargeRes.data.checkout_url);

                  if (chargeRes.data.require_auth) {
                    if (chargeRes.data.action !== null) {
                      if (chargeRes.data.action.type === 'redirect_to_url') {
                        /* setCardFrameUrl(chargeRes.data.action.url);
                        setCardFrameLoading(true);
                        setCardModalVisible(true); */
                        console.log('redirecting to action.url', chargeRes.data.action.url)
                        window.location.href = chargeRes.data.action.url;
                      } else {
                        //console.log('Unsupported action type:', chargeRes.data.action.type);
                        setCardNumberError(true);
                        setCardNumberErrorMsg(`Unsupported action type: ${chargeRes.data.action.type}`);
                        setLoading(false);
                        setComplete(false);
                      }
                    } else {
                      setCardNumberError(true);
                      setCardNumberErrorMsg('Card does not support 3D secure authentication required for this transaction. Contact your card issuer for more information.');
                      setLoading(false);
                      setComplete(false);
                    }
                  } else {
                    if (mode === 'payment' || mode === 'subscription') {
                      if (chargeRes.data.captured) {
                        if (saveInfoChecked || updateSavedInfo) {
                          const customerData = await getCustomerData();
                          if (customerData) {
                            await saveCustomerPaymentInfo(customerData);
                          }
                          await updatePaymentSession({
                            id: customerData.id,
                            name: customerData.metadata ? customerData.metadata.name ? customerData.metadata.name : name : name,
                            email: customerData.email,
                            phone: customerData.mobile_number,
                          }, chargeRes.data);
                        } else {
                          await updatePaymentSession(customer, chargeRes.data);
                        }
                      } else {
                        setCardNumberError(true);
                        setCardNumberErrorMsg('Payment authorization failed');
                        setLoading(false);
                        setComplete(false);
                      }
                    } else if (mode === 'setup' || mode === 'save_card') {
                      if (chargeRes.data.authorized) {
                        if (saveInfoChecked || updateSavedInfo) {
                          const customerData = await getCustomerData();
                          if (customerData) {
                            await saveCustomerPaymentInfo(customerData);
                          }
                          await updatePaymentSession({
                            id: customerData.id,
                            name: customerData.metadata ? customerData.metadata.name ? customerData.metadata.name : name : name,
                            email: customerData.email,
                            phone: customerData.mobile_number,
                          }, chargeRes.data);
                        } else {
                          await updatePaymentSession(customer, chargeRes.data);
                        }
                      } else {
                        setCardNumberError(true);
                        setCardNumberErrorMsg('Payment authorization failed');
                        setLoading(false);
                        setComplete(false);
                      }
                    }
                  }
                  
                  
                } else {
                  setCardNumberError(true);
                  setCardNumberErrorMsg(Array.isArray(chargeRes.data.detail) ? sourceRes.data.detail[0].msg : chargeRes.data.detail || 'We are unable to process this transaction at this time.');
                  setLoading(false);
                  setComplete(false);
                }
              } else {
                setCardNumberError(true);
                setCardNumberErrorMsg(Array.isArray(sourceRes.data.detail) ? sourceRes.data.detail[0].msg : sourceRes.detail || 'We are unable to process this transaction at this time.');
                setLoading(false);
                setComplete(false);
              }
            }

          } else {
            let data = {
              name,
              email,
              country: billingCountry,
              card_information: {
                card_number: cardNumber.replace(/\s/g, ''),
                expiry_month: expiry.split('/')[0],
                expiry_year: `20${expiry.split('/')[1].replace(/\s/g, '')}`,
                cvc
              },
              billing_address: null,
              shipping_address: null,
            };
    
            if (shipping) {
              data = {
                ...data,
                shipping_address: {
                  country: shippingCountry,
                  name: shippingName,
                  line_1: shippingAddressLine1,
                  line_2: shippingAddressLine2,
                  suburb: shippingAddressSuburb,
                  city: shippingAddressCity,
                  postal_code: shippingAddressPostalCode,
                  state: shippingAddressState
                }
              };
    
              if (sameBillingAsShipping) {
                data = {
                  ...data,
                  name: shippingName,
                  country: shippingCountry
                };
    
                if (billing) {
                  data = {
                    ...data,
                    billing_address: {
                      country: shippingCountry,
                      line_1: shippingAddressLine1,
                      line_2: shippingAddressLine2,
                      suburb: shippingAddressSuburb,
                      city: shippingAddressCity,
                      postal_code: shippingAddressPostalCode,
                      state: shippingAddressState
                    }
                  };
                }
              } else {
                if (billing) {
                  data = {
                    ...data,
                    billing_address: {
                      country: billingCountry,
                      line_1: billingAddressLine1,
                      line_2: billingAddressLine2,
                      suburb: billingAddressSuburb,
                      city: billingAddressCity,
                      postal_code: billingAddressPostalCode,
                      state: billingAddressState
                    }
                  };
                }
              }
            } else {
              if(billing) {
                data = {
                  ...data,
                  billing_address: {
                    country: billingCountry,
                    line_1: billingAddressLine1,
                    line_2: billingAddressLine2,
                    suburb: billingAddressSuburb,
                    city: billingAddressCity,
                    postal_code: billingAddressPostalCode,
                    state: billingAddressState
                  }
                };
              }
            }

            const tokenRes = await Api.createToken(
              publicKey,
              data.name,
              data.card_information.card_number,
              data.card_information.expiry_month,
              data.card_information.expiry_year,
              data.card_information.cvc,
              data.country,
              data.billing_address
            );
            //console.log('token', tokenRes);
            if (tokenRes.status === 201) {
              if (livemode) {
                const chargeRes = await Api.create3dsCharge(
                  secretKey,
                  total,
                  currency,
                  tokenRes.data.id,
                  StringUtils.truncate(description, 200, true).replace(/[^a-zA-Z0-9 ]/g, ''),
                  StringUtils.truncate(merchant, 22, false).replace(/[^a-zA-Z0-9 ]/g, ''),
                  true,
                  'magpie_3ds',
                  `${origin}/card/${secretKey}/callback`
                );
                if (chargeRes.status ===  201) {
                  setCardFrameUrl(chargeRes.data.checkout_url);
                  setCardFrameLoading(true);
                  setCardModalVisible(true);
                } else {
                  setCardNumberError(true);
                  setCardNumberErrorMsg(chargeRes.data.message || 'We are unable to process this transaction at this time.');
                  setLoading(false);
                  setComplete(false);
                }
              } else {
                const data = {
                  key: secretKey,
                  version,
                  merchant,
                  amount: total,
                  currency,
                  source: tokenRes.data.id,
                  description,
                };
                //const checkoutUrl = `${origin}/card/3ds-test?d=${btoa(unescape(encodeURIComponent(JSON.stringify(data))))}`;
                const checkoutUrl = `${origin}/card/3ds-test?d=${btoa(encodeURIComponent(JSON.stringify(data)))}`;
                //console.log('data', data);
                //console.log('json', JSON.stringify(data));
                //console.log('btoa', btoa(encodeURIComponent(JSON.stringify(data))));
                //console.log('d', btoa(unescape(encodeURIComponent(JSON.stringify(data)))));
                //console.log('url', checkoutUrl);
                
                setCardFrameUrl(checkoutUrl);
                setCardFrameLoading(true);
                setCardModalVisible(true);
              }
            } else {
              setCardNumberError(true);
              setCardNumberErrorMsg(tokenRes.data.message || 'We are unable to process this transaction at this time.');
              setLoading(false);
              setComplete(false);
            }
          }

        } else {
          setError(updateSessionRes.data.message || 'We are unable to process this transaction at this time.');
          setErrorModalVisible(true);
          setQuickPayLoading(false);
          setIsQuickPay(false);
        }
        
      } else if (!complete) {
        if (!email) setEmailMissing(true);
        if (!CardUtils.isValidEmail(email)) {
          setEmailError(true);
          setEmailErrorMsg('Your email is not valid');
        }
        if (requestPhone && !phone) setPhoneMissing(true);
        if (!cardNumber) setCardNumberMissing(true);
        if (!expiry) setExpiryMissing(true);
        if (!cvc) setCvcMissing(true);
        if (!cardNumber && !expiry && !cvc) setCardDetailsMissing(true);
        if (!name) setNameMissing(true);
        if (saveInfoChecked && !saveInfoPhone) setSaveInfoPhoneMissing(true);

        if (shipping) {
          checkShippingDetails();
          if (billing) {
            if (!sameBillingAsShipping) {
              if (!name) setNameMissing(true);
              checkBillingDetails();
            }
          }
        } else {
          if (billing) {
            if (!name) setNameMissing(true);
            checkBillingDetails();
          }
        }

        /* if (shipping) {
          if (!shippingName && !shippingAddressLine1 && (CountryUtils.hasCity(shippingCountry) && !shippingAddressCity) && (CountryUtils.hasPostal(shippingCountry) && !shippingAddressPostalCode) && (CountryUtils.hasState(shippingCountry) && (!shippingAddressState || shippingAddressState === 'true'))) {
            setShippingDetailsMissing(true);
          } else if (!shippingName) {
            setShippingNameMissing(true);
          } else if (!shippingAddressLine1) {
            setShippingAddressLine1Missing(true);
          } else if (CountryUtils.hasCity(shippingCountry) && !shippingAddressCity) {
            setShippingAddressCityMissing(true);
          } else if (CountryUtils.hasPostal(shippingCountry) && !shippingAddressPostalCode) {
            setShippingAddressPostalMissing(true);
          } else if (CountryUtils.hasState(shippingCountry) && (!shippingAddressState || shippingAddressState === 'true')) {
            setShippingAddressStateMissing(true);
          }

          if (!sameBillingAsShipping) {
            if (!name) setNameMissing(true);
            if (billing) {
              if (!billingAddressLine1 && (CountryUtils.hasCity(billingCountry) && !billingAddressCity) && (CountryUtils.hasPostal(billingCountry) && !billingAddressPostalCode) && (CountryUtils.hasState(billingCountry) && (!billingAddressState || billingAddressState === 'true'))) {
                setBillingDetailsMissing(true);
              } else if (!billingAddressLine1) {
                setBillingAddressLine1Missing(true);
              } else if (CountryUtils.hasCity(billingCountry) && !billingAddressCity) {
                setBillingAddressCityMissing(true);
              } else if (CountryUtils.hasPostal(billingCountry) && !billingAddressPostalCode) {
                setBillingAddressPostalMissing(true);
              } else if (CountryUtils.hasState(billingCountry) && (!billingAddressState || billingAddressState === 'true')) {
                setBillingAddressStateMissing(true);
              }
            }
          }
        } else {
          if (!name) setNameMissing(true);
          if (billing) {
            if (!billingAddressLine1 && (CountryUtils.hasCity(billingCountry) && !billingAddressCity) && (CountryUtils.hasPostal(billingCountry) && !billingAddressPostalCode) && (CountryUtils.hasState(billingCountry) && (!billingAddressState || billingAddressState === 'true'))) {
              setBillingDetailsMissing(true);
            } else if (!billingAddressLine1) {
              setBillingAddressLine1Missing(true);
            } else if (CountryUtils.hasCity(billingCountry) && !billingAddressCity) {
              setBillingAddressCityMissing(true);
            } else if (CountryUtils.hasPostal(billingCountry) && !billingAddressPostalCode) {
              setBillingAddressPostalMissing(true);
            } else if (CountryUtils.hasState(billingCountry) && (!billingAddressState || billingAddressState === 'true')) {
              setBillingAddressStateMissing(true);
            }
          }
        } */
      }
    } catch (e) {
      console.log('error', e);
      Sentry.captureException(e);
      setCardNumberError(true);
      setCardNumberErrorMsg('We are unable to process this transaction at this time.')
      setLoading(false);      
      setComplete(false);
    }
  }

  const onWalletPayClick = async (e) => {
    e.preventDefault();
    //checkIfCompleteWallet();
    try {
      if (complete && !loading && !success) {
        setLoading(true);

        if (allowedWallets.includes(wallet.toLowerCase())) {
          //console.log('params', data);

          const customer = {
            id: sessionCustomer.id ? sessionCustomer.id : null,
            name,
            email,
            phone: requestPhone ? getPhoneNumber() : undefined,
          };
          setWalletCustomer(customer);

          if (wallet.toLowerCase() === 'paymaya' || wallet.toLowerCase() === 'gcash') { 
            const updateSessionRes = await Api.updateSession(
              secretKey,
              version,
              session.id,
              customer,
              null,
              shipping ? getShippingAddress() : null,
              getBillingAddress()
            )
            //console.log('update', updateSessionRes);
            if (updateSessionRes.status === 200) {
              const data = {
                payment_method: 'wallet',
                // payment_type: 'paymaya',
                payment_type: wallet.toLowerCase(),
                is_quick_pay: false
              };

              const redirectUrls = {
                successUrl: `${BASE_URL}/${session.id}?d=${Buffer.from(encodeURIComponent(JSON.stringify(data)), 'utf8').toString('base64')}`,
                failUrl: `${BASE_URL}/${session.id}?d=${Buffer.from(encodeURIComponent(JSON.stringify(data)), 'utf8').toString('base64')}`,
                notifyUrl: `${BASE_URL}/api/v2/sessions/${session.id}/notify`,
              };

              const sourceRes = await Api.createSource(
                publicKey,
                version,
                total,
                currency,
                version === '2' ? wallet.toLowerCase() : wallet.toUpperCase(),
                redirectUrls,
                /* {
                  successUrl: `${window.location.origin}/${session.id}?d=${btoa(unescape(encodeURIComponent(JSON.stringify(data))))}`,
                  failUrl: `${window.location.origin}/${session.id}?d=${btoa(unescape(encodeURIComponent(JSON.stringify(data))))}`
                }, */
                undefined,
                {
                  name: customer.name ? StringUtils.sanitize(customer.name) : customer.email,
                  mobile_number: customer.phone ? StringUtils.sanitize(customer.phone) : null,
                  address_line1: StringUtils.sanitize(billingAddressLine1 ? billingAddressLine1 : shippingAddressLine1 ? shippingAddressLine1 : null),
                  address_line2: StringUtils.sanitize(billingAddressLine2 ? billingAddressLine2 : shippingAddressLine2 ? shippingAddressLine2 : null),
                  address_city: StringUtils.sanitize(billingAddressCity ? billingAddressCity : shippingAddressCity ? shippingAddressCity : null),
                  address_state: StringUtils.sanitize(billingAddressState ? billingAddressState : shippingAddressState ? shippingAddressState : null),
                  address_country: StringUtils.sanitize(billingCountry ? billingCountry : shippingCountry ? shippingCountry : null),
                  address_zip: StringUtils.sanitize(billingAddressPostalCode ? billingAddressPostalCode : shippingAddressPostalCode ? shippingAddressPostalCode : null)
                }
              );
            
              // console.log('source', sourceRes);
              if (sourceRes.status === 200 || sourceRes.status === 201) {
                const chargeRes = await Api.createCharge(
                  secretKey,
                  version,
                  total,
                  currency,
                  sourceRes.data.id,
                  StringUtils.truncate(description, 200, true).replace(/[^a-zA-Z0-9 ]/g, ''),
                  StringUtils.truncate(merchant, 22, false).replace(/[^a-zA-Z0-9 ]/g, ''),
                  true,
                  undefined,
                  { ...metadata, session_id: session.id },
                  undefined,
                );
                //console.log('charge', chargeRes);
                if (chargeRes.status === 201) {
                  if (version === '2') {
                    if (chargeRes.data.action.type === 'redirect_to_url') {
                      window.location.href = chargeRes.data.action.url;
                    } else {
                      //console.log('Unsupported action type:', chargeRes.data.action.type);
                      setWalletErrorMsg(`Unsupported action type: ${chargeRes.data.action.type}`);
                      setWalletError(true);
                      setLoading(false);
                      setComplete(false);
                    }
                  } else {
                    window.location.href = chargeRes.data.checkout_url;
                  }
                  
                } else {
                  if (version === '2') {
                    setWalletErrorMsg(Array.isArray(chargeRes.data.detail) ? chargeRes.data.detail[0].msg : chargeRes.data.detail || 'We are unable to process this transaction at this time.');
                  } else {
                    setWalletErrorMsg(chargeRes.data.message || 'We are unable to process this transaction at this time.');
                  }
                  setWalletError(true);
                  setLoading(false);
                  setComplete(false);
                }
              } else {
                if (version === '2') {
                  setWalletErrorMsg(Array.isArray(sourceRes.data.detail) ? sourceRes.data.detail[0].msg : sourceRes.data.detail || 'We are unable to process this transaction at this time.');
                } else {
                  setWalletErrorMsg(sourceRes.data.message || 'We are unable to process this transaction at this time.');
                }
                setWalletError(true);
                setLoading(false);
                setComplete(false);
              }
            } else {
              setWalletError(true);
              setWalletErrorMsg(updateSessionRes.data.message || 'We are unable to process this transaction at this time.');
              setLoading(false);
              setComplete(false);
            }
          } else {
            const updateSessionRes = await Api.updateSession(
              secretKey,
              version,
              session.id,
              customer,
              null,
              shipping ? getShippingAddress() : null,
              getBillingAddress()
            );

            if (updateSessionRes.status === 200) {
              const sourceRes = await Api.createSource(
                publicKey,
                version,
                total,
                currency,
                version === '2' ? wallet.toLowerCase() : wallet.toUpperCase(),
                {
                  successUrl: `${BASE_URL}/wallet/success?session_id=${session.id}`,
                  failUrl: `${BASE_URL}/wallet/fail?session_id=${session.id}`,
                  notifyUrl: `${BASE_URL}/api/v2/sessions/${session.id}/notify`,
                },
                undefined,
                {
                  name: customer.name ? StringUtils.sanitize(customer.name) : customer.email,
                  mobile_number: customer.phone ? StringUtils.sanitize(customer.phone) : null,
                  address_line1: StringUtils.sanitize(billingAddressLine1 ? billingAddressLine1 : shippingAddressLine1 ? shippingAddressLine1 : null),
                  address_line2: StringUtils.sanitize(billingAddressLine2 ? billingAddressLine2 : shippingAddressLine2 ? shippingAddressLine2 : null),
                  address_city: StringUtils.sanitize(billingAddressCity ? billingAddressCity : shippingAddressCity ? shippingAddressCity : null),
                  address_state: StringUtils.sanitize(billingAddressState ? billingAddressState : shippingAddressState ? shippingAddressState : null),
                  address_country: StringUtils.sanitize(billingCountry ? billingCountry : shippingCountry ? shippingCountry : null),
                  address_zip: StringUtils.sanitize(billingAddressPostalCode ? billingAddressPostalCode : shippingAddressPostalCode ? shippingAddressPostalCode : null)
                }
              );
              //console.log('source', sourceRes);
              if (sourceRes.status === 200 || sourceRes.status === 201) {
                const chargeRes = await Api.createCharge(
                  secretKey,
                  version,
                  total,
                  currency,
                  sourceRes.data.id,
                  StringUtils.truncate(description, 200, true).replace(/[^a-zA-Z0-9 ]/g, ''),
                  StringUtils.truncate(merchant, 22, false).replace(/[^a-zA-Z0-9 ]/g, ''),
                  true,
                  undefined,
                  { ...metadata, session_id: session.id },
                  undefined,
                );
                //console.log('charge', chargeRes);
                if (chargeRes.status === 201) {
                  //console.log('url', chargeRes.data.checkout_url);
                  if (version === '2') {
                    if (chargeRes.data.action.type === 'redirect_to_url') {
                      setWalletFrameUrl(chargeRes.data.action.url);
                    } else {
                      //console.log('Unsupported action type:', chargeRes.data.action.type);
                      setWalletErrorMsg(`Unsupported action type: ${chargeRes.data.action.type}`);
                      setWalletError(true);
                      setLoading(false);
                      setComplete(false);
                    }
                  } else {
                    setWalletFrameUrl(chargeRes.data.checkout_url);
                  }
                  
                  setWalletFrameLoading(true);
                  setWalletModalVisible(true);
                } else {
                  if (version === '2') {
                    setWalletErrorMsg(Array.isArray(chargeRes.data.detail) ? chargeRes.data.detail[0].msg : chargeRes.data.detail || 'We are unable to process this transaction at this time.');
                  } else {
                    setWalletErrorMsg(chargeRes.data.message || 'We are unable to process this transaction at this time.');
                  }
                  setWalletError(true);
                  setLoading(false);
                  setComplete(false);
                }
              } else {
                if (version === '2') {
                  setWalletErrorMsg(Array.isArray(sourceRes.data.detail) ? sourceRes.data.detail[0].msg : sourceRes.data.detail || 'We are unable to process this transaction at this time.');
                } else {
                  setWalletErrorMsg(sourceRes.data.message || 'We are unable to process this transaction at this time.');
                }
                setWalletError(true);
                setLoading(false);
                setComplete(false);
              }
            } else {
              setWalletError(true);
              setWalletErrorMsg(updateSessionRes.data.message || 'We are unable to process this transaction at this time.');
              setLoading(false);
              setComplete(false);
            }
          }
        } else {
          setWalletError(true);
          if (!!wallet) {
            setWalletErrorMsg(`${CardUtils.getWalletName(wallet)} is not yet supported`);
          } else {
            setWalletMissing(true);
          }
          setLoading(false);
          setComplete(false);
        }

      } else if (!complete) {
        if (!email) setEmailMissing(true);
        if (requestPhone && !phone) setPhoneMissing(true);
        if (!wallet) setWalletMissing(true);

        if (shipping) {
          checkShippingDetails();
          if (billing) {
            if (!sameBillingAsShipping) {
              if (!name) setNameMissing(true);
              checkBillingDetails();
            }
          }
        } else {
          if (billing) {
            if (!name) setNameMissing(true);
            checkBillingDetails();
          }
        }

        /* if (shipping) {
          if (!shippingName && !shippingAddressLine1 && (CountryUtils.hasCity(shippingCountry) && !shippingAddressCity) && (CountryUtils.hasPostal(shippingCountry) && !shippingAddressPostalCode) && (CountryUtils.hasState(shippingCountry) && (!shippingAddressState || shippingAddressState === 'true'))) {
            setShippingDetailsMissing(true);
          } else if (!shippingName) {
            setShippingNameMissing(true);
          } else if (!shippingAddressLine1) {
            setShippingAddressLine1Missing(true);
          } else if (CountryUtils.hasCity(shippingCountry) && !shippingAddressCity) {
            setShippingAddressCityMissing(true);
          } else if (CountryUtils.hasPostal(shippingCountry) && !shippingAddressPostalCode) {
            setShippingAddressPostalMissing(true);
          } else if (CountryUtils.hasState(shippingCountry) && (!shippingAddressState || shippingAddressState === 'true')) {
            setShippingAddressStateMissing(true);
          }

          if (!sameBillingAsShipping) {
            if (!name) setNameMissing(true);
            if (billing) {
              if (!billingAddressLine1 && (CountryUtils.hasCity(billingCountry) && !billingAddressCity) && (CountryUtils.hasPostal(billingCountry) && !billingAddressPostalCode) && (CountryUtils.hasState(billingCountry) && (!billingAddressState || billingAddressState === 'true'))) {
                setBillingDetailsMissing(true);
              } else if (!billingAddressLine1) {
                setBillingAddressLine1Missing(true);
              } else if (CountryUtils.hasCity(billingCountry) && !billingAddressCity) {
                setBillingAddressCityMissing(true);
              } else if (CountryUtils.hasPostal(billingCountry) && !billingAddressPostalCode) {
                setBillingAddressPostalMissing(true);
              } else if (CountryUtils.hasState(billingCountry) && (!billingAddressState || billingAddressState === 'true')) {
                setBillingAddressStateMissing(true);
              }
            }
          }
        } else {
          if (!name) setNameMissing(true);
          if (billing) {
            if (!billingAddressLine1 && (CountryUtils.hasCity(billingCountry) && !billingAddressCity) && (CountryUtils.hasPostal(billingCountry) && !billingAddressPostalCode) && (CountryUtils.hasState(billingCountry) && (!billingAddressState || billingAddressState === 'true'))) {
              setBillingDetailsMissing(true);
            } else if (!billingAddressLine1) {
              setBillingAddressLine1Missing(true);
            } else if (CountryUtils.hasCity(billingCountry) && !billingAddressCity) {
              setBillingAddressCityMissing(true);
            } else if (CountryUtils.hasPostal(billingCountry) && !billingAddressPostalCode) {
              setBillingAddressPostalMissing(true);
            } else if (CountryUtils.hasState(billingCountry) && (!billingAddressState || billingAddressState === 'true')) {
              setBillingAddressStateMissing(true);
            }
          }
        } */
      }
    } catch (e) {
      console.log('error', e);
      Sentry.captureException(e);
      setLoading(false);
      setWalletErrorMsg('We are unable to process this transaction at this time.');
      setWalletError(true);
      setComplete(false);
    }
  }

  const onBankPayClick = async (e) => {
    e.preventDefault();
    try {
      if (complete && !loading && !success) {
        setLoading(true);

        if (allowedBanks.includes(bank.toLowerCase())) {
          //console.log('params', data);

          let customer = {
            id: sessionCustomer.id ? sessionCustomer.id : null,
            name,
            email,
            phone: requestPhone ? getPhoneNumber() : undefined,
          };
          setBankCustomer(customer);

          console.log('method', bank);
          if (bank.toLowerCase() === 'bpi') {
            if (version === '2')  {
              const code = !!bankCode ? bankCode : CardUtils.getBankCode(bank.toLowerCase());

              let customerRes = await Api.getCustomerByEmail(secretKey, customer.email);

              if (customerRes.status === 200) {
                customer = {
                  id: customerRes.data.id,
                  name: customerRes.data.metadata ? customerRes.data.metadata.name ? customerRes.data.metadata.name : name : name,
                  email: customerRes.data.email,
                  phone: requestPhone ? getPhoneNumber() : undefined,
                };
                setBankCustomer(customer);
              } else {
                customerRes = await Api.createCustomer(
                  secretKey,
                  version,
                  customer.email,
                  customer.name || `${merchant} customer`,
                  requestPhone ? getPhoneNumber() : undefined,
                  `${merchant} customer`
                );

                if (customerRes.status === 201) {
                  customer = {
                    id: customerRes.data.id,
                    name: customerRes.data.metadata.name,
                    email: customerRes.data.email,
                    phone: customerRes.data.mobile_number,
                  };
                  setBankCustomer(customer);
                } else {
                  setError(Array.isArray(customerRes.data.detail) ? customerRes.data.detail[0].msg : customerRes.data.detail || 'We are unable to process this transaction at this time.');
                  setErrorModalVisible(true);
                  setLoading(false);
                  setComplete(false);
                  return;
                }
              }

              const updateSessionRes = await Api.updateSession(
                secretKey,
                version,
                session.id,
                customer,
                null,
                shipping ? getShippingAddress() : null,
                getBillingAddress()
              );

              if (updateSessionRes.status === 200) {
                const data = {
                  payment_method: 'bank',
                  payment_type: 'bpi',
                  is_quick_pay: false
                };

                const redirectUrl = `${BASE_URL}/${session.id}?d=${Buffer.from(encodeURIComponent(JSON.stringify(data)), 'utf8').toString('base64')}`;

                console.log({redirectUrl});

                const loginRes = await Api.createBankLogin(secretKey, '2', code, customer.id, redirectUrl);

                if (loginRes.status === 200) {
                  if (loginRes.data.redirect) {
                    console.log('redirect', loginRes.data.redirect);
                    window.location = loginRes.data.redirect;
                  } else {
                    setBankErrorMsg('We are unable to process this transaction at this time.');
                    setBankError(true);
                    setLoading(false);
                    setComplete(true);
                    Sentry.captureException(new Error('Missing redirect in login response'), {
                      extra: { data: loginRes }
                    });
                    return;
                  }
                } else {
                  setBankErrorMsg(Array.isArray(loginRes.data.detail) ? loginRes.data.detail[0].msg : loginRes.data.detail || 'We are unable to process this transaction at this time.');
                  setBankError(true);
                  setLoading(false);
                  setComplete(true);
                  Sentry.captureException(new Error('Failed to initiate BPI Login'), {
                    extra: { data: loginRes }
                  });
                  return;
                }
              } else {
                setBankError(true);
                setBankErrorMsg(updateSessionRes.data.message || 'We are unable to process this transaction at this time.');
                setLoading(false);
                setComplete(true);
              }

              /* const data = `session_id=${session.id}&key=${secretKey}&merchant=${StringUtils.truncate(merchant, 22, false).replace(/[^a-zA-Z0-9 ]/g, '')}&total=${total}&currency=${currency}&description=${desc}&customer=${customer.id}&code=${code}&submit=${submitType}`;

              console.log('params', data);

              const bankUrl = `${window.location.origin}/bank/v2/bpi/checkout?d=${Buffer.from(encodeURIComponent(data), 'utf8').toString('base64')}`;

              console.log({bankUrl});

              setBankFrameUrl(bankUrl);
              setBankFrameLoading(true);
              setBankModalVisible(true); */


            } else {
              if (customer.id === null) {
                const customerRes = await Api.createCustomer(
                  secretKey,
                  version,
                  customer.email,
                  customer.name || `${merchant} customer`,
                );
      
                if (customerRes.status === 201) {
                  customer = {
                    id: customerRes.data.id,
                    name: customerRes.data.description,
                    email: customerRes.data.email,
                  };
                  setBankCustomer(customer);
                } else {
                  setError(customerRes.data.message || 'We are unable to process this transaction at this time.');
                  setErrorModalVisible(true);
                  setLoading(false);
                  setComplete(false);
                  return;
                }
              }

              let desc = description;
              if (bankCode !== 'bpi2cash') {
                desc = StringUtils.truncate(desc, 32, false)
              }
  
              const data = `key=${secretKey}&merchant=${StringUtils.truncate(merchant, 22, false).replace(/[^a-zA-Z0-9 ]/g, '')}&total=${total}&currency=${currency}&livemode=${livemode}&description=${desc}&submit=${submitType}`;
              const code = !!bankCode ? bankCode : CardUtils.getBankCode(bank.toLowerCase());
              
              const bankUrl = `${BASE_URL}/bank/${bank.toLowerCase()}/checkout?d=${Buffer.from(encodeURIComponent(data), 'utf8').toString('base64')}`;
              console.log({bankUrl})
              const bankRes = await Api.createBankLogin(
                secretKey,
                version,
                code,
                customer.id,
                bankUrl,
                // `${window.location.origin}/bank/${bank.toLowerCase()}/checkout?d=${btoa(unescape(encodeURIComponent(data)))}`
              );
              // console.log('bankRes', bankRes.data);
              if (bankRes.status === 200) {
                //console.log('bank url', bankRes.data.redirect);
                setBankFrameUrl(bankRes.data.redirect);
                setBankFrameLoading(true);
                setBankModalVisible(true);
              } else {
                setBankError(true);
                setBankErrorMsg(bankRes.data.message || 'We are unable to process this transaction at this time.');
                setLoading(false);
                setComplete(false);
              }
            }
            
          } else {
            //request URL from Brankas
            const code = !!bankCode ? bankCode : CardUtils.getBankCode(bank.toLowerCase());

            const updateSessionRes = await Api.updateSession(
              secretKey,
              version,
              session.id,
              customer,
              null,
              shipping ? getShippingAddress() : null,
              getBillingAddress()
            )
            //console.log('update', updateSessionRes);
            if (updateSessionRes.status === 200) {
              const data = {
                payment_method: 'bank',
                payment_type: bank,
                is_quick_pay: false
              };

              const redirectUrls = {
                successUrl: `${BASE_URL}/${session.id}?d=${Buffer.from(encodeURIComponent(JSON.stringify(data)), 'utf8').toString('base64')}`,
                failUrl: `${BASE_URL}/${session.id}?d=${Buffer.from(encodeURIComponent(JSON.stringify(data)), 'utf8').toString('base64')}`,
                notifyUrl: `${BASE_URL}/api/v2/sessions/${session.id}/notify`,
              };

              const sourceRes = await Api.createSource(
                publicKey,
                version,
                total,
                currency,
                version === '2' && code === 'magpiebpi' ? 'bpi' : code,
                redirectUrls,
                undefined,
                {
                  name: customer.name ? StringUtils.sanitize(customer.name) : customer.email,
                  mobile_number: customer.phone ? StringUtils.sanitize(customer.phone) : null,
                  address_line1: StringUtils.sanitize(billingAddressLine1 ? billingAddressLine1 : shippingAddressLine1 ? shippingAddressLine1 : null),
                  address_line2: StringUtils.sanitize(billingAddressLine2 ? billingAddressLine2 : shippingAddressLine2 ? shippingAddressLine2 : null),
                  address_city: StringUtils.sanitize(billingAddressCity ? billingAddressCity : shippingAddressCity ? shippingAddressCity : null),
                  address_state: StringUtils.sanitize(billingAddressState ? billingAddressState : shippingAddressState ? shippingAddressState : null),
                  address_country: StringUtils.sanitize(billingCountry ? billingCountry : shippingCountry ? shippingCountry : null),
                  address_zip: StringUtils.sanitize(billingAddressPostalCode ? billingAddressPostalCode : shippingAddressPostalCode ? shippingAddressPostalCode : null)
                }
              );
            
              //console.log('source', sourceRes);
              if (sourceRes.status === 200 || sourceRes.status === 201) {
                const chargeRes = await Api.createCharge(
                  secretKey,
                  version,
                  total,
                  currency,
                  sourceRes.data.id,
                  StringUtils.truncate(description, 25).replace(/[^a-zA-Z0-9# ]/g, ''),
                  StringUtils.truncate(merchant, 22, false).replace(/[^a-zA-Z0-9 ]/g, ''),
                  true,
                  undefined,
                  { ...metadata, session_id: session.id },
                  undefined,
                );
                //console.log('charge', chargeRes);
                if (chargeRes.status === 201) {
                  if (version === '2') {
                    if (chargeRes.data.action.type === 'redirect_to_url') {
                      window.location.href = chargeRes.data.action.url;
                    } else {
                      //console.log('Unsupported action type:', chargeRes.data.action.type);
                      setBankErrorMsg(`Unsupported action type: ${chargeRes.data.action.type}`);
                      setBankError(true);
                      setLoading(false);
                      setComplete(false);
                    }
                  } else {
                    window.location.href = chargeRes.data.checkout_url;
                  }
                  
                } else {
                  if (version === '2') {
                    setBankErrorMsg(Array.isArray(chargeRes.data.detail) ? chargeRes.data.detail[0].msg : chargeRes.data.detail || 'We are unable to process this transaction at this time.');
                  } else {
                    setBankErrorMsg(chargeRes.data.message || 'We are unable to process this transaction at this time.');
                  }
                  setBankError(true);
                  setLoading(false);
                  setComplete(false);
                }
              } else {
                if (version === '2') {
                  setBankErrorMsg(Array.isArray(sourceRes.data.detail) ? sourceRes.data.detail[0] : sourceRes.data.detail || 'We are unable to process this transaction at this time.');
                } else {
                  setBankErrorMsg(sourceRes.data.message || 'We are unable to process this transaction at this time.');
                }
                setBankError(true);
                setLoading(false);
                setComplete(false);
              }
            } else {
              setBankError(true);
              setBankErrorMsg(updateSessionRes.data.message || 'We are unable to process this transaction at this time.');
              setLoading(false);
              setComplete(false);
            }
          }
          
        } else {
          setBankError(true);
          setBankErrorMsg(`${CardUtils.getBankName(bank)} is not yet supported`);
          setLoading(false);
          setComplete(false);
        }

      } else if (!complete) {
        if (!email) setEmailMissing(true);
        if (requestPhone && !phone) setPhoneMissing(true);
        if (!bank) setBankMissing(true);

        if (shipping) {
          checkShippingDetails();
          if (billing) {
            if (!sameBillingAsShipping) {
              if (!name) setNameMissing(true);
              checkBillingDetails();
            }
          }
        } else {
          if (billing) {
            if (!name) setNameMissing(true);
            checkBillingDetails();
          }
        }
      }
    } catch (e) {
      console.log('error', e);
      Sentry.captureException(e);
      setLoading(false);
      setBankError(true);
      setBankErrorMsg('We are unable to process this transaction at this time.');
      setComplete(false);
    }
  }

  const getBillingAddress = () => {
    let billingAddress = {};

    if (name) {
      billingAddress = {
        ...billingAddress,
        name,
      }
    }

    if (shipping) {
      if (sameBillingAsShipping) {
        billingAddress = {
          ...billingAddress,
          name: shippingName,
          country: shippingCountry
        };

        if (billing) {
          billingAddress = {
            ...billingAddress,
            country: shippingCountry,
            line_1: shippingAddressLine1,
            line_2: shippingAddressLine2,
            suburb: shippingAddressSuburb,
            city: shippingAddressCity,
            postal_code: shippingAddressPostalCode,
            state: shippingAddressState
          };
        }
      } else {
        if (billing) {
          billingAddress = {
            ...billingAddress,
            country: billingCountry,
            line_1: billingAddressLine1,
            line_2: billingAddressLine2,
            suburb: billingAddressSuburb,
            city: billingAddressCity,
            postal_code: billingAddressPostalCode,
            state: billingAddressState
          };
        }
      }
    } else {
      if (billing) {
        billingAddress = {
          ...billingAddress,
          country: billingCountry,
          line_1: billingAddressLine1,
          line_2: billingAddressLine2,
          suburb: billingAddressSuburb,
          city: billingAddressCity,
          postal_code: billingAddressPostalCode,
          state: billingAddressState
        };
      }
    }
    return billingAddress;
  }

  const getShippingAddress = () => {
    let shippingAddress = {};
    if (shipping) {
      shippingAddress = {
        ...shippingAddress,
        name: shippingName,
        country: shippingCountry,
        line_1: shippingAddressLine1,
        line_2: shippingAddressLine2,
        suburb: shippingAddressSuburb,
        city: shippingAddressCity,
        postal_code: shippingAddressPostalCode,
        state: shippingAddressState
      }
    } else {
      shippingAddress = null;
    }
    return shippingAddress;
  }

  const cardFrameLoadHandler = async () => {
    setCardFrameLoading(false);
    try {
      const window = cardFrameRef.current.contentWindow;
      if (window) {
        const location = window.location;
        if (location) {
          setCardFrameNav(location.href);
          if (location.href.includes(origin)) {
            setCardNavVisible(false);
          }
          console.log('iframe url:', location.href);
          if (location.href.includes(origin) && location.href.includes("/card") && !location.href.includes("/3ds-test")) {
            setTimeout(async () => {
              try {
                const params = new URLSearchParams(location.search);
                if (params.get('charge_id')) {
                  
                  const chargeId = params.get('charge_id');
                  const chargeRes = await Api.getCharge(secretKey, version, chargeId);
                  if (chargeRes.status === 200) {
                    console.log('charge', chargeRes.data)
                    if (chargeRes.data.status !== 'failed') {
                      if (version === '2') {
                        if (mode === 'payment') {
                          if (chargeRes.data.captured) {
                            if (saveInfoChecked || updateSavedInfo) {
                              const customerData = await getCustomerData();
                              if (customerData) {
                                await saveCustomerPaymentInfo(customerData);
                              }
                              await updatePaymentSession({
                                id: customerData.id,
                                name: customerData.metadata ? customerData.metadata.name ? customerData.metadata.name : name : name,
                                email: customerData.email,
                                phone: customerData.mobile_number,
                              }, chargeRes.data);
                            } else {
                              await updatePaymentSession(cardCustomer, chargeRes.data);
                            }
                          } else {
                            setCardNumberError(true);
                            setCardNumberErrorMsg('Payment authorization failed');
                            setLoading(false);
                            setComplete(false);
                            setCardModalVisible(false);
                          }
                        } else if (mode === 'setup') {
                          if (chargeRes.data.authorized) {
                            if (saveInfoChecked || updateSavedInfo) {
                              const customerData = await getCustomerData();
                              if (customerData) {
                                await saveCustomerPaymentInfo(customerData);
                              }
                              await updatePaymentSession({
                                id: customerData.id,
                                name: customerData.metadata ? customerData.metadata.name ? customerData.metadata.name : name : name,
                                email: customerData.email,
                                phone: customerData.mobile_number,
                              }, chargeRes.data);
                            } else {
                              await updatePaymentSession(cardCustomer, chargeRes.data);
                            }
                          } else {
                            setCardNumberError(true);
                            setCardNumberErrorMsg('Payment authorization failed');
                            setLoading(false);
                            setCardModalVisible(false);
                          }
                        }
                        
                      } else {
                        await updatePaymentSession(cardCustomer, chargeRes.data);
                      }
                    } else {
                      setCardNumberError(true);
                      setCardNumberErrorMsg('Payment authorization failed');
                      setLoading(false);
                      setCardModalVisible(false);
                    }
                    
                  } else {
                    setCardNumberError(true);
                    if (version === '2') {
                      setCardNumberErrorMsg(Array.isArray(chargeRes.data.detail) ? chargeRes.data.detail[0].msg : chargeRes.data.detail || 'We are unable to process this transaction at this time.');
                    } else {
                      setCardNumberErrorMsg(chargeRes.data.message || 'We are unable to process this transaction at this time.');
                    }
                    setLoading(false);
                    setCardModalVisible(false);
                  }
                } else if (params.get('error')) {
                  setCardNumberError(true);
                  setCardNumberErrorMsg(params.get('error'));
                  setLoading(false);
                  setCardModalVisible(false);
                } else {
                  setCardNumberError(true);
                  setCardNumberErrorMsg('charge_id is missing');
                  setLoading(false);
                  setCardModalVisible(false);
                }
              } catch (e) {
                console.log(e);
                Sentry.captureException(e);
              }
            }, 1000);
          }
        }
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  }

  const closeCardModalHandler = () => {
    setCardModalVisible(false);
    setLoading(false);
  }

  const walletFrameLoadHandler = async () => {
    setWalletFrameLoading(false);
    //console.log('iframe loading url...')
    try {
      //console.log('iframe', walletFrameRef.current.contentDocument);
      const window = walletFrameRef.current.contentWindow;
      //console.log('window', window);
      if (window) {
        const location = window.location;
        console.log('url', location.href);
        if (location) {
          setWalletFrameNav(location.href);
          if (location.href.includes(origin)) {
            setWalletNavVisible(false);
          }

          setTimeout(async () => {
            try {
              //console.log('params', location.search);
              const params = new URLSearchParams(location.search);
              if (params.get('charge_id')) {
                const chargeId = params.get('charge_id');
                const chargeRes = await Api.getCharge(secretKey, version, chargeId);
                if (chargeRes.status === 200) {
                  setWalletModalVisible(false);
                  
                  const updateRes = await Api.updateSessionPayment(
                    secretKey,
                    version,
                    session.id,
                    walletCustomer,
                    chargeRes.data,
                    shipping ? getShippingAddress() : null,
                    getBillingAddress()
                  );
        
                  if (updateRes.status === 200) {
                    if (isQuickPay) {
                      setQuickPayLoading(false);
                      setQuickPaySuccess(true);
                    } else {
                      setLoading(false);
                      setSuccess(true);
                    }
        
                    timerRef.current = setTimeout(() => {
                      onSuccessPayment(session.id);
                    }, 800);
                  } else {
                    if (isQuickPay) {
                      setError(updateRes.data.message);
                      setErrorModalVisible(true);
                      setQuickPayLoading(false);
                      setIsQuickPay(false);
                    } else {
                      setWalletError(true);
                      setWalletErrorMsg(updateRes.data.message);
                      setLoading(false);
                      setComplete(false);
                    }
                  }
                } else {
                  if (isQuickPay) {
                    if (version === '2') {
                      setError(Array.isArray(chargeRes.data.detail) ? chargeRes.data.detail[0].msg : chargeRes.data.detail || 'We are unable to process this transaction at this time.');
                    } else {
                      setError(chargeRes.data.message || 'We are unable to process this transaction at this time.');
                    }
                    setErrorModalVisible(true);
                    setQuickPayLoading(false);
                    setIsQuickPay(false);
                  } else {
                    setWalletError(true);
                    if (version === '2') {
                      setWalletErrorMsg(Array.isArray(chargeRes.data.detail) ? chargeRes.data.detail[0].msg : chargeRes.data.detail || 'We are unable to process this transaction at this time.');
                    } else {
                      setWalletErrorMsg(chargeRes.data.message || 'We are unable to process this transaction at this time.');
                    }
                    setLoading(false);
                    setComplete(false);
                  }
                }
              } else {
                if (isQuickPay) {
                  setError(`Payment failed using ${CardUtils.getWalletName(quickPayMethod)} wallet`);
                  setErrorModalVisible(true);
                  setQuickPayLoading(false);
                  setIsQuickPay(false);
                } else {
                  setWalletError(true);
                  setWalletErrorMsg(`Payment failed using ${CardUtils.getWalletName(wallet)} wallet`);
                  setLoading(false);
                  setComplete(false);
                }
              }
            } catch (e) {
              console.log(e);
              Sentry.captureException(e);
            }
          }, 1000);
        }
      }
    } catch (e) {
      console.log('iframe error', e.message);
      Sentry.captureException(e);
    }
  }

  const closeWalletModalHandler = () => {
    setWalletModalVisible(false);
    setQuickPayLoading(false);
    setIsQuickPay(false);
    setLoading(false);
  }

  const bankFrameLoadHandler = async () => {
    setBankFrameLoading(false);
    try {
      const window = bankFrameRef.current.contentWindow;
      if (window) {
        const location = window.location;
        if (location) {
          console.log('location', location);
          setBankFrameNav(location.href);
          if (location.href.includes(origin)) {
            setBankNavVisible(false);
          }

          if (location.href.includes('charge_id')) {
            await setTimeout(async () => {
              try {
                const params = new URLSearchParams(location.search);
                if (params.get('charge_id')) {
                  const chargeId = params.get('charge_id');
                  const chargeRes = await Api.getCharge(secretKey, version, chargeId);
                  if (chargeRes.status === 200) {
                    setBankModalVisible(false);
                    
                    const updateRes = await Api.updateSessionPayment(
                      secretKey,
                      version,
                      session.id,
                      bankCustomer,
                      chargeRes.data,
                      shipping ? getShippingAddress() : null,
                      getBillingAddress()
                    );
          
                    if (updateRes.status === 200) {
                      if (isQuickPay) {
                        setQuickPayLoading(false);
                        setQuickPaySuccess(true);
                      } else {
                        setLoading(false);
                        setSuccess(true);
                      }
          
                      timerRef.current = setTimeout(() => {
                        onSuccessPayment(session.id);
                      }, 800);
                    } else {
                      if (isQuickPay) {
                        setError(updateRes.data.message);
                        setErrorModalVisible(true);
                        setQuickPayLoading(false);
                        setIsQuickPay(false);
                      } else {
                        setBankError(true);
                        setBankErrorMsg(updateRes.data.message);
                        setLoading(false);
                        setComplete(false);
                      }
                    }
                  } else {
                    if (isQuickPay) {
                      if (version === '2') {
                        setError(Array.isArray(chargeRes.data.detail) ? chargeRes.data.detail[0].msg : chargeRes.data.detail || 'We are unable to process this transaction at this time.');
                      } else {
                        setError(chargeRes.data.message || 'We are unable to process this transaction at this time.');
                      }
                      setErrorModalVisible(true);
                      setQuickPayLoading(false);
                      setIsQuickPay(false);
                    } else {
                      setBankError(true);
                      if (version === '2') {
                        setBankErrorMsg(Array.isArray(chargeRes.data.detail) ? chargeRes.data.detail[0].msg : chargeRes.data.detail || 'We are unable to process this transaction at this time.');
                      } else {
                        setBankErrorMsg(chargeRes.data.message || 'We are unable to process this transaction at this time.');
                      }
                      setLoading(false);
                      setComplete(false);
                    }
                  }
                } else {
                  if (isQuickPay) {
                    setError(`Payment failed using ${CardUtils.getBankName(quickPayMethod)}`);
                    setErrorModalVisible(true);
                    setQuickPayLoading(false);
                    setIsQuickPay(false);
                  } else {
                    setBankError(true);
                    setBankErrorMsg(`Payment failed using ${CardUtils.getBankName(bank)}`);
                    setLoading(false);
                    setComplete(false);
                  }
                }


              } catch (e) {
                console.log(e);
                Sentry.captureException(e);
              }
            }, 1000);
          }
        }
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  }

  const closeBankModalHandler = () => {
    setBankModalVisible(false);
    setQuickPayLoading(false);
    setIsQuickPay(false);
    setLoading(false);
  }

  const closeErrorModalHandler = () => {
    setErrorModalVisible(false);
    setError('');
    setQuickPayLoading(false);
  }

  return (
    <div
      className={cn("w-full pt-6 px-4 pb-4 bg-white shadow-payment lg:h-full", {
        'lg:pt-0 lg:p-0 lg:m-0 lg:w-95 lg:shadow-none': mode !== 'save_card',
        'lg:p-8 lg:rounded-xl lg:shadow-container lg:my-6': mode === 'save_card',
      })}
      // style={{ height: mode === 'save_card' ? '100%' : formHeight }}
    >
      <main
        // className="payment p-4 pt-6 w-full bg-white shadow-payment transition-height duration-300 ease lg:pt-0 lg:p-0 lg:m-0 lg:shadow-none lg:w-95 lg:mb-0"
        // style={{ height: paymentHeight }}
        className="my-0 mx-auto max-w-95"
      >
        <div className={cn("payment-header relative lg:max-w-95 my-0 mx-auto", { 'show-quick-pay': loaded && !!quickPay })} style={ !quickPay && hasOtherPaymentMethods() ? { height: 0 } : {}}>
          <div className={cn("payment-title w-full", { 
              'opacity-0': !loaded,
              'block opacity-100': loaded && (!quickPay && !hasOtherPaymentMethods()),
              'is-hidden': !!quickPay || hasOtherPaymentMethods()
            })}>
            <div className="pb-4">
              <div className={cn("m-0 font-medium", {
                'text-lg': mode === 'save_card',
                'text-xl': mode !== 'save_card',
              })}>
                {
                  mode === 'subscription'
                  ? 'Payment information'
                    : mode === 'save_card'
                      ? 'Save card information'
                      : showCard 
                        ? `${CardUtils.getSubmitLabel(submitType)} with card` 
                        : (banks.length === 1 
                          ? `${CardUtils.getSubmitLabel(submitType)} with ${CardUtils.getBankPayTitle(banks[0])}` 
                          : (eWallets.length === 1 
                            ? `${CardUtils.getSubmitLabel(submitType)} with ${CardUtils.getWalletPayTitle(eWallets[0])}` 
                            : 'Payment information'))
                }
              </div>
            </div>
          </div>
          <div className={cn("payment-title w-full hidden opacity-0", { 'is-visible': loaded && !!quickPay })}>
            {
              !!quickPay && <div className="mb-4">
                <button disabled={loading || success || quickPayLoading || quickPaySuccess} onClick={(e) => onQuickPayClick(e)} className={cn("quick-pay relative overflow-hidden h-11 w-full mt-3 p-0 shadow-input bg-white border-0 outline-none rounded-md cursor-pointer transitions-pay-button backface-visibility-hidden focus:outline-none", { 
                  'active:shadow-pay-focus': !quickPayLoading && !quickPaySuccess,
                  'hover:shadow-pay-hover active:transform active:scale-99': !quickPayLoading && !quickPaySuccess,
                  'bg-success success': quickPaySuccess 
                })} style={{ backgroundColor: `${quickPaySuccess ? '' : CardUtils.getButtonColor(quickPayMethod)}`, color: `${ColorUtils.isDark(buttonColor) ? ColorUtils.hex2rgba('#fff') : '#fff'}` }}>
                  <div className={quickPaySuccess ? 'opacity-0 transform -translate-x-4' : ''}>
                    <img className="inline h-7 w-auto" src={CardUtils.getLogo(quickPayMethod)} alt="" />
                  </div>
                  <div className="icon-container absolute top-0 left-0 h-full w-full transform -translate-x-4 transition-transform duration-300 ease">
                    <div className={cn("spinner-icon flex absolute top-1/2 right-0 transform -translate-y-1/2 opacity-0 transition-all duration-200", { 'opacity-0 transform -translate-y-1/2 scale-90': !quickPayLoading, 'opacity-100': quickPayLoading, 'opacity-100 transform -translate-y-1/2 scale-130 duration-300 post-center': quickPaySuccess })}>
                      <div className="w-5 h-5">
                        <svg className="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false">
                          <ellipse className={cn("origin-center animate-spinner-spin", { 'animate-spinner-complete': quickPaySuccess })} cx="12" cy="12" rx="10" ry="10" style={{ stroke: 'rgb(255, 255, 255)' }}></ellipse>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className={cn("checkmark-icon absolute top-1/2 left-1/2", { 'current': quickPaySuccess })}>
                    <div className="h-4">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" focusable="false">
                        <path d="M 0.5 6 L 8 13.5 L 21.5 0" fill="transparent" strokeWidth="2" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"></path>
                      </svg>
                    </div>
                  </div>
                </button>
              </div>
            }
            <div className="relative flex items-center overflow-hidden pt-4 pb-8">
              <hr className="w-full h-px border-none bg-gray-200 my-0.5em"/>
              <p className="m-0 text-sm font-normal text-gray-400 absolute left-1/2 transform -translate-x-1/2 py-0 px-2 whitespace-no-wrap bg-white">
                {getSeparatorLabel()}
                {
                  /* quickPay
                  ? showCard 
                    ? `Or ${CardUtils.getSubmitLabel(submitType).toLowerCase()} with card`
                    : (quickPay !== 'bank' && banks.length === 1)
                      ? `Or ${CardUtils.getSubmitLabel(submitType).toLowerCase()} with bank`
                      : (quickPay !== 'wallet' && wallet.length === 1)
                        ? `Or ${CardUtils.getSubmitLabel(submitType).toLowerCase()} with wallet`
                        : `Or ${CardUtils.getSubmitLabel(submitType).toLowerCase()} another way`
                  : '' */
                }

                {/* { quickPay ? showCard ? 'Or pay with card' : (banks.length === 1 ? 'Or pay with bank' : (eWallets.length === 1 ? 'Or pay with wallet' : 'Or pay another way')) : 'Or pay another way' } */}
              </p>
            </div>
          </div>
        </div>
        <form
          ref={payRef}
          noValidate
          className={cn({'lg:min-h-50': mode !== 'save_card'})}
          style={{ height: mode === 'save_card' ? 'auto' : paymentHeight }}
        >
          <div className="box-border flex flex-row -m-2 flex-wrap mb-2">
            {
              shipping &&
              <div className="box-border flex-initial min-w-0 max-w-full p-2">
                <h2 className="m-0 text-base font-medium text-gray-600 mt-0">Shipping information</h2>
              </div>
            }
            {
              !shipping && !quickPay && hasOtherPaymentMethods() &&
              <div className="box-border flex-initial min-w-0 max-w-full p-2">
                <h2 className="m-0 text-base font-medium text-gray-600 mt-0">Contact information</h2>
              </div>
            }

            {/* Saved payment information */}
            {showCard && savedInfo.length > 0 ? (
              <div className="flex-initial min-w-0 max-w-full w-full p-2">
                {paymentMethod === 'card' && useSavedInfo ? (
                  <div className="flex flex-no-wrap justify-between mb-2">
                    <h3 className="m-0 text-13px font-medium text-modal-header">Saved payment information</h3>
                    <button type="button" className="cursor-pointer text-xs p-0 text-input bg-transparent border-none outline-none rounded-none focus:outline-none leading-tight transition-button" onClick={onLogout}>
                      <div className="flex flex-no-wrap justify-center items-center">Log out</div>
                    </button>
                  </div>
                ) : null}
                <div className="bg-readonly mt-1 rounded-md overflow-hidden shadow-input">
                  <div className="p-3 flex border-none">
                    <div className="w-3/10 text-label text-sm">
                      <span className="m-0 text-13px font-medium text-modal-header">{requestPhone ? `Contact` : `Email`}</span>
                    </div>
                    <div className="flex-1 min-w-0">
                      <div className="flex justify-between">
                        <div className="text-sm text-input truncate">{customerFound.email}</div>
                        <div className="flex flex-basis-auto flex-shrink-0 text-xs items-center">
                          {paymentMethod !== 'card' || !useSavedInfo ? (
                            <button type="button" className="cursor-pointer text-xs p-0 text-magpie bg-transparent border-none outline-none rounded-none focus:outline-none leading-tight transition-button" onClick={onLogout}>
                              <div className="flex flex-no-wrap justify-center items-center">
                                <span className="m-0 font-medium truncate">Log out</span>
                              </div>
                            </button>
                          ) : null}
                        </div>
                      </div>
                      {requestPhone ? (
                        <div className="mt-3">
                          <span className="m-0 text-13px text-gray-900 inline-block pb-1">Enter phone number</span>
                          <div className="flex-initial min-w-0 max-w-full w-full">
                            <div className="relative">
                              <div>
                                <div className="relative">
                                  <div className="relative">
                                    <div className="group pointer-events-none absolute z-3 top-1/2 left-3 transform -translate-y-1/2">
                                      <div className="cursor-pointer h-4 pointer-events-none relative w-4">
                                        <img className="absolute left-0 top-0 z-3 w-4 h-4" src={`/images/flags/${countryCode}.svg`} alt={countryCode} />
                                        <select disabled={loading || success || quickPayLoading || quickPaySuccess} className={cn("absolute left-0 top-0 h-0 w-0 z-2 py-2 pl-2 pr-3 pointer-events-auto appearance-none rounded-none border-none outline-none", {
                                          'input-disabled': loading || success || quickPayLoading || quickPaySuccess
                                        })} onChange={(e) => onCountryCodeSelected(e)} value={countryCode}>
                                          {countryCodes.map((grp, index) => (
                                            <optgroup key={index} label={grp.group}>
                                              {grp.countries.map((c) => (
                                                <option key={c.value} value={c.value}>{c.label}</option>
                                              ))}
                                            </optgroup> 
                                          ))}
                                        </select>
                                        <svg className="text-input-svg absolute -bottom-px cursor-pointer h-2 w-2 -right-0.5 z-4 group-hover:text-input" focusable="false" fill="currentColor" viewBox="0 0 16 16"><path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm2.7928932-9.70710678-2.7928932 2.79289322-2.79289322-2.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l3.5 3.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l3.50000002-3.50000002c.3905243-.39052429.3905243-1.02368927 0-1.41421356s-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path></svg>
                                      </div>
                                    </div>
                                    <span className="relative block m-0 p-0">
                                      <input disabled={loading || success || quickPayLoading || quickPaySuccess} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 rounded-md text-indent-6 shadow-input transitions-input bg-white appearance-none lg:h-9 lg:text-sm lg:transform lg:rotate-0", {
                                        'invalid': phoneError || phoneMissing,
                                        'input-disabled': loading || success
                                      })} autoComplete="tel" autoCorrect="off" spellCheck="false" id="phoneNumber" name="phoneNumber" type="tel" aria-invalid={phoneError || phoneMissing} placeholder={CountryUtils.getMobilePlaceholder(countryCode)} value={phone || ''} onChange={(e) => onPhoneChanged(e)} onBlur={(e) => onPhoneBlur(e)} />
                                    </span>
                                  </div>
                                  <div className="pointer-events-none absolute z-3 top-1/2 transform -translate-y-1/2 flex items-center right-3">
                                    <div data-tip="In case we need to contact you about your order." data-type="light" data-effect="solid" data-place="top" className="pointer-events-auto ml-2">
                                      <svg className={cn("w-3 h-3", {
                                        'text-red-500': phoneError || phoneMissing,
                                        'text-input-svg': !phoneError && !phoneMissing
                                      })} focusable="false" viewBox="0 0 12 12" fill="none">
                                        <path d="M6 12C9.28235 12 12 9.28235 12 6C12 2.72353 9.27647 0 5.99412 0C2.71765 0 0 2.72353 0 6C0 9.28235 2.72353 12 6 12ZM6 11C3.22353 11 1.00588 8.77647 1.00588 6C1.00588 3.22941 3.21765 1 5.99412 1C8.77059 1 10.9941 3.22941 11 6C11.0059 8.77647 8.77647 11 6 11ZM5.94706 3.90588C6.37647 3.90588 6.71177 3.56471 6.71177 3.14118C6.71177 2.71176 6.37647 2.37059 5.94706 2.37059C5.52353 2.37059 5.18235 2.71176 5.18235 3.14118C5.18235 3.56471 5.52353 3.90588 5.94706 3.90588ZM4.97059 9.23529H7.36471C7.60588 9.23529 7.79412 9.06471 7.79412 8.82353C7.79412 8.59412 7.60588 8.41177 7.36471 8.41177H6.63529V5.41765C6.63529 5.1 6.47647 4.88824 6.17647 4.88824H5.18235C4.94118 4.88824 4.75294 5.07059 4.75294 5.3C4.75294 5.54118 4.94118 5.71176 5.18235 5.71176H5.7V8.41177H4.97059C4.72941 8.41177 4.54118 8.59412 4.54118 8.82353C4.54118 9.06471 4.72941 9.23529 4.97059 9.23529Z" fill="currentColor"></path>
                                      </svg>
                                    </div>
                                    {tooltipVisible && <ReactTooltip className="tooltip text-xs font-normal text-input proportional-nums whitespace-normal" />}
                                  </div>
                                </div>
                                <AnimateHeight
                                  height={phoneErrorMsg ? 'auto' : 0}
                                  duration={500}
                                  animateOpacity
                                  className="flex-shrink-0 max-w-full pointer-events-none"
                                >
                                  <span className="m-0 text-13px text-danger">
                                    <span>{phoneErrorMsg}</span>
                                  </span>
                                </AnimateHeight>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {paymentMethod === 'card' && useSavedInfo ? (
                    <div className="p-3 flex border-t border-separator cursor-pointer select-none">
                      <div className="w-3/10 text-label text-sm">
                        <span className="m-0 text-13px font-medium text-modal-header">Pay with</span>
                      </div>
                      <div className="flex-1 min-w-0">
                        <div className="flex flex-col justify-start flex-no-wrap pointer-events-none -mt-4 -ml-4">
                          {savedInfo.length > 1 ? savedInfo.map((source, index) => (
                            <div key={index} className="mt-4 ml-4 pointer-events-auto">
                              <div className="flex flex-row items-center justify-between">
                                <button type="button" className={cn("cursor-pointer text-sm p-0 bg-transparent border-none outline-none rounded-none hover:text-gray-700 focus:outline-none transition-button", {
                                "text-gray-500": selectedSavedInfo.id !== source.id,
                                "text-input": selectedSavedInfo.id === source.id
                                })} onClick={(e) => onSelectSavedInfoSource(e, source)}>
                                  <div className="text-sm truncate pointer-events-none">
                                    <div className="flex flex-row items-center justify-start">
                                      <input type="radio" readOnly checked={selectedSavedInfo.id === source.id} className="cursor-pointer" />
                                      <div className="flex items-center pl-4">
                                        <img src={CardUtils.getCardIcon(source.card.brand)} alt={source.card.brand} className="h-4 w-auto mr-2" />
                                        {`•••• ${source.card.last4}`}
                                      </div>
                                    </div>
                                  </div>
                                </button>
                                <div className="flex flex-basis-auto flex-shrink-0 text-xs items-center">
                                  <button type="button" className="cursor-pointer text-xs p-0 text-magpie bg-transparent border-none outline-none rounded-none focus:outline-none leading-tight transition-colors duration-100 ease-out" onClick={(e) => onRemovePayment(e, source.id)}>
                                    <div className="flex flex-no-wrap justify-center items-center">
                                      <span className="m-0 font-medium truncate">Remove</span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )) : (
                            <div className="mt-4 ml-4 pointer-events-auto">
                              <div className="flex flex-row items-center justify-between">
                                <div className="text-sm text-input truncate">
                                  <div className="flex flex-row items-center justify-start">
                                    <div className="flex items-center">
                                      <img src={CardUtils.getCardIcon(selectedSavedInfo.card.brand)} alt={selectedSavedInfo.card.brand} className="h-4 w-auto mr-2" />
                                      {`•••• ${selectedSavedInfo.card.last4}`}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex flex-basis-auto flex-shrink-0 text-xs items-center">
                                  <button type="button" className="cursor-pointer text-xs p-0 text-magpie bg-transparent border-none outline-none rounded-none focus:outline-none leading-tight transition-colors duration-100 ease-out" onClick={(e) => onRemovePayment(e, selectedSavedInfo.id)}>
                                    <div className="flex flex-no-wrap justify-center items-center">
                                      <span className="m-0 font-medium truncate">Remove</span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          
                          <div className="mt-3 ml-3 pointer-events-auto">
                            <div className="flex flex-basis-auto flex-shrink-0 text-xs items-center">
                              <button type="button" className="cursor-pointer text-xs p-0 text-magpie bg-transparent border-none outline-none rounded-none focus:outline-none leading-tight transition-colors duration-100 ease-out" onClick={onChangePaymentDetails}>
                                <div className="flex flex-no-wrap justify-center items-center">
                                  <span className="m-0 font-medium truncate">Use a different card</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="text-sm text-input-svg">
                          <div></div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <AnimateHeight
                  height={savedInfoErrorMsg ? 'auto' : 0}
                  duration={500}
                  animateOpacity
                  style={{ flexShrink: 0, maxWidth: '100%' }}
                >
                  <span className="m-0 text-13px text-danger">
                    <span>{savedInfoErrorMsg}</span>
                  </span>
                </AnimateHeight>
              </div>
            ) : null}

            {savedInfo.length === 0 ? (
              <div className="box-border flex-initial min-w-0 max-w-full w-full p-2">
                <div>
                  <div className="box-border flex flex-no-wrap justify-between relative">
                    <label htmlFor="email">
                      <span className="m-0 text-13px font-medium text-gray-600">
                        {!shipping && requestPhone ? 'Contact information' : 'Email'}
                      </span>
                    </label>
                    {(emailHasSavedInfo && !loginVerifyModalVisible) ? (
                      <div className="opacity-100 transform-none">
                        <button type="button" className="cursor-pointer text-xs p-0 text-magpie bg-transparent border-none outline-none rounded-none focus:outline-none leading-tight transition-button" onClick={onLogin}>
                          <div className="flex flex-no-wrap justify-center items-center">
                            <span className="m-0 font-medium truncate">Log in</span>
                          </div>
                        </button>
                      </div>
                    ) : null}
                    <div className={cn("transition-all duration-300 ease", { 'transform translate-y-full opacity-0 hidden': !emailMissing, 'transform translate-y-0 block opacity-100': emailMissing })}>
                      <span className="m-0 text-tiny font-medium text-danger uppercase">
                        <span>Required</span>
                      </span>
                    </div>
                  </div>
                  <div className="mt-1 border-none p-0">
                    <div className="relative flex flex-wrap">
                      <div className="box-border flex-initial min-w-0 max-w-full w-full">
                        <div className="relative">
                          {!shipping && requestPhone ? (
                            <div className="pointer-events-none absolute z-3 top-1/2 left-3 transform -translate-y-1/2">
                              <svg className="w-4 h-4 text-input-svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>
                            </div>
                          ) : null}
                          <div className="relative">
                            <span className="relative block m-0 p-0">
                              <input disabled={loading || success || quickPayLoading || quickPaySuccess || mode === 'save_card'} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none lg:h-9 lg:text-sm lg:transform lg:rotate-0", 
                              {
                                'rounded-md': shipping || !requestPhone,
                                'rounded-none rounded-t-md text-indent-6': !shipping && requestPhone,
                                'input-readonly': mode === 'save_card',
                                'invalid': emailError || emailMissing,
                                'input-disabled': loading || success || quickPayLoading || quickPaySuccess,
                              })} autoComplete="email" autoCorrect="off" spellCheck="false" id="email" name="email" type="email" aria-invalid={emailError || emailMissing} placeholder={!shipping && requestPhone ? 'email@example.com' : ''} value={email} onChange={(e) => onEmailChanged(e)} onBlur={(e) => onEmailBlur(e)} />
                            </span>
                          </div>
                          {emailError ? (
                            <div className="pointer-events-none flex items-center absolute z-3 top-0 right-0 h-full pr-3">
                              <svg className="w-4 h-4 text-danger" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                            </div>
                          ) : null}
                          {emailLookupLoading && !emailError ? (
                            <div className="pointer-events-none flex items-center absolute z-3 top-0 right-0 h-full pr-3">
                              <div className="spinner-icon flex">
                                <svg className=" w-3.5 h-3.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false">
                                  <ellipse className="origin-center animate-spinner-spin" cx="12" cy="12" rx="10" ry="10" style={{ stroke: 'rgb(26, 26, 26, 0.5)' }}></ellipse>
                                </svg>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {!shipping && requestPhone ? (
                        <div className="box-border flex-initial min-w-0 max-w-full w-full">
                          <div className="relative">
                            <div>
                              <div className="relative">
                                <div className="relative">
                                  <div className="group pointer-events-none absolute z-3 top-1/2 left-3 transform -translate-y-1/2">
                                    <div className="cursor-pointer h-4 pointer-events-none relative w-4">
                                      <img className="absolute left-0 top-0 z-3 w-4 h-4" src={`/images/flags/${countryCode}.svg`} alt={countryCode} />
                                      <select disabled={loading || success || quickPayLoading || quickPaySuccess} className={cn("absolute left-0 top-0 h-0 w-0 z-2 py-2 pl-2 pr-3 pointer-events-auto appearance-none rounded-none border-none outline-none", {
                                        'input-disabled': loading || success || quickPayLoading || quickPaySuccess
                                      })} onChange={(e) => onCountryCodeSelected(e)} value={countryCode}>
                                        {countryCodes.map((grp, index) => (
                                          <optgroup key={index} label={grp.group}>
                                            {grp.countries.map((c) => (
                                              <option key={c.value} value={c.value}>{c.label}</option>
                                            ))}
                                          </optgroup> 
                                        ))}
                                      </select>
                                      <svg className="text-input-svg absolute -bottom-px cursor-pointer h-2 w-2 -right-0.5 z-4 group-hover:text-input" focusable="false" fill="currentColor" viewBox="0 0 16 16"><path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm2.7928932-9.70710678-2.7928932 2.79289322-2.79289322-2.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l3.5 3.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l3.50000002-3.50000002c.3905243-.39052429.3905243-1.02368927 0-1.41421356s-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path></svg>
                                    </div>
                                  </div>
                                  <span className="relative block m-0 p-0">
                                    <input disabled={loading || success || quickPayLoading || quickPaySuccess} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 rounded-none rounded-b-md text-indent-6 shadow-input transitions-input bg-white appearance-none lg:h-9 lg:text-sm lg:transform lg:rotate-0", {
                                      'invalid': phoneError || phoneMissing,
                                      'input-disabled': loading || success
                                    })} autoComplete="tel" autoCorrect="off" spellCheck="false" id="phoneNumber" name="phoneNumber" type="tel" aria-invalid={phoneError || phoneMissing} placeholder={CountryUtils.getMobilePlaceholder(countryCode)} value={phone || ''} onChange={(e) => onPhoneChanged(e)} onBlur={(e) => onPhoneBlur(e)} />
                                  </span>
                                </div>
                                <div className="pointer-events-none absolute z-3 top-1/2 transform -translate-y-1/2 flex items-center right-3">
                                  <div data-tip="In case we need to contact you about your order." data-type="light" data-effect="solid" data-place="top" className="pointer-events-auto ml-2">
                                    <svg className={cn("w-3 h-3", {
                                      'text-red-500': phoneError || phoneMissing,
                                      'text-input-svg': !phoneError && !phoneMissing
                                    })} focusable="false" viewBox="0 0 12 12" fill="none">
                                      <path d="M6 12C9.28235 12 12 9.28235 12 6C12 2.72353 9.27647 0 5.99412 0C2.71765 0 0 2.72353 0 6C0 9.28235 2.72353 12 6 12ZM6 11C3.22353 11 1.00588 8.77647 1.00588 6C1.00588 3.22941 3.21765 1 5.99412 1C8.77059 1 10.9941 3.22941 11 6C11.0059 8.77647 8.77647 11 6 11ZM5.94706 3.90588C6.37647 3.90588 6.71177 3.56471 6.71177 3.14118C6.71177 2.71176 6.37647 2.37059 5.94706 2.37059C5.52353 2.37059 5.18235 2.71176 5.18235 3.14118C5.18235 3.56471 5.52353 3.90588 5.94706 3.90588ZM4.97059 9.23529H7.36471C7.60588 9.23529 7.79412 9.06471 7.79412 8.82353C7.79412 8.59412 7.60588 8.41177 7.36471 8.41177H6.63529V5.41765C6.63529 5.1 6.47647 4.88824 6.17647 4.88824H5.18235C4.94118 4.88824 4.75294 5.07059 4.75294 5.3C4.75294 5.54118 4.94118 5.71176 5.18235 5.71176H5.7V8.41177H4.97059C4.72941 8.41177 4.54118 8.59412 4.54118 8.82353C4.54118 9.06471 4.72941 9.23529 4.97059 9.23529Z" fill="currentColor"></path>
                                    </svg>
                                  </div>
                                  {tooltipVisible && <ReactTooltip className="tooltip text-xs font-normal text-input proportional-nums whitespace-normal" />}
                                </div>
                              </div>
                              <AnimateHeight
                                height={phoneErrorMsg ? 'auto' : 0}
                                duration={500}
                                animateOpacity
                                className="flex-shrink-0 max-w-full pointer-events-none"
                              >
                                <span className="m-0 text-13px text-danger">
                                  <span>{phoneErrorMsg}</span>
                                </span>
                              </AnimateHeight>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <AnimateHeight
                        height={emailErrorMsg ? 'auto' : 0}
                        duration={500}
                        animateOpacity
                        style={{ flexShrink: 0, maxWidth: '100%' }}
                      >
                        <span className="m-0 text-13px text-danger">
                          <span>{emailErrorMsg}</span>
                        </span>
                      </AnimateHeight>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            
            {
              shipping &&
              <>
                <div className="box-border flex-initial min-w-0 max-w-full w-full p-2">
                  <div>
                    <div className="box-border flex flex-no-wrap justify-between relative">
                      <label htmlFor="shipping-address-fieldset">
                        <span className="m-0 text-13px font-medium text-input">
                          Shipping address
                        </span>
                      </label>
                      <div className={cn("transition-all duration-300 ease", { 'transform translate-y-full opacity-0': !shippingDetailsMissing, 'transform translate-y-0 opacity-100': shippingDetailsMissing })}>
                        <span className="m-0 text-tiny font-medium text-danger uppercase">
                          <span>Required</span>
                        </span>
                      </div>
                    </div>
                    <fieldset id="shipping-address-fieldset" className="mt-1 mx-0 border-none p-0">
                      <div className="relative flex flex-wrap">
                        <div className="box-border flex-initial min-w-0 max-w-full w-full">
                          <div className="relative">
                            <div>
                              <div className="flex relative">
                                <select disabled={loading || success || quickPayLoading || quickPaySuccess} className={cn("checkout-select w-full h-11 text-base py-2 pl-3 pr-6 text-input placeholder-placeholder leading-normal border-0 bg-white shadow-input transitions-input appearance-none rounded-none rounded-t-md lg:h-9 lg:text-sm", {
                                  'invalid': shippingDetailsMissing,
                                  'input-disabled': loading || success || quickPayLoading || quickPaySuccess
                                })} id="shippingCounty" name="shippingCountry" autoComplete="shipping country" aria-label="Country or region" value={shippingCountry} onChange={(e) => onShippingCountryChanged(e)}>
                                  <option value disabled hidden></option>
                                  {
                                    CountryUtils.getCountriesByIso(allowedCountries).map((item, index) => 
                                      <option key={index} value={item.value}>{item.label}</option>
                                    )
                                  }
                                </select>
                                <svg className={cn("absolute top-1/2 right-3 -mt-1.5 pointer-events-none w-3 h-12 z-3", {"hidden" : loading || success || quickPayLoading || quickPaySuccess})} focusable="false" width="12" height="12">
                                  <path d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z" fillRule="evenodd"></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="box-border flex-initial min-w-0 max-w-full w-full">
                          <div className="relative">
                            <span className="relative block m-0 p-0">
                              <input disabled={loading || success || quickPayLoading || quickPaySuccess} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm", {
                                'invalid': shippingNameMissing || shippingDetailsMissing, 'input-disabled': loading || success
                              })} autoComplete="name" autoCorrect="off" spellCheck="false" id="shippingName" name="shippingName" type="text" aria-label="Name" placeholder="Name" aria-invalid="false" value={shippingName} onChange={(e) => onShippingNameChanged(e)} />
                            </span>
                            <div className={cn("form-input-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded': shippingNameMissing })}>
                              <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                            </div>
                          </div>
                        </div>
                        <div className="box-border flex-initial min-w-0 max-w-full w-full">
                          <div className="relative">
                            <span className="relative block m-0 p-0">
                              <input disabled={loading || success || quickPayLoading || quickPaySuccess} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm", {
                                'invalid': shippingAddressLine1Missing || shippingDetailsMissing,
                                'input-disabled': loading || success
                              })} autoComplete="shipping address-line1" autoCorrect="off" spellCheck="false" id="shippingAddressLine1" name="shippingAddressLine1" type="text" aria-label="Address line 1" placeholder="Address line 1" aria-invalid="false" value={shippingAddressLine1} onChange={(e) => onShippingAddressLine1Changed(e)} />
                            </span>
                            <div className={cn("form-input-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded': shippingAddressLine1Missing })}>
                              <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                            </div>
                          </div>
                        </div>
                        <div className="box-border flex-initial min-w-0 max-w-full w-full">
                          <div className="relative">
                            <span className="relative block m-0 p-0">
                              <input disabled={loading || success || quickPayLoading || quickPaySuccess} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm", { 
                                'rounded-b-md': !CountryUtils.hasCity(shippingCountry) && !CountryUtils.hasPostal(shippingCountry),
                                'invalid': shippingDetailsMissing,
                                'input-disabled': loading || success
                              })} autoComplete="shipping address-line2" autoCorrect="off" spellCheck="false" id="shippingAddressLine2" name="shippingAddressLine2" type="text" aria-label="Address line 2" placeholder="Address line 2" aria-invalid="false" value={shippingAddressLine2} onChange={(e) => onShippingAddressLine2Changed(e)} />
                            </span>
                          </div>
                        </div>
                        {
                          CountryUtils.hasSuburb(shippingCountry) &&
                          <div className="box-border flex-initial min-w-0 max-w-full w-full">
                            <div className="relative">
                              <span className="relative block m-0 p-0">
                                <input disabled={loading || success || quickPayLoading || quickPaySuccess} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm", {
                                  'invalid': shippingDetailsMissing || shippingAddressSuburbMissing, 'input-disabled': loading || success
                                })} autoComplete="shipping address-level3" autoCorrect="off" spellCheck="false" id="shippingSuburb" name="shippingSuburb" type="text" aria-label={CountryUtils.getSuburbLabel(shippingCountry)} placeholder={CountryUtils.getSuburbLabel(shippingCountry)} aria-invalid="false" value={shippingAddressSuburb} onChange={(e) => onShippingAddressSuburbChanged(e)} />
                              </span>
                              <div className={cn("form-input-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded': shippingAddressSuburbMissing })}>
                                <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                              </div>
                            </div>
                          </div>
                        }
                        {
                          CountryUtils.hasCity(shippingCountry) &&
                          <div className={cn("box-border flex-initial min-w-0 max-w-full", { 'w-1/2': CountryUtils.hasPostal(shippingCountry), 'w-full': !CountryUtils.hasPostal(shippingCountry) })}>
                            <div className="relative">
                              <span className="relative block m-0 p-0">
                                <input disabled={loading || success || quickPayLoading || quickPaySuccess} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm", 
                                {
                                  'rounded-bl-md': !CountryUtils.hasState(shippingCountry),
                                  'rounded-b-md': !CountryUtils.hasPostal(shippingCountry) && !CountryUtils.hasState(shippingCountry),
                                  'invalid': shippingAddressCityMissing || shippingDetailsMissing, 'input-disabled': loading || success
                                })} autoComplete="shipping address-level2" autoCorrect="off" spellCheck="false" id="shippingCity" name="shippingCity" type="text" aria-label="City" placeholder="City" aria-invalid="false" value={shippingAddressCity} onChange={(e) => onShippingAddressCityChanged(e)} />
                              </span>
                              <div className={cn("form-input-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded': shippingAddressCityMissing })}>
                                <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                              </div>
                            </div>
                          </div>
                        }
                        {
                          CountryUtils.hasPostal(shippingCountry) &&
                          <div className={cn("box-border flex-initial min-w-0 max-w-full", { 'w-1/2': CountryUtils.hasCity(shippingCountry), 'w-full': !CountryUtils.hasCity(shippingCountry) })}>
                            <div className="relative">
                              <span className="relative block m-0 p-0">
                                <input disabled={loading || success || quickPayLoading || quickPaySuccess} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 shadow-input transitions-input bg-white appearance-none rounded-none lg:h-9 lg:text-sm",
                                {
                                  'rounded-br-md': !CountryUtils.hasState(shippingCountry),
                                  'rounded-b-md': !CountryUtils.hasCity(shippingCountry) && !CountryUtils.hasState(shippingCountry),
                                  'invalid': shippingAddressPostalMissing ||  shippingDetailsMissing, 'input-disabled': loading || success
                                })} autoComplete="shipping postal-code" autoCorrect="off" spellCheck="false" id="shippingPostalCode" name="shippingPostalCode" type="text" aria-label={CountryUtils.getPostalLabel(shippingCountry)} placeholder={CountryUtils.getPostalLabel(shippingCountry)} aria-invalid="false" value={shippingAddressPostalCode} onChange={(e) => onShippingAddressPostalChanged(e)} />
                              </span>
                              <div className={cn("form-input-icon pointer-events-none flex items-center absolute right-0 top-0 h-full pr-3 z-3", { 'is-loaded': shippingAddressPostalMissing })}>
                                <svg className="h-3" focusable="false" height="12" viewBox="0 0 12 12" width="12"><g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 12)"><circle cx="6" cy="3.2" fill="#dc2727" r="1"></circle><g stroke="#dc2727"><circle cx="6" cy="6" r="5.5"></circle><path d="m6 9.2v-3.2" strokeLinecap="round" strokeLinejoin="round"></path></g></g></svg>
                              </div>
                            </div>
                          </div>
                        }
                        {
                          CountryUtils.hasState(shippingCountry) && CountryUtils.getStates(shippingCountry).length > 0 &&
                          <div className="box-border flex-initial min-w-0 max-w-full w-full">
                            <div className="relative">
                              <div>
                                <div className="flex relative">
                                  <select disabled={loading || success || quickPayLoading || quickPaySuccess} className={cn("checkout-select w-full h-11 text-base py-2 pl-3 pr-6 leading-normal border-0 bg-white shadow-input transitions-input appearance-none rounded-none lg:h-9 lg:text-sm", {
                                    'invalid': shippingAddressStateMissing || shippingDetailsMissing,
                                    'text-placeholder empty': !shippingAddressState || shippingAddressState === 'true',
                                    'text-input': !!shippingAddressState && shippingAddressState !== 'true',
                                    'rounded-b-md': !requestPhone,
                                    'input-disabled': loading || success || quickPayLoading || quickPaySuccess
                                  })} id="shippingAdministrativeArea" name="shippingAdministrativeArea" autoComplete="shipping address-level1" aria-label={CountryUtils.getStateLabel(shippingCountry)} value={shippingAddressState} onChange={(e) => onShippingAddressStateChanged(e)}>
                                    <option value>{CountryUtils.getStateLabel(shippingCountry)}</option>
                                    {
                                      CountryUtils.getStates(shippingCountry).map((item, index) =>
                                        <option key={index} value={item.name}>{item.name}</option>
                                      )
                                    }
                                  </select>
                                  <svg className={cn("absolute top-1/2 right-3 -mt-1.5 pointer-events-none w-3 h-12 z-3", {
                                    'hidden': loading || success || quickPayLoading || quickPaySuccess
                                  })} focusable="false" width="12" height="12">
                                    <path d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z" fillRule="evenodd"></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        {shipping && requestPhone ? (
                          <div className="box-border flex-initial min-w-0 max-w-full w-full">
                            <div className="relative">
                              <div>
                                <div className="relative">
                                  <div className="relative">
                                    <div className="group pointer-events-none absolute z-3 top-1/2 left-3 transform -translate-y-1/2">
                                      <div className="cursor-pointer h-4 pointer-events-none relative w-4">
                                        <img className="absolute left-0 top-0 z-3 w-4 h-4" src={`/images/flags/${countryCode}.svg`} alt={countryCode} />
                                        <select disabled={loading || success || quickPayLoading || quickPaySuccess} className={cn("absolute left-0 top-0 h-0 w-0 z-2 py-2 pl-2 pr-3 pointer-events-auto appearance-none rounded-none border-none outline-none", {
                                          'input-disabled': loading || success || quickPayLoading || quickPaySuccess
                                        })} onChange={(e) => onCountryCodeSelected(e)} value={countryCode}>
                                          {countryCodes.map((grp, index) => (
                                            <optgroup key={index} label={grp.group}>
                                              {grp.countries.map((c) => (
                                                <option key={c.value} value={c.value}>{c.label}</option>
                                              ))}
                                            </optgroup> 
                                          ))}
                                        </select>
                                        <svg className="text-input-svg absolute -bottom-px cursor-pointer h-2 w-2 -right-0.5 z-4 group-hover:text-input" focusable="false" fill="currentColor" viewBox="0 0 16 16"><path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm2.7928932-9.70710678-2.7928932 2.79289322-2.79289322-2.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l3.5 3.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l3.50000002-3.50000002c.3905243-.39052429.3905243-1.02368927 0-1.41421356s-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path></svg>
                                      </div>
                                    </div>
                                    <span className="relative block m-0 p-0">
                                      <input disabled={loading || success || quickPayLoading || quickPaySuccess} className={cn("checkout-input relative w-full h-11 py-2 px-3 text-input placeholder-placeholder text-base leading-normal border-0 rounded-none rounded-b-md text-indent-6 shadow-input transitions-input bg-white appearance-none lg:h-9 lg:text-sm lg:transform lg:rotate-0", {
                                        'invalid': phoneError || phoneMissing, 'input-disabled': loading || success
                                      })} autoComplete="tel" autoCorrect="off" spellCheck="false" id="phoneNumber" name="phoneNumber" type="tel" aria-invalid={phoneError || phoneMissing} placeholder={CountryUtils.getMobilePlaceholder(countryCode)} value={phone || ''} onChange={(e) => onPhoneChanged(e)} onBlur={(e) => onPhoneBlur(e)} />
                                    </span>
                                  </div>
                                  <div className="pointer-events-none absolute z-3 top-1/2 transform -translate-y-1/2 flex items-center right-3">
                                    <div data-tip="In case we need to contact you about your order." data-type="light" data-effect="solid" data-place="top" className="pointer-events-auto ml-2">
                                      <svg className={cn("w-3 h-3", {
                                        'text-red-500': phoneError || phoneMissing,
                                        'text-input-svg': !phoneError && !phoneMissing
                                      })} focusable="false" viewBox="0 0 12 12" fill="none">
                                        <path d="M6 12C9.28235 12 12 9.28235 12 6C12 2.72353 9.27647 0 5.99412 0C2.71765 0 0 2.72353 0 6C0 9.28235 2.72353 12 6 12ZM6 11C3.22353 11 1.00588 8.77647 1.00588 6C1.00588 3.22941 3.21765 1 5.99412 1C8.77059 1 10.9941 3.22941 11 6C11.0059 8.77647 8.77647 11 6 11ZM5.94706 3.90588C6.37647 3.90588 6.71177 3.56471 6.71177 3.14118C6.71177 2.71176 6.37647 2.37059 5.94706 2.37059C5.52353 2.37059 5.18235 2.71176 5.18235 3.14118C5.18235 3.56471 5.52353 3.90588 5.94706 3.90588ZM4.97059 9.23529H7.36471C7.60588 9.23529 7.79412 9.06471 7.79412 8.82353C7.79412 8.59412 7.60588 8.41177 7.36471 8.41177H6.63529V5.41765C6.63529 5.1 6.47647 4.88824 6.17647 4.88824H5.18235C4.94118 4.88824 4.75294 5.07059 4.75294 5.3C4.75294 5.54118 4.94118 5.71176 5.18235 5.71176H5.7V8.41177H4.97059C4.72941 8.41177 4.54118 8.59412 4.54118 8.82353C4.54118 9.06471 4.72941 9.23529 4.97059 9.23529Z" fill="currentColor"></path>
                                      </svg>
                                    </div>
                                    {tooltipVisible && <ReactTooltip className="tooltip text-xs font-normal text-input proportional-nums whitespace-normal" />}
                                  </div>
                                </div>
                                <AnimateHeight
                                  height={phoneErrorMsg ? 'auto' : 0}
                                  duration={500}
                                  animateOpacity
                                  className="flex-shrink-0 max-w-full pointer-events-none"
                                >
                                  <span className="m-0 text-13px text-danger">
                                    <span>{phoneErrorMsg}</span>
                                  </span>
                                </AnimateHeight>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="opacity-0 h-0"></div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                {
                  !hasOtherPaymentMethods() &&
                  <div className="box-border flex-initial min-w-0 max-w-full p-2">
                    <h2 className="m-0 text-base font-medium text-gray-600 mt-3">Payment details</h2>
                  </div>
                }
              </>
            }
            {
              hasOtherPaymentMethods() &&
              <div className="box-border flex-initial min-w-0 max-w-full p-2">
                <h2 className="m-0 text-base font-medium text-gray-600 mt-3">Payment method</h2>
              </div>
            }
          </div>
          {
            hasOtherPaymentMethods()
            ? <div className="tabs">
                <div className="relative">
                  <nav className="-mb-px flex">
                    {
                      showCard && 
                      <button disabled={loading || success || quickPayLoading || quickPaySuccess} href="#" className={cn("mr-1 py-2.5 px-1 text-center font-medium text-13px rounded-md hover:text-gray-700 focus:outline-none focus:text-gray-700 transition duration-150 ease-in-out", { 
                        'text-gray-500 shadow-tab hover:shadow-tab-hover': paymentMethod !== 'card',
                        'text-gray-800 shadow-tab-active focus:shadow-tab-selected-focus': paymentMethod === 'card',
                        'w-1/3': banks.length > 0 && eWallets.length > 1,
                        'w-1/2': banks.length < 1 || eWallets.length <= 1,
                        'cursor-default': loading || success || quickPayLoading || quickPaySuccess,
                      })} onClick={(e) => onPaymentMethodClick(e, 'card')}>
                        Card
                      </button>
                    }
                    {
                      (banks.length > 0 && quickPay !== 'bank') &&
                      <button disabled={loading || success || quickPayLoading || quickPaySuccess} href="#" className={cn("mx-1 py-2.5 px-1 text-center font-medium text-13px rounded-md hover:text-gray-700 focus:outline-none focus:text-gray-700 transition duration-150 ease-in-out", { 
                        'text-gray-500 shadow-tab hover:shadow-tab-hover': paymentMethod !== 'bank',
                        ' text-gray-800 shadow-tab-active focus:shadow-tab-selected-focus': paymentMethod === 'bank',
                        'w-1/3': showCard && eWallets.length > 1,
                        'w-1/2': eWallets.length <= 1 || (!showCard && eWallets.length > 1),
                        'cursor-default': loading || success || quickPayLoading || quickPaySuccess,
                      })} onClick={(e) => onPaymentMethodClick(e, 'bank')}>
                        Bank
                      </button>
                    }
                    {
                      (eWallets.length > 0 && quickPay !== 'wallet') &&
                      <button disabled={loading || success || quickPayLoading || quickPaySuccess} className={cn("ml-1 py-2.5 px-1 text-center font-medium text-13px rounded-md hover:text-gray-700 focus:outline-none focus:text-gray-700 transition duration-150 ease-in-out", {
                        'text-gray-500 shadow-tab hover:shadow-tab-hover': paymentMethod !== 'wallet',
                        'text-gray-800 shadow-tab-active focus:shadow-tab-selected-focus': paymentMethod === 'wallet',
                        'w-1/3': showCard && banks.length > 0,
                        'w-1/2': banks.length <= 1 || (!showCard && banks.length > 1),
                        'cursor-default': loading || success || quickPayLoading || quickPaySuccess,
                      })} onClick={(e) => onPaymentMethodClick(e, 'wallet')}>
                        Wallet
                      </button>
                    }
                  </nav>
                </div>
                <div className="tab-content-panel mt-6 relative">
                  <Transition
                    show={paymentMethod === 'card'}
                    enter="transition delay-150 duration-500 ease-in-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transform transition duration-300 ease"
                    leaveFrom="translate-x-0 opacity-100"
                    leaveTo="-translate-x-8 opacity-0"
                  >
                    <div className="absolute top-0 left-0 right-0">
                      <CardInfo
                        ref={cardRef}
                        brand={brand}
                        cardDetailsMissing={cardDetailsMissing}
                        cardNumberMissing={cardNumberMissing}
                        cardNumberError={cardNumberError}
                        cardNumberErrorMsg={cardNumberErrorMsg}
                        cardNumber={cardNumber}
                        onCardNumberChanged={onCardNumberChanged}
                        onCardNumberBlur={onCardNumberBlur}
                        expiry={expiry}
                        expiryMissing={expiryMissing}
                        expiryError={expiryError}
                        expiryErrorMsg={expiryErrorMsg}
                        onExpiryChanged={onExpiryChanged}
                        onExpiryBlur={onExpiryBlur}
                        cvc={cvc}
                        cvcMissing={cvcMissing}
                        cvcError={cvcError}
                        cvcErrorMsg={cvcErrorMsg}
                        onCvcChanged={onCvcChanged}
                        onCvcBlur={onCvcBlur}
                        loaded={loaded}
                        shipping={shipping}
                        sameBillingAsShipping={sameBillingAsShipping}
                        onUseShippingAsBillingChecked={onUseShippingAsBillingChecked}
                        name={name}
                        nameMissing={nameMissing}
                        nameError={nameError}
                        nameErrorMsg={nameErrorMsg}
                        onNameChanged={onNameChanged}
                        onNameBlur={onNameBlur}
                        billing={billing}
                        billingDetailsMissing={billingDetailsMissing}
                        billingCountry={billingCountry}
                        onBillingCountryChanged={onBillingCountryChanged}
                        billingAddressLine1={billingAddressLine1}
                        billingAddressLine1Missing={billingAddressLine1Missing}
                        onBillingAddressLine1Changed={onBillingAddressLine1Changed}
                        billingAddressLine2={billingAddressLine2}
                        onBillingAddressLine2Changed={onBillingAddressLine2Changed}
                        billingAddressSuburb={billingAddressSuburb}
                        onBillingAddressSuburbChanged={onBillingAddressSuburbChanged}
                        billingAddressCity={billingAddressCity}
                        billingAddressCityMissing={billingAddressCityMissing}
                        onBillingAddressCityChanged={onBillingAddressCityChanged}
                        billingAddressPostalCode={billingAddressPostalCode}
                        billingAddressPostalMissing={billingAddressPostalMissing}
                        onBillingAddressPostalChanged={onBillingAddressPostalChanged}
                        billingAddressState={billingAddressState}
                        billingAddressStateMissing={billingAddressStateMissing}
                        onBillingAddressStateChanged={onBillingAddressStateChanged}
                        saveInfoChecked={saveInfoChecked}
                        onSaveInfoChanged={onSaveInfoChanged}
                        onShowLinkWithMagpieInfo={onShowLinkWithMagpieInfo}
                        countryCodes={countryCodes}
                        saveInfoPhoneCountry={saveInfoPhoneCountry}
                        onSaveInfoCountryCodeChanged={onSaveInfoCountryCodeChanged}
                        saveInfoPhone={saveInfoPhone}
                        saveInfoPhoneLoading={saveInfoPhoneLoading}
                        saveInfoPhoneVerified={saveInfoPhoneVerified}
                        onSaveInfoPhoneChanged={onSaveInfoPhoneChanged}
                        onSaveInfoPhoneVerify={verifyPhoneHandler}
                        onSaveInfoPhoneBlur={onSaveInfoPhoneBlur}
                        onChangeSaveInfoPhone={changeSaveInfoPhoneHandler}
                        saveInfoPhoneMissing={saveInfoPhoneMissing}
                        saveInfoPhoneError={saveInfoPhoneError}
                        savedPaymentInfo={selectedSavedInfo}
                        useSavedPaymentInfo={useSavedInfo}
                        onUseSavedPaymentInfo={onUseSavedPaymentInfoHandler}
                        updateSavedCardInfo={updateSavedInfo}
                        onUpdateSavedCardInfo={onUpdateSavedPaymentInfoHandler}
                        complete={complete}
                        loading={loading}
                        success={success}
                        buttonColor={buttonColor}
                        total={total}
                        currency={currency}
                        merchant={merchant}
                        mode={mode}
                        submit={submitType}
                        onPayClick={onPayClick}
                      />
                    </div>
                  </Transition>
                  <Transition
                    show={paymentMethod === 'bank'}
                    enter="transition delay-150 duration-700 ease"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transform transition duration-300 ease"
                    leaveFrom="translate-x-0 opacity-100"
                    leaveTo={ paymentMethod === 'wallet' ? "-translate-x-8 opacity-0" : "translate-x-8 opacity-0" }
                  >
                    <div className="absolute top-0 left-0 right-0">
                      <BankInfo
                        ref={bankRef}
                        banks={banks}
                        bank={bank}
                        onBankChanged={onBankChanged}
                        bankError={bankError}
                        bankErrorMsg={bankErrorMsg}
                        bankMissing={bankMissing}
                        shipping={shipping}
                        sameBillingAsShipping={sameBillingAsShipping}
                        onUseShippingAsBillingChecked={onUseShippingAsBillingChecked}
                        requestName={requestName}
                        name={name}
                        nameMissing={nameMissing}
                        nameError={nameError}
                        nameErrorMsg={nameErrorMsg}
                        onNameChanged={onNameChanged}
                        onNameBlur={onNameBlur}
                        billing={billing}
                        billingDetailsMissing={billingDetailsMissing}
                        billingCountry={billingCountry}
                        onBillingCountryChanged={onBillingCountryChanged}
                        billingAddressLine1={billingAddressLine1}
                        billingAddressLine1Missing={billingAddressLine1Missing}
                        onBillingAddressLine1Changed={onBillingAddressLine1Changed}
                        billingAddressLine2={billingAddressLine2}
                        onBillingAddressLine2Changed={onBillingAddressLine2Changed}
                        billingAddressSuburb={billingAddressSuburb}
                        onBillingAddressSuburbChanged={onBillingAddressSuburbChanged}
                        billingAddressCity={billingAddressCity}
                        billingAddressCityMissing={billingAddressCityMissing}
                        onBillingAddressCityChanged={onBillingAddressCityChanged}
                        billingAddressPostalCode={billingAddressPostalCode}
                        billingAddressPostalMissing={billingAddressPostalMissing}
                        onBillingAddressPostalChanged={onBillingAddressPostalChanged}
                        billingAddressState={billingAddressState}
                        billingAddressStateMissing={billingAddressStateMissing}
                        onBillingAddressStateChanged={onBillingAddressStateChanged}
                        complete={complete}
                        loading={loading}
                        success={success}
                        bankFrameVisible={bankModalVisible}
                        buttonColor={buttonColor}
                        total={total}
                        currency={currency}
                        submit={submitType}
                        onBankPayClick={onBankPayClick}
                      />
                    </div>
                  </Transition>
                  <Transition
                    show={paymentMethod === 'wallet'}
                    enter="transition delay-150 duration-700 ease"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transform transition duration-300 ease"
                    leaveFrom="translate-x-0 opacity-100"
                    leaveTo="translate-x-8 opacity-0"
                  >
                    <div className="absolute top-0 left-0 right-0">
                      <WalletInfo
                        ref={walletRef}
                        eWallets={eWallets}
                        wallet={wallet}
                        onWalletChanged={onWalletChanged}
                        walletError={walletError}
                        walletErrorMsg={walletErrorMsg}
                        walletMissing={walletMissing}
                        shipping={shipping}
                        sameBillingAsShipping={sameBillingAsShipping}
                        onUseShippingAsBillingChecked={onUseShippingAsBillingChecked}
                        requestName={requestName}
                        name={name}
                        nameMissing={nameMissing}
                        nameError={nameError}
                        nameErrorMsg={nameErrorMsg}
                        onNameChanged={onNameChanged}
                        onNameBlur={onNameBlur}
                        billing={billing}
                        billingDetailsMissing={billingDetailsMissing}
                        billingCountry={billingCountry}
                        onBillingCountryChanged={onBillingCountryChanged}
                        billingAddressLine1={billingAddressLine1}
                        billingAddressLine1Missing={billingAddressLine1Missing}
                        onBillingAddressLine1Changed={onBillingAddressLine1Changed}
                        billingAddressLine2={billingAddressLine2}
                        onBillingAddressLine2Changed={onBillingAddressLine2Changed}
                        billingAddressSuburb={billingAddressSuburb}
                        onBillingAddressSuburbChanged={onBillingAddressSuburbChanged}
                        billingAddressCity={billingAddressCity}
                        billingAddressCityMissing={billingAddressCityMissing}
                        onBillingAddressCityChanged={onBillingAddressCityChanged}
                        billingAddressPostalCode={billingAddressPostalCode}
                        billingAddressPostalMissing={billingAddressPostalMissing}
                        onBillingAddressPostalChanged={onBillingAddressPostalChanged}
                        billingAddressState={billingAddressState}
                        billingAddressStateMissing={billingAddressStateMissing}
                        onBillingAddressStateChanged={onBillingAddressStateChanged}
                        complete={complete}
                        loading={loading}
                        success={success}
                        buttonColor={buttonColor}
                        total={total}
                        currency={currency}
                        submit={submitType}
                        onWalletPayClick={onWalletPayClick}
                      />
                    </div>
                  </Transition>
                </div>
              </div>
            : showCard  
            ? <CardInfo
                ref={cardRef}
                brand={brand}
                cardDetailsMissing={cardDetailsMissing}
                cardNumberMissing={cardNumberMissing}
                cardNumberError={cardNumberError}
                cardNumberErrorMsg={cardNumberErrorMsg}
                cardNumber={cardNumber}
                onCardNumberChanged={onCardNumberChanged}
                onCardNumberBlur={onCardNumberBlur}
                expiry={expiry}
                expiryMissing={expiryMissing}
                expiryError={expiryError}
                expiryErrorMsg={expiryErrorMsg}
                onExpiryChanged={onExpiryChanged}
                onExpiryBlur={onExpiryBlur}
                cvc={cvc}
                cvcMissing={cvcMissing}
                cvcError={cvcError}
                cvcErrorMsg={cvcErrorMsg}
                onCvcChanged={onCvcChanged}
                onCvcBlur={onCvcBlur}
                loaded={loaded}
                shipping={shipping}
                sameBillingAsShipping={sameBillingAsShipping}
                onUseShippingAsBillingChecked={onUseShippingAsBillingChecked}
                name={name}
                nameMissing={nameMissing}
                nameError={nameError}
                nameErrorMsg={nameErrorMsg}
                onNameChanged={onNameChanged}
                onNameBlur={onNameBlur}
                billing={billing}
                billingDetailsMissing={billingDetailsMissing}
                billingCountry={billingCountry}
                onBillingCountryChanged={onBillingCountryChanged}
                billingAddressLine1={billingAddressLine1}
                billingAddressLine1Missing={billingAddressLine1Missing}
                onBillingAddressLine1Changed={onBillingAddressLine1Changed}
                billingAddressLine2={billingAddressLine2}
                onBillingAddressLine2Changed={onBillingAddressLine2Changed}
                billingAddressSuburb={billingAddressSuburb}
                onBillingAddressSuburbChanged={onBillingAddressSuburbChanged}
                billingAddressCity={billingAddressCity}
                billingAddressCityMissing={billingAddressCityMissing}
                onBillingAddressCityChanged={onBillingAddressCityChanged}
                billingAddressPostalCode={billingAddressPostalCode}
                billingAddressPostalMissing={billingAddressPostalMissing}
                onBillingAddressPostalChanged={onBillingAddressPostalChanged}
                billingAddressState={billingAddressState}
                billingAddressStateMissing={billingAddressStateMissing}
                onBillingAddressStateChanged={onBillingAddressStateChanged}
                saveInfoChecked={saveInfoChecked}
                onSaveInfoChanged={onSaveInfoChanged}
                onShowLinkWithMagpieInfo={onShowLinkWithMagpieInfo}
                countryCodes={countryCodes}
                saveInfoPhoneCountry={saveInfoPhoneCountry}
                onSaveInfoCountryCodeChanged={onSaveInfoCountryCodeChanged}
                saveInfoPhone={saveInfoPhone}
                saveInfoPhoneLoading={saveInfoPhoneLoading}
                saveInfoPhoneVerified={saveInfoPhoneVerified}
                onSaveInfoPhoneChanged={onSaveInfoPhoneChanged}
                onSaveInfoPhoneVerify={verifyPhoneHandler}
                onSaveInfoPhoneBlur={onSaveInfoPhoneBlur}
                onChangeSaveInfoPhone={changeSaveInfoPhoneHandler}
                saveInfoPhoneMissing={saveInfoPhoneMissing}
                saveInfoPhoneError={saveInfoPhoneError}
                savedPaymentInfo={selectedSavedInfo}
                useSavedPaymentInfo={useSavedInfo}
                onUseSavedPaymentInfo={onUseSavedPaymentInfoHandler}
                updateSavedCardInfo={updateSavedInfo}
                onUpdateSavedCardInfo={onUpdateSavedPaymentInfoHandler}
                complete={complete}
                loading={loading}
                success={success}
                buttonColor={buttonColor}
                total={total}
                currency={currency}
                merchant={merchant}
                mode={mode}
                submit={submitType}
                onPayClick={onPayClick}
              />
              : (quickPay !== 'bank' && paymentMethod === 'bank')
              ? <BankInfo
                  ref={bankRef}
                  banks={banks}
                  bank={bank}
                  onBankChanged={onBankChanged}
                  bankError={bankError}
                  bankErrorMsg={bankErrorMsg}
                  bankMissing={bankMissing}
                  shipping={shipping}
                  sameBillingAsShipping={sameBillingAsShipping}
                  onUseShippingAsBillingChecked={onUseShippingAsBillingChecked}
                  requestName={requestName}
                  name={name}
                  nameMissing={nameMissing}
                  nameError={nameError}
                  nameErrorMsg={nameErrorMsg}
                  onNameChanged={onNameChanged}
                  onNameBlur={onNameBlur}
                  billing={billing}
                  billingDetailsMissing={billingDetailsMissing}
                  billingCountry={billingCountry}
                  onBillingCountryChanged={onBillingCountryChanged}
                  billingAddressLine1={billingAddressLine1}
                  billingAddressLine1Missing={billingAddressLine1Missing}
                  onBillingAddressLine1Changed={onBillingAddressLine1Changed}
                  billingAddressLine2={billingAddressLine2}
                  onBillingAddressLine2Changed={onBillingAddressLine2Changed}
                  billingAddressSuburb={billingAddressSuburb}
                  onBillingAddressSuburbChanged={onBillingAddressSuburbChanged}
                  billingAddressCity={billingAddressCity}
                  billingAddressCityMissing={billingAddressCityMissing}
                  onBillingAddressCityChanged={onBillingAddressCityChanged}
                  billingAddressPostalCode={billingAddressPostalCode}
                  billingAddressPostalMissing={billingAddressPostalMissing}
                  onBillingAddressPostalChanged={onBillingAddressPostalChanged}
                  billingAddressState={billingAddressState}
                  billingAddressStateMissing={billingAddressStateMissing}
                  onBillingAddressStateChanged={onBillingAddressStateChanged}
                  complete={complete}
                  loading={loading}
                  success={success}
                  bankFrameVisible={bankModalVisible}
                  buttonColor={buttonColor}
                  total={total}
                  currency={currency}
                  submit={submitType}
                  onBankPayClick={onBankPayClick}
                />
              : (quickPay !== 'wallet' && paymentMethod === 'wallet')
              ? <WalletInfo
                  ref={walletRef}
                  eWallets={eWallets}
                  wallet={wallet}
                  onWalletChanged={onWalletChanged}
                  walletError={walletError}
                  walletErrorMsg={walletErrorMsg}
                  walletMissing={walletMissing}
                  shipping={shipping}
                  sameBillingAsShipping={sameBillingAsShipping}
                  onUseShippingAsBillingChecked={onUseShippingAsBillingChecked}
                  requestName={requestName}
                  name={name}
                  nameMissing={nameMissing}
                  nameError={nameError}
                  nameErrorMsg={nameErrorMsg}
                  onNameChanged={onNameChanged}
                  onNameBlur={onNameBlur}
                  billing={billing}
                  billingDetailsMissing={billingDetailsMissing}
                  billingCountry={billingCountry}
                  onBillingCountryChanged={onBillingCountryChanged}
                  billingAddressLine1={billingAddressLine1}
                  billingAddressLine1Missing={billingAddressLine1Missing}
                  onBillingAddressLine1Changed={onBillingAddressLine1Changed}
                  billingAddressLine2={billingAddressLine2}
                  onBillingAddressLine2Changed={onBillingAddressLine2Changed}
                  billingAddressSuburb={billingAddressSuburb}
                  onBillingAddressSuburbChanged={onBillingAddressSuburbChanged}
                  billingAddressCity={billingAddressCity}
                  billingAddressCityMissing={billingAddressCityMissing}
                  onBillingAddressCityChanged={onBillingAddressCityChanged}
                  billingAddressPostalCode={billingAddressPostalCode}
                  billingAddressPostalMissing={billingAddressPostalMissing}
                  onBillingAddressPostalChanged={onBillingAddressPostalChanged}
                  billingAddressState={billingAddressState}
                  billingAddressStateMissing={billingAddressStateMissing}
                  onBillingAddressStateChanged={onBillingAddressStateChanged}
                  complete={complete}
                  loading={loading}
                  success={success}
                  buttonColor={buttonColor}
                  total={total}
                  currency={currency}
                  submit={submitType}
                  onWalletPayClick={onWalletPayClick}
                />
              : null
          }
        </form>
      </main>
      <BankModal
        ref={bankFrameRef}
        isOpen={bankModalVisible}
        onClose={closeBankModalHandler}
        color={CardUtils.getButtonColor(bank)}
        showNav={bankNavVisible}
        bankNav={bankFrameNav}
        bankFrameLoading={bankFrameLoading}
        bankFrameUrl={bankFrameUrl}
        onFrameLoad={bankFrameLoadHandler}
      />
      <WalletModal
        ref={walletFrameRef}
        isOpen={walletModalVisible}
        onClose={closeWalletModalHandler}
        color={CardUtils.getButtonColor(wallet)}
        showNav={walletNavVisible}
        paymentMethod={wallet}
        walletNav={walletFrameNav}
        walletFrameLoading={walletFrameLoading}
        walletFrameUrl={walletFrameUrl}
        onFrameLoad={walletFrameLoadHandler}
      />
      <CardModal
        ref={cardFrameRef}
        isOpen={cardModalVisible}
        onClose={closeCardModalHandler}
        color={buttonColor}
        showNav={cardNavVisible}
        cardNav={cardFrameNav}
        cardFrameLoading={cardFrameLoading}
        cardFrameUrl={cardFrameUrl}
        onFrameLoad={cardFrameLoadHandler}
      />
      <ErrorModal
        isOpen={errorModalVisible}
        onClose={closeErrorModalHandler}
        message={error}
      />
      {saveInfoChecked && (
        <PhoneVerificationModal
          phone={saveInfoPhone}
          country={saveInfoPhoneCountry}
          messageId={otpRefId}
          isOpen={phoneVerifyModalVisible}
          onClose={closePhoneVerifyModalHandler}
          onVerified={phoneVerifiedHandler}
        />
      )}
      {emailHasSavedInfo && (
        <LoginVerificationModal
          customer={customerFound}
          messageId={otpRefId}
          isOpen={loginVerifyModalVisible}
          onClose={closeLoginVerifyModalHandler}
          onAuthenticateSuccess={loginVerifySuccessHandler}
        />
      )}
      {customerFound && (
        <LogoutConfirmModal
          isOpen={logoutConfirmVisible}
          customer={customerFound}
          onClose={closeLogoutModalHandler}
          onLogout={logoutHandler}
        />
      )}
      <SaveInfoMoreModal
        isOpen={linkWithMagpieInfoVisible}
        merchant={merchant}
        onClose={closeSaveInfoMoreModalHandler}
      />
    </div>
  );
}

export default PaymentForm;