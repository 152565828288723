import React from 'react';
import Modal from 'react-modal';
import Link from 'next/link';

const SaveInfoMoreModal = ({
  isOpen,
  merchant,
  onClose
}) => {
  Modal.setAppElement('body');
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      portalClassName="modal-portal"
      overlayClassName={{ base: "modal-overlay info-modal-overlay", afterOpen: "modal-overlay--after-open", beforeClose: "modal-overlay--before-close" }}
      className={{ base: "modal-content info-modal", afterOpen: "modal-content--after-open", beforeClose: "modal-content--before-close" }}
      bodyOpenClassName="body--modal-open"
    >
      <div className="max-w-67.5 text-center text-modal-header leading-4.5">
        {`${merchant} uses Link with Magpie to securely save your payment information.`}
      </div>
      <div className="max-w-67.5 mt-6 flex flex-row">
        <div className="block">
          <svg className="w-4 h-4" fill="#000" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" /></svg>
        </div>
        <div className="ml-3">
          <div className="text-input leading-4.5">Mobile verifcation</div>
          <div className="mt-0.5 text-input-svg leading-4.5">
            You're protected with mobile authentication designed to ensure that you're the only person who can access your information.
          </div>
        </div>
      </div>
      <div className="max-w-67.5 mt-6 flex flex-row">
        <div className="block">
          <svg className="w-4 h-4" fill="#000" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" /></svg>
        </div>
        <div className="ml-3">
          <div className="text-input leading-4.5">Bank-level security</div>
          <div className="mt-0.5 text-input-svg leading-4.5">
            Your information stays secure with end-to-end encryption on servers that meet the strictest PCI standards available in the payment industry.
          </div>
        </div>
      </div>
      <div className="mt-6 text-placeholder text-13px leading-4">You can review every order before you pay.</div>
      <div className="flex flex-no-wrap flex-col mt-6 w-full">
        <div className="flex-initial min-w-0 max-w-full flex-basis-0 flex-grow p-1">
          <button onClick={onClose} className="border border-solid border-transparent rounded-md cursor-pointer text-sm text-gray-900 p-3 bg-gray-50 w-full transition-button hover:bg-secondary-hover focus:outline-none focus:shadow-secondary-focus active:bg-secondary-active active:transform active:scale-98">
            <div className="flex flex-no-wrap justify-center items-center">
              <span className="font-medium text-blue-500 truncate">Close</span>
            </div>
          </button>
        </div>
        <div className="flex-initial min-w-0 max-w-full flex-basis-0 flex-grow p-1">
          <Link href="https://magpie.im/privacy.html" passHref>
            <a className="no-underline cursor-pointer text-blue-500 outline-none focus:outline-none focus:ring-0" target="_blank">
              <button className="border border-solid border-transparent rounded-md cursor-pointer text-sm text-gray-900 p-3 bg-transparent w-full transition-button hover:bg-secondary-hover active:bg-secondary-active active:transform active:scale-98 focus:shadow-secondary-focus focus:outline-none">
                <div className="flex flex-no-wrap justify-center items-center">
                  <svg className="w-4 h-4 order-1 mr-0 ml-2 flex-shrink-0 text-placeholder" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" /><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" /></svg>
                  <span className="m-0 font-medium text-placeholder truncate">Learn more about privacy</span>
                </div>
              </button>
            </a>
          </Link>
        </div>
      </div>
    </Modal>
  )
}

export default SaveInfoMoreModal;