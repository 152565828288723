import React from 'react'
import Modal from 'react-modal'
import cn from 'classnames'

const ErrorModal = ({
  isOpen,
  onClose,
  message
}) => {
  Modal.setAppElement('body');
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      portalClassName="modal-portal"
      overlayClassName={{ base: "modal-overlay error-modal-overlay", afterOpen: "modal-overlay--after-open", beforeClose: "modal-overlay--before-close" }}
      className={{ base: "modal-content error-modal", afterOpen: "modal-content--after-open", beforeClose: "modal-content--before-close" }}
      bodyOpenClassName="body--modal-open"
    >
      <div className="w-full max-w-95">
        <div className="modal-body bg-white overflow-hidden rounded px-8 pt-8 pb-5">
          <p className="mb-6 text-sm text-gray-800 font-sans font-medium text-center">{message}</p>
          <div className="flex justify-center items-center">
            <div className="rounded-md shadow-sm relative">
              <button type="button" className="flex items-center px-8 py-2 border border-transparent rounded-md bg-blue-500 hover:bg-blue-600 focus:outline-none focus:bg-blue-700 transition ease-in-out duration-150" onClick={() => onClose()}>
                <span className="text-sm font-sans font-medium text-white leading-normal">Close</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ErrorModal;