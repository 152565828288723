import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js/mobile';

import SmsCodeInput from './SmsCodeInput';
import Api from '../api/Api';


const PhoneVerificationModal = ({
  isOpen,
  phone,
  country,
  messageId,
  onClose,
  onVerified
}) => {
  Modal.setAppElement('body');

  const [otpId, setOtpId] = useState();
  const [mobile, setMobile] = useState('');
  const [code, setCode] = useState();
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (!isOpen) {
      setCode(undefined);
      setDisabled(false);
      setLoading(false);
      setError(undefined);
      setSuccess(false);
    }
  }, [isOpen]);
  
  useEffect(() => {
    if (phone) {
      console.log('phone', phone);
      if (isValidPhoneNumber(phone, country)) {
        const phoneNumber = parsePhoneNumber(phone, country);
        if (phoneNumber) {
          setMobile(phoneNumber.number);
        }
      }
    }
  }, [phone]);

  useEffect(() => {
    if (messageId) {
      setOtpId(messageId);
    }
  }, [messageId]);

  const requestNewCode = async () => {
    if (mobile) {
      const requestRes = await Api.requestOtp(mobile);
      if (requestRes.status === 200) {
        setOtpId(requestRes.data.id);
        setCode('');
        setError(undefined);
        setDisabled(false);
        setLoading(false);
      } else {
        setCode('');
        setDisabled(false);
        setLoading(false);
        setError('Sorry — something went wrong while sending SMS to your mobile number. Please try again in a few minutes.');
      }
    }
  }

  const smsSubmitHandler = async (value) => {
    console.log('code', value);
    if (otpId) {
      setError(undefined);
      setCode(value);
      setDisabled(true);
      setLoading(true);

      const verifyRes = await Api.verifyOtp(otpId, value);
      if (verifyRes.status === 200) {
        if (verifyRes.data.status === 'verified') {
          setLoading(false);
          setSuccess(true);
          setTimeout(() => {
            onVerified();
          }, 400);
        } else {
          setCode('');
          setLoading(false);
          setDisabled(false);
          setError('The previous code has expired. Please enter the new code sent to your phone.');
        }
      } else {
        setCode('');
        setLoading(false);
        setDisabled(false);
        setError(verifyRes.data.message ? verifyRes.data.message : 'Sorry — something went wrong while verifying your code. Please try again in a few minutes.');
      }
    } else {
      await requestNewCode();
    }
  }
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      portalClassName="modal-portal"
      overlayClassName={{ base: "modal-overlay verification-modal-overlay", afterOpen: "modal-overlay--after-open", beforeClose: "modal-overlay--before-close" }}
      className={{ base: "modal-content verification-modal", afterOpen: "modal-content--after-open", beforeClose: "modal-content--before-close" }}
      bodyOpenClassName="body--modal-open"
    >
      <div className="py-6 px-8 text-center">
        <span className="m-0 text-base font-medium text-gray-900">Verify your mobile number</span>
        <div className="mt-2">
          <div className="m-0 text-sm text-modal-header">
            Enter the verification code we sent to your phone to save your payment information.
          </div>
        </div>
        <div className="mt-6 flex flex-no-wrap justify-center">
          <div className="flex-initial min-w-0 max-w-full w-auto flex-basis-auto">
            <SmsCodeInput code={code} disabled={disabled} onCodeSubmit={smsSubmitHandler} />
          </div>
        </div>
        <div className="mt-4 flex flex-no-wrap justify-center">
          <div className="flex-initial min-w-0 max-w-full w-auto flex-basis-auto">
            {loading ? (
              <div className="m-0 text-sm p-2 rounded-md bg-verification-mobile">
                <div className="flex flex-row items-center justify-center">
                  <div className="spinner-icon flex mr-2">
                    <svg className=" w-3.5 h-3.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false">
                      <ellipse className="origin-center animate-spinner-spin" cx="12" cy="12" rx="10" ry="10" style={{ stroke: 'rgb(26, 26, 26, 0.5)' }}></ellipse>
                    </svg>
                  </div>
                  <span>Verifying...</span>
                </div>
              </div>
            ) : error ? (
              <div className="m-0 text-sm p-2 rounded-md bg-red-50 text-red-600">
                <div className="flex items-center justify-center">{error}</div>
              </div>
            ) : success ? (
              <div className="m-0 text-sm p-2 rounded-md bg-green-100 text-green-500">
                <div className="flex flex-row items-center justify-center">
                  <div className="flex mr-2">
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                  </div>
                  <span>Success</span>
                </div>
              </div>
            ) : (
               <div className="m-0 text-sm p-2 rounded-md bg-verification-mobile">
                {`Code sent to: `}
                <div className="flex items-center justify-center mt-1">
                  <svg className="h-4 w-4 fill-dark mr-1" focusable="false" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M10 1.532A1 1 0 019 2.5H7a1 1 0 01-1-.968 6.137 6.137 0 00-.048.004c-.408.033-.559.09-.633.127a1.5 1.5 0 00-.656.656c-.037.074-.094.225-.127.633C4.5 3.377 4.5 3.935 4.5 4.8v6.4c0 .865.001 1.423.036 1.848.033.408.09.559.127.633a1.5 1.5 0 00.656.655c.074.038.225.095.633.128.425.035.983.036 1.848.036h.4c.865 0 1.423-.001 1.848-.036.408-.033.559-.09.633-.128a1.5 1.5 0 00.655-.655c.038-.074.095-.225.128-.633.035-.425.036-.983.036-1.848V4.8c0-.865-.001-1.423-.036-1.848-.033-.408-.09-.559-.128-.633a1.5 1.5 0 00-.655-.656c-.074-.037-.225-.094-.633-.127A6.05 6.05 0 0010 1.532zm-6.673.106C3 2.28 3 3.12 3 4.8v6.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C5.28 16 6.12 16 7.8 16h.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311C13 13.72 13 12.88 13 11.2V4.8c0-1.68 0-2.52-.327-3.162A3 3 0 0011.362.327C10.72 0 9.88 0 8.2 0h-.4C6.12 0 5.28 0 4.638.327a3 3 0 00-1.311 1.311zM9 12a1 1 0 11-2 0 1 1 0 012 0z"></path></svg>
                  {mobile}
                </div>
              </div>
            )}
           
          </div>
        </div>
        
        <button className="mt-6 cursor-pointer text-sm p-0 bg-transparent border-none outline-none rounded-none focus:outline-none transition-button" onClick={onClose}>
          <div className="flex flex-no-wrap justify-center items-center">
            <span className="m-0 text-input">Cancel</span>
          </div>
        </button>
      </div>
    </Modal>
  )
}

export default PhoneVerificationModal;