import Modal from 'react-modal'
import ItemsMobile from './ItemsMobile'

const ItemsModal = ({ modalVisible, closeModalHandler, items, currency, hasItemWithImage }) => {
  Modal.setAppElement('body');

  return (
    <Modal
      isOpen={modalVisible}
      onRequestClose={() => closeModalHandler()}
      portalClassName="modal-portal"
      overlayClassName={{ base: "modal-overlay details-modal-overlay", afterOpen: "modal-overlay--after-open", beforeClose: "modal-overlay--before-close" }}
      className={{ base: "modal-content details-modal", afterOpen: "modal-content--after-open", beforeClose: "modal-content--before-close" }}
      bodyOpenClassName="body--modal-open"
    >
      <div className="p-4 pt-0">
        <ItemsMobile items={items} currency={currency} hasItemWithImage={hasItemWithImage} />
      </div>
    </Modal>
  )
}

export default ItemsModal;